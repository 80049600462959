import cntl from "cntl";
import PropTypes from "prop-types";
import { BadgeText, ButtonDropdown } from "..";
import { BUTTON_VARIANTS, SIZE } from "../constants";
import { Avatar, ButtonGrey, ButtonPrimary } from "../atoms";
import { IconDotsVertical } from "@tabler/icons-react";

const propTypes = {
  dropdown: PropTypes.func,
  className: PropTypes.string,
  onItemSelect: PropTypes.func,
  avatarProps: PropTypes.object,
  actionProps: PropTypes.object,
  dropdownOptions: PropTypes.array,
};

function CardHeader({ className, avatarProps, actionProps, onItemSelect, dropdownOptions }) {
  const cardHeaderCn = () => cntl`
    flex
    px-4
    py-5
    gap-y-4
    lg:px-6
    flex-col
    bg-white
    border-b
    lg:flex-row
    lg:justify-between
    border-gray-light-6
    ${className ? className : undefined}
  `;

  const avatarWithTextCn = () => cntl`
    flex
    gap-x-4
    flex-row
  `;

  const textCn = () => cntl`
    flex
    gap-x-1
    flex-col
  `;

  const titleAndBadgeCn = () => cntl`
    flex
    gap-x-2
    flex-row
    items-center
  `;

  const actionWrapperCn = () => cntl`
    flex
    h-fit
    gap-x-3
    flex-row
  `;

  const hideButtonCn = () => cntl`
    hidden
    lg:block
  `;

  const headerCn = () => cntl`
    text-lg
    font-semibold
    first-letter:uppercase
  `;

  const supportingTextCn = () => cntl`
    text-sm
    text-gray-light-11 
    dark:text-gray-dark-11
    first-letter:uppercase
  `;

  return (
    <div className={cardHeaderCn()}>
      <div className={avatarWithTextCn()}>
        {avatarProps?.picture ||
          (avatarProps?.letters && (
            <Avatar size={SIZE.xl} picture={avatarProps?.picture} letters={avatarProps?.letters} />
          ))}
        {(avatarProps?.title || avatarProps?.badge?.label || avatarProps?.supportingText) && (
          <div className={textCn()}>
            <div className={titleAndBadgeCn()}>
              <div className={headerCn()}>{avatarProps?.title}</div>
              {avatarProps?.badge?.label && <BadgeText label={avatarProps?.badge?.label} />}
            </div>
            <div className={supportingTextCn()}>{avatarProps?.supportingText}</div>
          </div>
        )}
      </div>
      <div className={actionWrapperCn()}>
        {actionProps?.button1?.label && (
          <ButtonGrey
            size={SIZE.md}
            className={hideButtonCn()}
            icon={actionProps?.button1?.icon}
            label={actionProps?.button1?.label}
            variant={BUTTON_VARIANTS.ghost}
            onClick={actionProps?.button1?.onClick}
            iconPosition={actionProps?.button1?.iconPosition}
          />
        )}
        {actionProps?.button2?.label && (
          <ButtonPrimary
            size={SIZE.md}
            className={hideButtonCn()}
            variant={BUTTON_VARIANTS.solid}
            icon={actionProps?.button2?.icon}
            label={actionProps?.button2?.label}
            onClick={actionProps?.button2?.onClick}
            iconPosition={actionProps?.button2?.iconPosition}
          />
        )}
        {actionProps?.button3?.label && (
          <ButtonGrey
            size={SIZE.md}
            variant={BUTTON_VARIANTS.outline}
            icon={actionProps?.button3?.icon}
            label={actionProps?.button3?.label}
            onClick={actionProps?.button3?.onClick}
            iconPosition={actionProps?.button3?.iconPosition}
          />
        )}
        {actionProps?.button4?.label && (
          <ButtonPrimary
            size={SIZE.md}
            icon={actionProps?.button4?.icon}
            label={actionProps?.button4?.label}
            onClick={actionProps?.button4?.onClick}
            iconPosition={actionProps?.button4?.iconPosition}
          />
        )}
        {dropdownOptions && (
          <ButtonDropdown options={dropdownOptions} className={hideButtonCn()} onItemSelect={onItemSelect}>
            <ButtonGrey icon={<IconDotsVertical />} variant={BUTTON_VARIANTS.ghost} />
          </ButtonDropdown>
        )}
      </div>
    </div>
  );
}

CardHeader.prototype = propTypes;
export default CardHeader;
