import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneElement } from "react";
import { POSITIONS } from "../../../constants";
import { IconPointFilled, IconTriangleFilled } from "@tabler/icons-react";

const propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  isOpen: PropTypes.bool,
  isOnline: PropTypes.bool,
  className: PropTypes.string,
  isVisibleTriangleIcon: PropTypes.bool,
  positionTriangleIcon: PropTypes.oneOf([POSITIONS.left, POSITIONS.right]),
};

function ContentNavItem({
  icon,
  text = "",
  className,
  isOpen = false,
  isOnline = false,
  isVisibleTriangleIcon = false,
  positionTriangleIcon = POSITIONS.left,
}) {
  const contentNavItemBaseCn = () => cntl`
    flex
    w-full
    items-center
    ${className ? className : undefined}
  `;

  const triangleIconAndTextCn = () => cntl`
    flex
    gap-x-1.5
    items-center
    ${positionTriangleIcon === POSITIONS.left ? cntl`flex-row` : cntl`flex-row-reverse`}
  `;

  const mainIconAndTextCn = () => cntl`
    flex
    gap-x-1.5
    items-center
  `;

  const mainIconCn = () => cntl`
    min-w-4
    min-h-4
    max-w-4
    max-h-4
    text-gray-light-11
    dark:text-gray-dark-11
  `;

  const textCn = () => cntl`
    text-sm
    font-medium
    text-gray-light-12
    dark:text-gray-dark-12
  `;

  const pointIconCn = () => cntl`
    w-2.5
    h-2.5
    ml-auto
    text-[#30A46C]
  `;

  const triangleIconCn = () => cntl`
    min-w-2
    max-w-2
    min-h-2
    max-h-2
    text-gray-light-10
    dark:text-gray-dark-10
    transition-transform
    ${isOpen ? cntl`rotate-180` : cntl`rotate-90`}
  `;

  return (
    <div className={contentNavItemBaseCn()}>
      <div className={triangleIconAndTextCn()}>
        {isVisibleTriangleIcon ? <IconTriangleFilled className={triangleIconCn()} /> : null}
        <div className={mainIconAndTextCn()}>
          {icon
            ? cloneElement(icon, {
                className: mainIconCn(),
              })
            : null}
          {text ? <div className={textCn()}>{text}</div> : null}
        </div>
      </div>
      {isOnline ? <IconPointFilled className={pointIconCn()} /> : null}
    </div>
  );
}

ContentNavItem.propTypes = propTypes;
export default ContentNavItem;
