import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onSelectionClick: PropTypes.func,
  isActive: PropTypes.number,
  key: PropTypes.string,
  onClick: PropTypes.func,
};

function CardSwitcher({ isActive, children, onSelectionClick, key }) {
  return (
    <div key={key} onClick={onSelectionClick}>
      {children && children?.[isActive ? 1 : 0]}
    </div>
  );
}

Selection.propTypes = propTypes;
export default CardSwitcher;
