import cntl from "cntl";
import PropTypes from "prop-types";
import { Tag } from "../molecules";
import { INPUT_VARIANTS, KEYBOARDS, SIZE, WIDTH } from "../constants";

const propTypes = {
  onTags: PropTypes.func,
  label: PropTypes.string,
  initTags: PropTypes.array,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  suggestions: PropTypes.array,
  placeholder: PropTypes.string,
  hasSuggestions: PropTypes.bool,
  onSuggestionsFetch: PropTypes.func,
  size: PropTypes.oneOf([SIZE.sm, SIZE.lg]),
  variant: PropTypes.oneOf([INPUT_VARIANTS.surface]),
  width: PropTypes.oneOf([WIDTH.xs, WIDTH.sm, WIDTH.md, WIDTH.lg, WIDTH.xl]),
};

function TagsInput({
  label,
  width,
  onTags,
  initTags,
  className,
  isDisabled,
  placeholder,
  suggestions,
  size = SIZE.lg,
  onSuggestionsFetch,
  hasSuggestions = false,
  variant = INPUT_VARIANTS.surface,
  ...props
}) {
  let tags = initTags || [];

  const styleBase = {
    [SIZE.sm]: {
      container: {
        size: "h-6",
        rounded: "rounded-0.75",
      },
      text: {
        fontStyle: "text-xs",
      },
    },
    [SIZE.md]: {
      container: {
        size: "h-8",
        rounded: "rounded",
      },
      text: {
        fontStyle: "text-sm",
      },
    },
    [SIZE.lg]: {
      container: {
        size: "h-10",
        rounded: "rounded-md",
      },
      text: {
        fontStyle: "text-base",
      },
    },
  };

  const wrapperCn = () => cntl`
    flex
    flex-col
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    mb-1
    text-sm
    text-gray-lightAlpha-12
    first-letter:uppercase
    dark:text-gray-darkAlpha-12
  `;

  const inputAndTagsCn = () => cntl`
    py-0
    px-2
    w-full
    border
    ${styleBase[size]?.container?.size}
    ${styleBase[size]?.text?.fontStyle}
    ${styleBase[size]?.container?.rounded}
    ${styleBase[size]?.wrapper?.heigth}
    ${false ? styleVariant[variant]?.style?.error : styleVariant[variant]?.style?.default}
  `;

  const tagCn = () => cntl`
    z-10
  `;

  const inputCn = () => cntl`
    p-0
    truncate
    border-0
    form-input
    bg-transparent
    focus:ring-0
    focus:ring-transparent
    focus:outline-0
    focus:outline-none
    focus:outline-transparent
    ${styleBase[size]?.container?.size}
    ${styleBase[size]?.text?.fontStyle}
  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1
    text-sm
    first-letter:uppercase
    text-error-lightAlpha-11
    dark:text-error-darkAlpha-11
    ${!hasErrors && cntl`invisible`}
  `;

  const surfaceVariantBase = () => cntl`
    bg-white/[.9]
    text-gray-light-12
    dark:bg-black/[.25]
    dark:text-gray-dark-12
    disabled:bg-gray-lightAlpha-3
    dark:disabled:bg-gray-darkAlpha-3
    placeholder:text-gray-lightAlpha-10
    dark:placeholder:text-gray-darkAlpha-10
  `;

  const surfaceVariantDefault = () => cntl`
    border-gray-light-6
    dark:border-gray-dark-6
    focus-within:border-primary-lightAlpha-8
    dark:focus-within:border-primary-lightAlpha-8
    ${surfaceVariantBase()}
  `;

  const surfaceVariantError = () => cntl`
    border-error-lightAlpha-8
    dark:border-error-darkAlpha-8
    focus:border-error-lightAlpha-8
    dark:focus:border-error-darkAlpha-8
  `;

  const styleVariant = {
    [INPUT_VARIANTS.surface]: {
      style: {
        error: surfaceVariantError(),
        default: surfaceVariantDefault(),
      },
    },
  };

  const handleKeyDown = (e) => {
    const value = e.target.value;

    if (e.keyCode === KEYBOARDS.enter) {
      if (!value.trim()) {
        return;
      } else {
        if (!tags.includes(value)) {
          tags.push(value);
          onTags(tags);
          e.target.value = "";
        }
      }
    } else if (e.keyCode === KEYBOARDS.backspace) {
      if (!value.length && tags.length) {
        const newTagsArr = tags.splice(0, tags.length - 1);
        tags.push(newTagsArr);
        onTags(newTagsArr);
      }
    } else {
      return;
    }
  };

  const onRemoveTag = (label) => {
    onTags(tags?.filter((tag) => tag !== label));
  };

  const onSuggestionClick = () => {
    if (hasSuggestions) {
      onSuggestionsFetch();
    }
  }

  return (
    <div className={wrapperCn()} onClick={onSuggestionClick}>
      {!!label?.length && <label className={labelCn()}>{label}</label>}
      <div tabIndex={0} className={inputAndTagsCn()}>
        {tags?.map((label, index) => {
          return (
            <Tag
              label={label}
              size={SIZE.md}
              className={tagCn()}
              key={`tag-${index}`}
              onCloseProps={{
                isVisible: true,
                onClose: () => onRemoveTag(label),
              }}
            />
          );
        })}
        <input
          {...props}
          disabled={isDisabled}
          className={inputCn()}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

TagsInput.propTypes = propTypes;
export default TagsInput;
