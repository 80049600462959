import cntl from "cntl";
function Toolbar({ className, children }) {
  const toolbarCn = ({ className }) => cntl`
        lg:h-24
        bg-dark-gray
        ${className}
    `;
  return <div className={toolbarCn({ className })}>{children}</div>;
}

export default Toolbar;
