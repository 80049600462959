import cntl from "cntl";
import PropTypes from "prop-types";
import MetricItem from "../atoms/MetricItem";
import { COLOR } from "../constants";

const proptypes = {
  multiCols: PropTypes.bool,
  items: PropTypes.array,
  theme: PropTypes.oneOf([COLOR.light, COLOR.dark, COLOR.gray, COLOR.primary, COLOR.primary_light]),
};

function MetricItemsList({ multiCols, items, theme }) {

  const wrapperCn = () => cntl`
    w-full
    flex
    flex-col
    ${multiCols ? "md:grid md:grid-cols-2" : "md:flex-row"}
    gap-y-10
    gap-x-8
    justify-center
    items-center
  `;

  return (
    <div className={wrapperCn()}>
      {items?.map((item, index) => {
        return (
          <MetricItem
            hasBar={item.hasBar}
            text={item.text}
            number={item.number}
            ctaText={item.ctaText}
            ctaOnclick={item.ctaOnclick}
            textCentered={item.textCentered}
            theme={theme}
            key={index}
          />
        );
      })}
    </div>
  );
}

MetricItemsList.prototype = proptypes;
export default MetricItemsList;
