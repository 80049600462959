import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { POSITIONS, COLOR, SIZE } from "../constants";
import SectionItem from "./SectionItem";
import Content from "./SectionContent";

const propTypes = {
  theme: PropTypes.oneOf([
    "",
    COLOR.dark,
    COLOR.gray,
    COLOR.light,
    COLOR.primary,
    COLOR.primary_light,
  ]),
};

function FeatureIntegrationsIcons04({ content, children, theme }) {
  const [bgColor, setBgColor] = useState("");

  const wrapperCn = () => cntl`
    ${bgColor}
  `;

  const containerCn = () => cntl`
    container
    mx-auto
    px-3 md:px-8 py-16 md:py-24
  `;

  useMemo(() => {
    switch (theme) {
      case "dark":
        setBgColor("bg-gray-light-10 dark:bg-gray-dark-10");
        break;
      case "grey":
        setBgColor("bg-gray-light-2 dark:bg-gray-dark-2");
        break;
      case "primary":
        setBgColor("bg-primary-light-10 dark:bg-primary-dark-10");
        break;
      case "primaryLight":
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        break;
      case "light":
        setBgColor("bg-white dark:bg-black");
        break;
      default:
        break;
    }
  }, [theme]);

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <div className="flex flex-col gap-y-12 md:gap-y-16">
          <Content {...content} />
          {children && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-8 md:gap-y-16">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
}

FeatureIntegrationsIcons04.prototype = propTypes;
export default FeatureIntegrationsIcons04;
