import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { syncTokens, refreshAuth } from "../actions/cromie/auth";
import { syncTokens as AldysSyncTokens, refreshAuth as AldysRefreshAuth } from "../actions/aldys/auth";

function Bootstrap({ children, app }) {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    async function dispatchActions() {
      if (app === "cromie" || app === "cromie-bo") {
        await dispatch(syncTokens());
        await dispatch(refreshAuth(app));
      }

      if (app === "aldys" || app === "aldys-bo") {
        await dispatch(AldysSyncTokens());
        await dispatch(AldysRefreshAuth(app));
      }

      setIsReady(true);
    }
    dispatchActions();
  }, [app, dispatch]);
  return isReady ? children : null;
}

export default Bootstrap;
