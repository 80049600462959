import cntl from "cntl";
import PropTypes from "prop-types";
import { Children, Fragment } from "react";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function ModalBody({ children, className }) {
  const bodyCn = () => cntl`
    p-6
    flex
    flex-col
    ${className ? className : undefined}
  `;

  return (
    <div className={bodyCn()}>
      {children &&
        Children.map(children, (child, index) => {
          return <Fragment key={`modal-body-child-${index}`}>{child}</Fragment>;
        })}
    </div>
  );
}

ModalBody.propTypes = propTypes;
export default ModalBody;
