import cntl from "cntl";
import { uniqueId } from "lodash";
import { PropTypes } from "prop-types";

// Constants
import { COLOR_PICKER_VARIANTS, SIZE } from "@dbox/components/constants";

const propTypes = {
  colors: PropTypes.array,
  onClick: PropTypes.func,
  colorSelected: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm]),
  variant: PropTypes.oneOf([COLOR_PICKER_VARIANTS.circle]),
};

const ColorPicker = ({ onClick, colors, size = SIZE.sm, variant = COLOR_PICKER_VARIANTS.circle, colorSelected }) => {
  const wrapperCn = () => cntl`
    flex
    gap-x-4
  `;

  const circleCn = ({ color }) => cntl`
    border
    border-gray-light-12
    dark:border-gray-dark-12
    ${
      color === colorSelected ? `outline outline-2 outline-primary-lightAlpha-8 dark:outline-primary-darkAlpha-8` : null
    }
    ${style[size].size}
    ${style[size].variant}
  `;

  const style = {
    [size]: { size: "w-4.5 h-4.5", variant: variant === COLOR_PICKER_VARIANTS.circle ? `rounded-full` : null },
  };

  return (
    <div className={wrapperCn()}>
      {colors?.map((color) => (
        <button
          onClick={() => onClick(color)}
          className={circleCn({ color })}
          style={{ backgroundColor: color }}
          key={uniqueId("button-color-picker-")}
        />
      ))}
    </div>
  );
};

ColorPicker.propTypes = propTypes;
export default ColorPicker;
