import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app.reducer";
import logger from "redux-logger";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
      },
    });

    if (process.env.NODE_ENV === "development") {
      return defaultMiddleware.concat(logger);
    }
    return defaultMiddleware;
  },
});

export default store;
