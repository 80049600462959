import cntl from "cntl";
import PropTypes from "prop-types";
import Content from "./SectionContent";
import { useMemo, useState } from "react";
import { COLOR, POSITIONS, SIZE } from "../constants";
import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";

const propTypes = {
  content: PropTypes.object,
  accordions: PropTypes.array,
  theme: PropTypes.oneOf([COLOR.dark, COLOR.gray, COLOR.light, COLOR.primary, COLOR.primary_light]),
};

function FAQSectionAccordion03({ accordions, theme, content }) {
  const [bgColor, setBgColor] = useState("");
  const [accordionColors, setAccordionColors] = useState({ title: "", description: "" });

  const wrapperCn = () => cntl`
    ${bgColor}
  `;

  const containerCn = () => cntl`
    px-4
    py-16
    md:px-8
    mx-auto
    md:py-24
    container
  `;

  const contentCn = () => cntl`
    w-full
    md:w-1/2
    lg:w-[28rem]
  `;

  const textAndAccordionsCn = () => cntl`
    flex
    flex-col
    gap-x-16
    gap-y-12
    md:flex-row
    justify-between
  `;

  const accordionsCn = () => cntl`
    flex
    gap-y-8
    flex-col
    md:w-1/2
    md:max-w-2xl
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-gray-light-11 dark:bg-gray-dark-11");
        setAccordionColors({ title: "text-white dark:text-black", description: "text-gray-light-8 dark:text-gray-dark-8" });
        break;
      case COLOR.gray:
        setBgColor("bg-gray-light-2 dark:bg-gray-dark-2");
        setAccordionColors({ title: "text-gray-light-12 dark:text-gray-dark-12", description: "text-gray-light-11 dark:text-gray-dark-11" });
        break;
      case COLOR.primary:
        setBgColor("bg-primary-light-11 dark:bg-primary-dark-11");
        setAccordionColors({ title: "text-white dark:text-black", description: "text-primary-light-8 dark:text-primary-dark-8" });
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        setAccordionColors({ title: "text-primary-light-12 dark:text-primary-dark-12", description: "text-primary-light-11 dark:text-primary-dark-11" });
        break;
      case COLOR.light:
        setBgColor("bg-white dark:bg-black");
        setAccordionColors({ title: "text-gray-light-12 dark:text-gray-dark-12", description: "text-gray-light-11 dark:text-gray-dark-11" });
        break;
      default:
        setBgColor("bg-white dark:bg-blac");
        setAccordionColors({ title: "text-gray-light-12 dark:text-gray-dark-12", description: "text-gray-light-11 dark:text-gray-dark-11" });
        break;
    }
  }, [theme]);

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <div className={textAndAccordionsCn()}>
          <div className={contentCn()}>
            <Content {...content} theme={theme} size={SIZE.sm} position={POSITIONS.left} />
          </div>
          <div className={accordionsCn()}>
            {accordions?.map((accordion, index) => {
              return <AccordionOld key={`accordion-${index}`} colors={accordionColors} {...accordion} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const AccordionOld = ({ title, colors, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const accordionCn = () => cntl`
    flex
    gap-y-2
    flex-col
  `;

  const titleCn = () => cntl`
    text-lg
    font-medium
    ${colors?.title}
  `;

  const descriptionCn = () => cntl`
    mr-6
    text-base
    ease-in-out
    duration-200
    overflow-hidden
    ${colors?.description}
    ${isOpen ? cntl`leading-normal` : cntl`leading-[0]`}
  `;

  const titleAndIconCn = () => cntl`
    flex
    flex-row
    items-start
    cursor-pointer
    justify-between
  `;

  const iconCn = () => cntl`
    w-6
    h-6
    ml-2
    shrink-0
    text-gray-light-10
    dark:text-gray-dark-10
  `;

  return (
    <div className={accordionCn()}>
      <div className={titleAndIconCn()} onClick={() => setIsOpen(!isOpen)}>
        <div className={titleCn()}>{title}</div>
        {isOpen ? <IconCircleMinus className={iconCn()} /> : <IconCirclePlus className={iconCn()} />}
      </div>
      <div className={descriptionCn()}>{description}</div>
    </div>
  );
};

FAQSectionAccordion03.prototype = propTypes;
export default FAQSectionAccordion03;
