import cntl from "cntl";
import { Children } from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

function ContentSplitHalf({ children, className }) {
  const containerCn = () => cntl`
    mx-auto
    container
    ${className ? className : undefined}
  `;

  const wrapperCn = () => cntl`
    flex
    flex-col
    gap-x-16
    gap-y-12
    lg:flex-row
  `;

  const childCn = () => cntl`
    w-full
    lg:w-1/2
  `;

  return (
    <div className={containerCn()}>
      <div className={wrapperCn()}>
        {children &&
          Children.map(children, (child, index) => {
            return (
              <div key={`child-${index}`} className={childCn()}>
                {child}
              </div>
            );
          })}
      </div>
    </div>
  );
}

ContentSplitHalf.prototype = propTypes;
export default ContentSplitHalf;
