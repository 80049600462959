import cntl from "cntl";
import PropTypes from "prop-types";
import { FeaturedIcon } from "../atoms";
import FileUploadItemBase from "./FileUploadItemBase";
import React, { useMemo, useState, useEffect, Fragment } from "react";
import { SIZE, COLOR, STATUS, FORMAT_ACCEPTED, FEATURED_ICON_VARIANTS } from "../constants";
import { IconCloudUpload, IconFile, IconPhoto } from "@tabler/icons-react";

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
  progress: PropTypes.number,
  isDisabled: PropTypes.bool,
  isMultiple: PropTypes.bool,
  className: PropTypes.string,
  onRemoveFile: PropTypes.func,
  onResetStatus: PropTypes.func,
  showListFiles: PropTypes.bool,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md]),
  status: PropTypes.oneOf([STATUS.success, STATUS.error, STATUS.undefined]),
  formatAccepted: PropTypes.oneOf([FORMAT_ACCEPTED.json, FORMAT_ACCEPTED.image, FORMAT_ACCEPTED.document]),
};

function FileUpload({
  id,
  text,
  size,
  name,
  status,
  onChange,
  progress,
  className,
  isDisabled,
  width = "",
  onRemoveFile,
  onResetStatus,
  formatAccepted,
  isMultiple = false,
  showListFiles = true,
}) {
  const [formats, setFormats] = useState("");
  const [iconUpload, setIconUpload] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileUploadWidth, setFileUploadWidth] = useState("");
  const [hoverColorFeatIcon, setHoverColorFeatIcon] = useState(COLOR.gray);

  useEffect(() => {
    window.addEventListener("reset", () => setUploadedFiles([]), true);

    return () => {
      window.removeEventListener("reset", () => setUploadedFiles([]), true);
    };
  }, []);

  const fileUploadWrapperCn = () => cntl`
    flex
    py-4
    px-6
    group
    border
    gap-y-3
    flex-col
    min-h-32
    rounded-lg
    cursor-pointer
    border-gray-light-6
    dark:border-gray-dark-6
    hover:bg-primary-light-1
    hover:border-primary-light-4
    dark:hover:bg-primary-dark-1
    dark:hover:border-primary-dark-4
    ${fileUploadWidth}
    ${className ? className : undefined}
    ${isDisabled ? cntl`bg-gray-light-2 dark:bg-gray-dark-2 pointer-events-none	` : cntl`bg-white`}
  `;

  const featuredIconCn = () => cntl`
    self-center
  `;

  const pCn = () => cntl`
    text-sm
    text-center
    text-gray-light-11
    dark:text-gray-dark-11
    whitespace-pre-line
    first-letter:uppercase
    group-hover:text-gray-light-11
    dark:group-hover:text-gray-dark-11
  `;

  const inputCn = () => cntl`
    sr-only	
  `;

  useEffect(() => {
    let element = document.getElementById("container-file-upload");

    element.addEventListener("mouseenter", () => {
      setHoverColorFeatIcon(COLOR.primary);
    });

    element.addEventListener("mouseleave", () => {
      setHoverColorFeatIcon(COLOR.gray);
    });
  }, []);

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setFileUploadWidth("w-[21.25rem]");
        break;
      case SIZE.md:
        setFileUploadWidth("w-[32rem]");
        break;
      default:
        setFileUploadWidth(width);
        break;
    }
  }, [size, width]);

  useMemo(() => {
    switch (formatAccepted) {
      case FORMAT_ACCEPTED.json:
        setFormats(".json");
        setIconUpload(<IconFile />);
        break;
      case FORMAT_ACCEPTED.image:
        setIconUpload(<IconPhoto />);
        setFormats(".jpg, .png, .jpeg, .svg");
        break;
      case FORMAT_ACCEPTED.document:
        setFormats(".pdf");
        setIconUpload(<IconFile />);
        break;
      default:
        setIconUpload(<IconPhoto />);
        setFormats(".jpg, .png, .jpeg, .svg");
        break;
    }
  }, [formatAccepted]);

  const handleUploadFiles = (files) => {
    const uploaded = [];

    files.forEach((file) => {
      if (uploaded.findIndex((f) => f?.name === file?.name) === -1) {
        uploaded.push(file);
      }
    });
    if (uploaded?.length) {
      onChange(uploaded);
      setUploadedFiles(uploaded);
      if (showListFiles) onResetStatus();
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const onRefetchList = (index) => {
    const newFiles = uploadedFiles?.filter((_, idx) => idx !== index);
    setUploadedFiles(newFiles);
    onChange(newFiles);
    onRemoveFile();
  };

  return (
    <>
      <label id="container-file-upload" className={fileUploadWrapperCn()}>
        <input
          id={id}
          name={name}
          type="file"
          accept={formats}
          className={inputCn()}
          multiple={isMultiple}
          onChange={handleFileEvent}
        />
        <FeaturedIcon
          size={SIZE.md}
          color={hoverColorFeatIcon}
          className={featuredIconCn()}
          variant={FEATURED_ICON_VARIANTS.light_circle}
        >
          <IconCloudUpload />
        </FeaturedIcon>
        <p className={pCn()}>{text}</p>
      </label>
      {showListFiles &&
        !!uploadedFiles?.length &&
        uploadedFiles.map((file, index) => {
          return (
            <Fragment key={`file-upload-${index}`}>
              <FileUploadItemBase
                className="mt-4"
                icon={iconUpload}
                status={
                  status === STATUS.success ? STATUS.success : status === STATUS.error ? STATUS.error : STATUS.undefined
                }
                sizePB={file?.size}
                labelPB={file?.name}
                progressPB={progress}
                onRemoveFile={() => onRefetchList(index)}
              />
            </Fragment>
          );
        })}
    </>
  );
}

FileUpload.propTypes = propTypes;
export default FileUpload;
