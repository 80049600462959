import { createSlice } from "@reduxjs/toolkit";
import { NOTIFICATION_VARIANTS } from "@dbox/components/constants";

export const defaultState = {
  snackbar: {
    message: "",
    type: "error",
    open: false,
    timeoutId: null,
  },
  notification: {
    id: "",
    type: "",
    open: false,
    title: "",
    message: "",
    children: "",
    timeStamp: "",
    timeoutId: null,
    variant: NOTIFICATION_VARIANTS.toast,
  },
  bottomToolbar: {
    open: false,
  },
  hasOnePickerOpened: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: defaultState,
  reducers: {
    openSnackbar(state, { payload }) {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          type: payload.type,
          message: payload.message,
          open: true,
          timeoutId: payload.timeoutId,
        },
      };
    },
    closeSnackbar(state) {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
          timeoutId: null,
        },
      };
    },
    openNotification(state, { payload }) {
      return {
        ...state,
        notification: {
          ...state.notification,
          open: true,
          id: payload.id,
          type: payload.type,
          title: payload.title,
          variant: payload.variant,
          message: payload.message,
          children: payload.children,
          timeoutId: payload.timeoutId,
          timeStamp: payload.timeStamp,
        },
      };
    },
    closeNotification(state, { variant }) {
      return {
        ...state,
        notification: {
          ...state.notification,
          open: false,
          timeoutId: null,
          ...(variant === NOTIFICATION_VARIANTS.inline && { variant: "" }),
        },
      };
    },
    closeAllPickers(state) {
      return {
        ...state,
        hasOnePickerOpened: false,
      };
    },
    setPickerOpen(state) {
      return {
        ...state,
        hasOnePickerOpened: true,
      };
    },
    openBottomToolbar(state) {
      return {
        ...state,
        bottomToolbar: {
          open: true,
        },
      };
    },
    closeBottomToolbar(state) {
      return {
        ...state,
        bottomToolbar: {
          open: false,
        },
      };
    },
  },
});

export default uiSlice;

export const getNotificationState = (state) => state.notification;
export const getSnackbarState = (state) => state.snackbar;
export const getNotificationTimeoutId = (state) => state.notification.timeoutId;
export const getBottomToolbarState = (state) => state.bottomToolbar;
export const getSnackbarTimeoutId = (state) => state.snackbar.timeoutId;
