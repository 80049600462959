import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { ButtonGrey, ButtonPrimary, FeaturedIcon } from "../atoms";
import { SIZE, COLOR, POSITIONS, BUTTON_VARIANTS, FEATURED_ICON_VARIANTS } from "../constants";
import { BadgeText } from "../molecules";

const propTypes = {
  links: PropTypes.array,
  icon: PropTypes.object,
  label: PropTypes.string,
  title: PropTypes.string,
  pretitle: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  position: PropTypes.oneOf([POSITIONS.left, POSITIONS.center]),
  theme: PropTypes.oneOf([COLOR.dark, COLOR.gray, COLOR.light, COLOR.primary, COLOR.primary_light]),
};

function SectionContent({ icon, size, label, title, links, theme, pretitle, position, description }) {
  const [textColor, setTextColor] = useState("");
  const [titleSize, setTitleSize] = useState("");
  const [badgeColor, setBadgeColor] = useState("");
  const [titleColor, setTitleColor] = useState("");
  const [preTitleColor, setPreTitleColor] = useState("");
  const [featuredIconColor, setFeaturedIconColor] = useState(null);
  const [gapTitleDescription, setGapTitleDescription] = useState("");
  const [featuredIconVariant, setFeaturedIconVariant] = useState(null);

  const pretitleCn = () => cntl`
    font-semibold
    text-sm md:text-base
    ${preTitleColor}
  `;

  const titleCn = () => cntl`
    ${titleSize}
    font-semibold
    ${titleColor}
  `;

  const descriptionCn = () => cntl`
    font-normal
    text-lg md:text-xl
    ${textColor}
  `;

  const badgeCn = () => cntl`
    ${badgeColor}
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setTitleColor("text-white dark:text-black");
        setTextColor("text-gray-light-8 dark:text-gray-dark-8");
        setFeaturedIconColor(COLOR.gray);
        setPreTitleColor("text-gray-light-8 dark:text-gray-dark-8");
        setBadgeColor(COLOR.gray);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.dark);
        break;
      case COLOR.gray:
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        setFeaturedIconColor(COLOR.primary);
        setPreTitleColor("text-primary-light-11");
        setBadgeColor(COLOR.gray);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.light_circle_outline);
        break;
      case COLOR.primary:
        setTitleColor("text-white dark:text-black");
        setTextColor("text-primary-light-8 dark:text-primary-dark-8");
        setFeaturedIconColor(COLOR.primary);
        setPreTitleColor("text-primary-light-8 dark:text-primary-dark-8");
        setBadgeColor(COLOR.gray);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.dark);
        break;
      case COLOR.primary_light:
        setTextColor("text-primary-light-11 dark:text-primary-dark-11");
        setTitleColor("text-primary-light-12 dark:text-primary-dark-12");
        setFeaturedIconColor(COLOR.primary);
        setPreTitleColor("text-primary-light-11 dark:text-primary-dark-11");
        setBadgeColor(COLOR.primary);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.light_circle_outline);
        break;
      default:
        setTextColor("text-primary-light-11 dark:text-primary-dark-11");
        setTitleColor("text-primary-light-12 dark:text-primary-dark-12");
        setFeaturedIconColor(COLOR.primary);
        setPreTitleColor("text-primary-light-11 dark:text-primary-dark-11");
        setBadgeColor(COLOR.gray);
        setFeaturedIconVariant(FEATURED_ICON_VARIANTS.light_circle_outline);
        break;
    }
  }, [theme]);

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setTitleSize("text-4xl");
        setGapTitleDescription("gap-y-4 md:gap-y-5");
        break;
      case SIZE.md:
        setTitleSize("text-5xl");
        setGapTitleDescription("gap-y-4 md:gap-y-6");
        break;
      case SIZE.lg:
        setTitleSize("text-6xl");
        setGapTitleDescription("gap-y-4 md:gap-y-6");
        break;
      default:
        setTitleSize("text-5xl");
        setGapTitleDescription("gap-y-4 md:gap-y-6");
        break;
    }
  }, [size]);

  const containerWrapperCn = () => cntl`
    flex flex-col gap-3
  `;

  const iconlabelpretitleWrapperCn = () => cntl`
    flex
    ${position === POSITIONS.center ? "justify-center" : "justify-start"}
    mb-5
  `;
  const textWrapperCn = () => cntl`
    flex
    ${position === POSITIONS.center ? "justify-center text-center" : "justify-start text-left"}
  `;
  const buttonsWrapperCn = () => cntl`
    flex
    gap-3
    flex-col
    md:flex-row
    ${position === POSITIONS.center ? "justify-center text-center" : "justify-start text-left"}
  `;
  const textInnerWrapperCn = () => cntl`
    max-w-3xl flex flex-col
    ${gapTitleDescription}
    ${position === POSITIONS.center ? "mx-auto" : ""}
  `;

  return (
    <div className={containerWrapperCn()}>
      {(icon || label || pretitle) && (
        <div className={iconlabelpretitleWrapperCn()}>
          {icon && (
            <FeaturedIcon size={SIZE.lg} color={featuredIconColor} variant={featuredIconVariant}>
              {icon}
            </FeaturedIcon>
          )}
          {pretitle && <div className={pretitleCn()}>{pretitle}</div>}
          {label && <BadgeText className={badgeCn()} label={label} />}
        </div>
      )}
      <div className="flex flex-col gap-y-8 md:gap-y-10">
        <div className={textWrapperCn()}>
          <div className={textInnerWrapperCn()}>
            {title && <div className={titleCn()} dangerouslySetInnerHTML={{ __html: title }} />}
            {description && <div className={descriptionCn()} dangerouslySetInnerHTML={{ __html: description }} />}
          </div>
        </div>
        {links && (
          <div className={buttonsWrapperCn()}>
            {links?.map(({ text, link, variant }, index) => {
              if (variant === "primary") {
                return (
                  <ButtonPrimary
                    label={text}
                    size={SIZE.md}
                    key={`primary-button-${index}`}
                    onClick={() => window.location.replace(link)}
                  />
                );
              }
              return (
                <ButtonGrey
                  label={text}
                  size={SIZE.md}
                  key={`grey-button-${index}`}
                  variant={BUTTON_VARIANTS.outline}
                  onClick={() => window.location.replace(link)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

SectionContent.prototype = propTypes;
export default SectionContent;
