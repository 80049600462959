import cntl from "cntl";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "@dbox/core/actions/common/ui";
import { NOTIFICATION_TYPES, NOTIFICATION_VARIANTS } from "../../constants";
import { getNotificationState } from "@dbox/aldys-common/store/reducers/app.reducer";
import {
  IconX,
  IconAlertCircleFilled,
  IconCircleCheckFilled,
  IconAlertTriangleFilled,
  IconCircleArrowUpFilled,
} from "@tabler/icons-react";

function Notification({ variantsAllowed }) {
  const dispatch = useDispatch();
  const notification = useSelector(getNotificationState);

  const onClose = () => dispatch(closeNotification({ variant: notification?.variant }));

  const baseToast = () => cntl`
    p-3
    z-50
    mb-4
    mx-4
    w-96
    fixed
    right-0
    bottom-0
    max-w-sm
    rounded-md
    shadow-light-lg
    dark:shadow-dark-lg
  `;

  const baseInline = () => cntl`
    py-3
    px-6
    w-full
  `;

  const infoCn = ({ variant }) => cntl`
    border
    bg-gray-light-3
    border-gray-light-6
    dark:bg-gray-dark-3
    dark:border-gray-dark-6
    ${variant === NOTIFICATION_VARIANTS.toast ? baseToast() : baseInline()}
  `;

  const errorCn = ({ variant }) => cntl`
    border
    bg-error-light-3
    border-error-light-6
    dark:bg-error-dark-3
    dark:border-error-dark-6
    ${variant === NOTIFICATION_VARIANTS.toast ? baseToast() : baseInline()}
  `;

  const successCn = ({ variant }) => cntl`
    border
    bg-success-light-3
    border-success-light-6
    dark:bg-success-dark-3
    dark:border-success-dark-6
    ${variant === NOTIFICATION_VARIANTS.toast ? baseToast() : baseInline()}
  `;

  const warningCn = ({ variant }) => cntl`
    border
    bg-warning-light-3
    border-warning-light-6
    dark:bg-warning-dark-3
    dark:border-warning-dark-6
    ${variant === NOTIFICATION_VARIANTS.toast ? baseToast() : baseInline()}
  `;

  const notificationCn = ({ open, type, variant }) => cntl`
    duration-300
    transition-transform
    ${open ? `translate-y-0` : `translate-y-[200px]`}
    ${
      type?.toLowerCase() === NOTIFICATION_TYPES.info
        ? infoCn({ variant })
        : type?.toLowerCase() === NOTIFICATION_TYPES.success
        ? successCn({ variant })
        : type?.toLowerCase() === NOTIFICATION_TYPES.error
        ? errorCn({ variant })
        : type?.toLowerCase() === NOTIFICATION_TYPES.warning
        ? warningCn({ variant })
        : infoCn({ variant })
    }
  `;

  const contentCn = () => cntl`
    flex
    justify-between
  `;

  const iconAndMessageCn = () => cntl`
    flex
    gap-x-3
    items-start
  `;

  const iconCn = ({ isCloseIcon }) => cntl`
    w-5
    h-5
    flex
    shrink-0
    items-center
    justify-center
    ${isCloseIcon ? `cursor-pointer` : `cursor-auto`}
  `;

  const iconMessageCn = ({ type }) => cntl`
    ${
      type?.toLowerCase() === NOTIFICATION_TYPES.info
        ? `text-primary-light-9 dark:text-primary-dark-9`
        : type?.toLowerCase() === NOTIFICATION_TYPES.success
        ? `text-success-light-9 dark:text-success-dark-9`
        : type?.toLowerCase() === NOTIFICATION_TYPES.error
        ? `text-error-light-9 dark:text-error-dark-9`
        : type?.toLowerCase() === NOTIFICATION_TYPES.warning
        ? `text-warning-light-9 dark:text-warning-dark-9`
        : `text-primary-light-9 dark:text-primary-dark-9`
    }
  `;

  const messageProps = {
    type: notification.type,
    title: notification.title,
    id: notification?.id ?? "",
    message: notification.message,
    ...(notification?.children && { children: notification.children }),
    ...(notification?.timeStamp && { timeStamp: notification.timeStamp }),
  };

  if (variantsAllowed?.includes(notification.variant)) {
    return (
      <div
        id="notification"
        className={notificationCn({
          open: notification.open,
          type: notification.type,
          variant: notification.variant,
        })}
      >
        <div className={contentCn({ open: notification.open })}>
          <div className={iconAndMessageCn()}>
            <div className={iconCn({ isCloseIcon: false })}>
              {notification.type === NOTIFICATION_TYPES.info ? (
                <IconCircleArrowUpFilled className={iconMessageCn({ type: notification.type })} />
              ) : notification.type === NOTIFICATION_TYPES.error ? (
                <IconAlertTriangleFilled className={iconMessageCn({ type: notification.type })} />
              ) : notification.type === NOTIFICATION_TYPES.success ? (
                <IconCircleCheckFilled className={iconMessageCn({ type: notification.type })} />
              ) : notification.type === NOTIFICATION_TYPES.warning ? (
                <IconAlertCircleFilled className={iconMessageCn({ type: notification.type })} />
              ) : (
                <IconCircleArrowUpFilled className={iconMessageCn({ type: notification.type })} />
              )}
            </div>
            {notification.variant === NOTIFICATION_VARIANTS.inline ? (
              <MessageInline {...messageProps} />
            ) : (
              <MessageToast {...messageProps} />
            )}
          </div>
          <div className={iconCn({ isCloseIcon: true })} onClick={onClose}>
            <IconX />
          </div>
        </div>
      </div>
    );
  }
}

const MessageInline = ({ title, message, type }) => {
  const messageInline = () => cntl`
    flex
    gap-y-1
    flex-col
  `;

  const titleAndMessageStyle = ({ type, isMessage }) => cntl`
    text-sm
    ${isMessage ? `font-regular` : `font-semibold`}
    ${
      type?.toLowerCase() === NOTIFICATION_TYPES.info
        ? `text-gray-light-12 dark:text-gray-dark-12`
        : type?.toLowerCase() === NOTIFICATION_TYPES.success
        ? `text-gray-success-12 dark:text-success-dark-12`
        : type?.toLowerCase() === NOTIFICATION_TYPES.error
        ? `text-error-light-12 dark:text-error-dark-12`
        : type?.toLowerCase() === NOTIFICATION_TYPES.warning
        ? `text-warning-light-12 dark:text-warning-dark-12`
        : `text-gray-light-12 dark:text-gray-dark-12`
    }
  `;

  return (
    <div className={messageInline()}>
      <div className={titleAndMessageStyle({ type, isMessage: false })}>{title}</div>
      <div className={titleAndMessageStyle({ type, isMessage: false })}>{message}</div>
    </div>
  );
};

const MessageToast = ({ type, title, message, children, timeStamp }) => {
  const messageToastCn = () => cntl`
    flex
    gap-y-2
    flex-col
  `;

  const titleAndMessageCn = () => cntl`
    flex
    gap-y-1
    flex-col
    max-w-[18rem]
  `;

  const titleAndMessageStyle = ({ type, isMessage }) => cntl`
    text-sm
    ${isMessage ? `font-regular` : `font-semibold`}
    ${
      type?.toLowerCase() === NOTIFICATION_TYPES.info
        ? `text-gray-light-12 dark:text-gray-dark-12`
        : type?.toLowerCase() === NOTIFICATION_TYPES.success
        ? `text-gray-success-12 dark:text-success-dark-12`
        : type?.toLowerCase() === NOTIFICATION_TYPES.error
        ? `text-error-light-12 dark:text-error-dark-12`
        : type?.toLowerCase() === NOTIFICATION_TYPES.warning
        ? `text-warning-light-12 dark:text-warning-dark-12`
        : `text-gray-light-12 dark:text-gray-dark-12`
    }
  `;

  const actionAndTimeStampCn = () => cntl`
    flex
    gap-y-2
    flex-col
  `;

  const timeStampCn = ({ type }) => cntl`
    text-sm
    ${
      type?.toLowerCase() === NOTIFICATION_TYPES.info
        ? `text-gray-light-11 dark:text-gray-dark-11`
        : type?.toLowerCase() === NOTIFICATION_TYPES.success
        ? `text-gray-success-11 dark:text-success-dark-11`
        : type?.toLowerCase() === NOTIFICATION_TYPES.error
        ? `text-error-light-11 dark:text-error-dark-11`
        : type?.toLowerCase() === NOTIFICATION_TYPES.warning
        ? `text-warning-light-11 dark:text-warning-dark-11`
        : `text-gray-light-11 dark:text-gray-dark-11`
    }
  `;

  return (
    <div className={messageToastCn()}>
      <div className={titleAndMessageCn()}>
        <div className={titleAndMessageStyle({ type, isMessage: false })}>{title}</div>
        <div className={titleAndMessageStyle({ type, isMessage: true })}>{message}</div>
      </div>
      <div className={actionAndTimeStampCn()}>
        <>{children}</>
        <div className={timeStampCn({ type })}>{timeStamp}</div>
      </div>
    </div>
  );
};

export default Notification;
