import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import { Children, Fragment, forwardRef } from "react";

const propTypes = {
  props: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

const Sidebar = forwardRef(function Sidebar({ children, ...props }, ref) {
  return (
    <nav ref={ref} {...props}>
      {children
        ? Children.map(children, (child) => {
            return <Fragment key={uniqueId("child-sidebar-")}>{child}</Fragment>;
          })
        : null}
    </nav>
  );
});

Sidebar.propTypes = propTypes;
export default Sidebar;
