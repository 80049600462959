import cntl from "cntl";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import { Fragment, Children, cloneElement, forwardRef } from "react";

const propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

const Menu = forwardRef(function Menu({ style, isVisible, onClick, children, className, onSelect }, ref) {
  const menuCn = () => cntl`
    z-50
    border
    shadow-light-lg
    dark:shadow-dark-lg
    rounded-lg
    bg-gray-light-3
    border-gray-light-6
    dark:bg-gray-dark-3
    dark:border-gray-dark-6
    ${className ? className : undefined}
  `;

  return (
    <>
      {isVisible && (
        <div style={style} className={menuCn()} ref={ref}>
          {children
            ? Children.map(children, (child) => {
                return <Fragment key={uniqueId("menu-children-")}>{cloneElement(child, { onClick, onSelect })}</Fragment>;
              })
            : null}
        </div>
      )}
    </>
  );
});

Menu.propTypes = propTypes;
export default Menu;
