import cntl from "cntl";
import { get } from "lodash";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useEffect, useMemo, useRef, useState } from "react";

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  showLabel: PropTypes.bool,
  onClick: PropTypes.func,
  isIndeterminated: PropTypes.bool,
  supportingText: PropTypes.string,
  formRegistration: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl]),
};

function Checkbox({
  id,
  name,
  size,
  label,
  value,
  errors,
  onBlur,
  onClick,
  onChange,
  isChecked,
  className,
  isDisabled,
  supportingText,
  showLabel = true,
  formRegistration = null,
  isIndeterminated = null,
}) {
  const ref = useRef(null);

  const [rounded, setRounded] = useState("");
  const [fontStyle, setFontStyle] = useState("");
  const [checkboxSize, setCheckboxSize] = useState("");

  const { onChange: onFormChange, onBlur: onFormBlur, name: formName, ...rest } = formRegistration || {};

  const computedName = name || formName;
  const computedId = id || computedName;
  const errorMessage = get(errors, `[${formName}].message`, "");

  const checkboxWrapperCn = () => cntl`
    flex
    flex-row
    items-start
    gap-x-2 
    ${className ? className : undefined}
  `;

  const inputCn = ({ hasErrors }) => cntl`
    mt-0.5
    border
    form-checkbox
    border
    focus:ring-0
    focus:outline-0
    focus:outline-none
    focus:ring-offset-0
    ${
      isDisabled
        ? `border-lightAlpha-6 dark:border-darkAlpha-6 checked:bg-gray-lightAlpha-3 dark:checked:bg-gray-darkAlpha-3 checked:hover:bg-gray-lightAlpha-3 dark:checked:hover:bg-gray-darkAlpha-3`
        : `focus:checked:bg-primary-lightAlpha-10 focus:checked:dark:bg-primary-darkAlpha-10 hover:bg-white[.9] dark:hover:bg-black/[.9] bg-white/[.9] dark:bg-black/[.9] checked:hover:bg-primary-lightAlpha-10 dark:checked:hover:bg-primary-darkAlpha-10 checked:bg-primary-lightAlpha-10 dark:checked:bg-primary-darkAlpha-10 border-gray-lightAlpha-9 dark:border-gray-darkAlpha-9`
    }
    ${rounded}
    ${checkboxSize}
  `;

  const labelCn = () => cntl`
    ${fontStyle}
  `;

  const onlyLabelCn = () => cntl`
    text-gray-light-12
    dark:text-gray-dark-12
    first-letter:capitalize
    ${!!supportingText?.length ? cntl`font-medium` : cntl`font-regular`}
  `;

  const onlySupportingTextCn = () => cntl`
    text-gray-light-11
    dark:text-gray-dark-11
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.xs:
        setRounded("rounded");
        setFontStyle("text-xs");
        setCheckboxSize("w-3.5 h-3.5");
        break;
      case SIZE.sm:
        setRounded("rounded");
        setFontStyle("text-sm");
        setCheckboxSize("w-4 h-4");
        break;
      case SIZE.md:
        setRounded("rounded-md");
        setFontStyle("text-base");
        setCheckboxSize("w-[1.125rem] h-[1.125rem]");
        break;
      case SIZE.lg:
        setRounded("rounded");
        setFontStyle("text-base");
        setCheckboxSize("w-5 h-5");
        break;
      case SIZE.xl:
        setRounded("rounded");
        setFontStyle("text-base");
        setCheckboxSize("w-6 h-6");
        break;
      default:
        setRounded("rounded");
        setFontStyle("text-sm");
        setCheckboxSize("w-4 h-4");
        break;
    }
  }, [size]);

  useEffect(() => {
    if (isIndeterminated !== null) {
      if (isIndeterminated) {
        ref.current.indeterminate = true;
      } else {
        ref.current.indeterminate = false;
      }
    }
  }, [isIndeterminated]);

  return (
    <div className={checkboxWrapperCn()}>
      <input
        ref={ref}
        id={computedId}
        value={value}
        type="checkbox"
        onClick={onClick}
        checked={isChecked}
        name={computedName}
        disabled={isDisabled}
        aria-hidden="true"
        aria-invalid={errorMessage ? "true" : "false"}
        className={inputCn({ hasErrors: !!errorMessage })}
        onChange={(e) => {
          if (onFormChange) {
            onFormChange(e);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          if (onFormBlur) {
            onFormBlur(e);
          }
          if (onBlur) {
            onBlur(e);
          }
        }}
        {...rest}
      />
      {showLabel && (
        <label htmlFor={computedId} className={labelCn()}>
          <div className={onlyLabelCn()}>{label}</div>
          {!!supportingText?.length && <div className={onlySupportingTextCn()}>{supportingText}</div>}
        </label>
      )}
    </div>
  );
}

Checkbox.propTypes = propTypes;
export default Checkbox;
