import cntl from "cntl";
import PropTypes from "prop-types";
import { COLOR } from "../constants";
import { useMemo, useState } from "react";
import { ReviewRating } from "../molecules";

const propTypes = {
  quotes: PropTypes.array,
  theme: PropTypes.oneOf([COLOR.light, COLOR.dark, COLOR.gray, COLOR.primary, COLOR.primary_light]),
};

function QuoteSection({ quotes, theme }) {
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [titleColor, setTitleColor] = useState("");
  const [currentQuote, setCurrentQuote] = useState(0);

  useMemo(() => {
    switch (theme) {
      case COLOR.gray:
        setBgColor("bg-gray-light-2 dark:bg-gray-dark-2");
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        setTextColor("text-primary-light-11 dark:text-primary-dark-11");
        setTitleColor("text-primary-light-12 dark:text-primary-dark-12");
        break;
      case COLOR.primary:
        setTitleColor("text-white dark:text-black");
        setBgColor("bg-primary-light-10 dark:bg-primary-dark-10");
        setTextColor("text-primary-light-8 dark:text-primary-dark-8");
        break;
      case COLOR.dark:
        setBgColor("bg-gray-light-10 dark:bg-gray-dark-10");
        setTitleColor("text-white dark:text-black");
        setTextColor("text-gray-light-8 dark:text-gray-dark-8");
        break;
      case COLOR.light:
        setBgColor("bg-white dark:bg-black");
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
      default:
        setBgColor("bg-white dark:bg-black");
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
    }
  }, [theme]);

  const caroulselDot = (index) => cntl`
    rounded-lg 
    w-2.5 
    h-2.5
    ${index === currentQuote ? "bg-primary-light-5 dark:bg-primary-dark-5" : " bg-gray-light-4 dark:bg-gray-dark-4"}
  `;

  const wrapperCn = () => cntl`
    px-4
    py-16
    lg:px-8
    lg:py-24
    w-full
    ${bgColor}
    ${textColor}
  `;

  const containerCn = () => cntl`
    w-full
    container
    lg:mx-auto
    flex
    flex-col
    gap-y-10
    justify-center
    items-center
  `;

  const dotsCn = () => cntl`
    flex
    flex-row
    gap-x-3
  `;

  const contentCn = () => cntl`
    flex
    flex-col
    justify-center
    items-center
    gap-y-8
    lg:px-16
  `;

  const quoteCn = () => cntl`
    text-center
    font-medium
    text-3xl
    lg:text-4xl
    lg:px-8
    ${titleColor}
  `;

  const userCn = () => cntl`
    flex
    flex-col
    justify-center
    items-center
    gap-y-4
  `;

  const userImageCn = () => cntl`
    w-fit
    h-fit
    rounded-full
    overflow-hidden
  `;

  const userTextCn = () => cntl`
    flex
    flex-col
    gap-y-1
    justify-center
    items-center
  `;

  const userNameCn = () => cntl`
    text-center
    text-lg
    font-medium
    ${titleColor}
  `;

  const userRoleCn = () => cntl`
    text-center
  `;

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        {quotes?.map((quote, index) => {
          if (index === currentQuote) {
            return (
              <div key={index} className={contentCn()}>
                <div className={quoteCn()}>{quote.text}</div>
                <div className={userCn()}>
                  <div className={userImageCn()}>
                    <img alt="" src={quote.user.profileImage} />
                  </div>
                  <div className={userTextCn()}>
                    <div className={userNameCn()}>{quote.user.name}</div>
                    <div className={userRoleCn()}>{quote.user.role}</div>
                  </div>
                  <ReviewRating nFullStars={quote.rating} hasHalf={quote.halfStar} />
                </div>
              </div>
            );
          } else {
            return false;
          }
        })}
        <div className={dotsCn()}>
          {quotes?.map((_quote, index) => {
            return <div key={index} onClick={() => setCurrentQuote(index)} className={caroulselDot(index)}></div>;
          })}
        </div>
      </div>
    </div>
  );
}

QuoteSection.prototype = propTypes;
export default QuoteSection;
