import cntl from "cntl";
import { Checkbox } from "../atoms";
import PropTypes from "prop-types";
import ButtonGrey from "../atoms/button/ButtonGrey";
import ButtonError from "../atoms/button/ButtonError";
import ButtonPrimary from "../atoms/button/ButtonPrimary";
import { useMemo, useState, cloneElement } from "react";
import { BUTTON_VARIANTS, COLOR, MODAL_ACTION_VARIANTS, SIZE } from "../constants";

const propTypes = {
  isLoading: PropTypes.bool,
  iconLink: PropTypes.object,
  labelLink: PropTypes.string,
  className: PropTypes.string,
  onClickLink: PropTypes.func,
  hasPaddingTop: PropTypes.bool,
  hasBorderTop: PropTypes.bool,
  hasSubmitButton: PropTypes.bool,
  labelCheckbox: PropTypes.string,
  labelSubmitBtn: PropTypes.string,
  labelCancelBtn: PropTypes.string,
  onClickSubmitBtn: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onClickCancelBtn: PropTypes.func,
  disabledSubmitBtn: PropTypes.bool,
  color: PropTypes.oneOf([COLOR.primary, COLOR.error]),
  variant: PropTypes.oneOf([
    MODAL_ACTION_VARIANTS.row,
    MODAL_ACTION_VARIANTS.column,
    MODAL_ACTION_VARIANTS.other_actions,
  ]),
};

function ModalAction({
  color,
  variant,
  iconLink,
  className,
  isLoading,
  labelLink,
  onClickLink,
  labelCheckbox,
  labelSubmitBtn,
  labelCancelBtn,
  onClickSubmitBtn,
  onChangeCheckbox,
  onClickCancelBtn,
  hasBorderTop = false,
  hasSubmitButton = true,
  disabledSubmitBtn = false,
}) {
  const [width, setWidth] = useState("");
  const [direction, setDirection] = useState("");

  const actionCn = () => cntl`
    flex
    gap-3
    items-center
    justify-between
    ${direction}
    ${className ? className : undefined}
    ${
      hasBorderTop
        ? cntl`border-t border-t-gray-light-6 dark:border-t-gray-dark-6 p-4 pt-4 sm:p-6`
        : cntl`p-4 pt-6 sm:p-6 sm:pt-8`
    }
  `;

  const buttonCn = () => cntl`
    ${width}
  `;

  const buttonsCn = () => cntl`
    flex
    gap-3
    ${width}
    ${direction}
  `;

  const checkboxAndLinkCn = () => cntl`
    flex
    w-full
    gap-x-3
    flex-row
    items-center
    justify-between
    sm:justify-start
  `;

  const iconLinkCn = () => cntl`
    w-5
    h-5
    text-gray-light-11 
    dark:text-gray-dark-11
  `;

  const linkCn = () => cntl`
    flex
    gap-x-1
    flex-row
    text-base
    items-center
    font-semibold
    text-gray-light-11 
    dark:text-gray-dark-11
    cursor-pointer
  `;

  const buttonProps = useMemo(
    () => ({
      isLoading,
      size: SIZE.md,
      label: labelSubmitBtn,
      onClick: onClickSubmitBtn,
    }),
    [isLoading, labelSubmitBtn, onClickSubmitBtn]
  );

  const button = useMemo(() => {
    switch (color) {
      case COLOR.primary:
        return <ButtonPrimary {...buttonProps} />;
      case COLOR.error:
        return <ButtonError {...buttonProps} />;
      default:
        return <ButtonPrimary {...buttonProps} />;
    }
  }, [color, buttonProps]);

  useMemo(() => {
    switch (variant) {
      case MODAL_ACTION_VARIANTS.row:
        setWidth("w-full");
        setDirection("flex-row");
        break;
      case MODAL_ACTION_VARIANTS.column:
        setWidth("w-full");
        setDirection("flex-col-reverse sm:flex-col-reverse");
        break;
      case MODAL_ACTION_VARIANTS.other_actions:
        setWidth("w-full sm:w-[initial] ml-auto");
        setDirection("flex-col-reverse sm:flex-row");
        break;
      default:
        setWidth("w-full");
        setDirection("flex-row");
        break;
    }
  }, [variant]);

  return (
    <div className={actionCn()}>
      {variant === MODAL_ACTION_VARIANTS.other_actions && iconLink && onChangeCheckbox && (
        <div className={checkboxAndLinkCn()}>
          <Checkbox
            size={SIZE.sm}
            name={labelCheckbox}
            label={labelCheckbox}
            id="modal-action-checkbox"
            onChange={onChangeCheckbox}
          />

          <div className={linkCn()} onClick={onClickLink}>
            {cloneElement(iconLink, { className: iconLinkCn() })}
            {labelLink}
          </div>
        </div>
      )}
      <div className={buttonsCn()}>
        {onClickCancelBtn ? (
          <ButtonGrey
            label={labelCancelBtn}
            className={buttonCn()}
            onClick={onClickCancelBtn}
            variant={BUTTON_VARIANTS.outline}
          />
        ) : null}
        {hasSubmitButton &&
          cloneElement(button, {
            size: SIZE.md,
            className: buttonCn(),
            label: labelSubmitBtn,
            onClick: onClickSubmitBtn,
            disabled: disabledSubmitBtn,
          })}
      </div>
    </div>
  );
}

ModalAction.propTypes = propTypes;
export default ModalAction;
