import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import { useState, useCallback } from "react";
import { POSITIONS, SIZE } from "../../constants";
import { Avatar, Dropdown, Menu, Trigger } from "../../atoms";

const propTypes = {
  width: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  onItemSelect: PropTypes.func,
  isItemDisabled: PropTypes.bool,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl, SIZE["2xl"]]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

function AvatarDropdown({
  size,
  width,
  options,
  children,
  className,
  onItemSelect,
  isItemDisabled,
  ...props
}) {
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const offset = useCallback(
    ({ reference, popper }) => {
      if (props.buttonPosition === POSITIONS.left) {
        return [-((reference.width - popper.width) / 2), 5];
      } else {
        return [(reference.width - popper.width) / 2, 5];
      }
    },
    [props.buttonPosition]
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset
        },
      },
    ],
    strategy: "absolute",
  });

  return (
    <Dropdown size={size} width={width} className={className} onSelect={onItemSelect}>
      <Trigger ref={setReferenceElement}>
        <Avatar {...props} />
      </Trigger>
      <Menu ref={setPopperElement} style={styles.popper} {...attributes.popper} className="min-w-[7rem]">
        {children}
      </Menu>
    </Dropdown>
  );
}

AvatarDropdown.propTypes = propTypes;
export default AvatarDropdown;
