import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonPrimary, ButtonGrey, CheckItem, HeaderPricing } from "@dbox/components";
import { BUTTON_VARIANTS, CHECK_ITEM_ICON_VARIANTS, COLOR, POSITIONS, SIZE } from "@dbox/components/constants";

const propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  pretitle: PropTypes.string,
  checkItems: PropTypes.array,
  badgeLabel: PropTypes.number,
  isMostPopular: PropTypes.bool,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonIsDisabled: PropTypes.bool,
  labelMostPopular: PropTypes.string,
  buttonColor: PropTypes.oneOf([COLOR.primary, COLOR.gray]),
  alignment: PropTypes.oneOf([POSITIONS.left, POSITIONS.center]),
  theme: PropTypes.oneOf([COLOR.primary_light, COLOR.gray, COLOR.light]),
};

function PricingTierCard({
  icon,
  title,
  theme,
  subtitle,
  pretitle,
  badgeLabel,
  checkItems,
  buttonColor,
  description,
  buttonLabel,
  buttonOnClick,
  isMostPopular,
  buttonIsDisabled,
  labelMostPopular,
  alignment = POSITIONS.center,
}) {
  const { t } = useTranslation();

  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");

  useMemo(() => {
    switch (theme) {
      case COLOR.primary_light:
        setBgColor(`bg-primary-light-2 dark:bg-primary-dark-2`);
        setTextColor(`text-primary-light-11 dark:text-primary-dark-11`);
        break;
      case COLOR.gray:
        setBgColor(`bg-gray-light-2`);
        setTextColor(`text-gray-light-11 dark:text-gray-dark-11`);
        break;
      case COLOR.light:
        setTextColor(`text-gray-light-11 dark:text-gray-dark-11`);
        setBgColor(
          `bg-white border ${isMostPopular ? "border-gray-light-6 dark:-gray-dark-6" : "border-primary-light-6"}`
        );
        break;
      default:
        setTextColor(`text-gray-light-11 dark:text-gray-dark-11`);
        setBgColor(
          `bg-gray-light-2 dark:bg-gray-dark-2 border ${
            isMostPopular
              ? "border-primary-dark-9 dark:border-primary-dark-9"
              : "border-gray-light-6 dark:border-gray-dark-6"
          }`
        );
        break;
    }
  }, [theme, isMostPopular]);

  const style = {
    [COLOR.primary_light]: {
      text: "text-primary-light-11 dark:text-primary-dark-11",
      title: "text-primary-light-12 dark:text-primary-dark-12",
    },
    [COLOR.gray]: {
      text: "text-gray-light-11 dark:text-gray-dark-11",
      title: "text-gray-light-12 dark:text-gray-dark-12",
    },
    [COLOR.light]: {
      text: "text-gray-light-11 dark:text-gray-dark-11",
      title: "text-gray-light-12 dark:text-gray-dark-12",
    },
    default: {
      text: "text-gray-light-11 dark:text-gray-dark-11",
      title: "text-gray-light-12 dark:text-gray-dark-12",
    },
  };

  const wrapperCn = () => cntl`
    flex
    max-w-sm
    flex-col
    shadow-light-lg
    dark:shadow-dark-lg
    rounded-2xl
    items-center
    justify-end
    overflow-hidden
    ${bgColor}
  `;

  const container = () => cntl`
    p-6
    flex
    w-full
    h-fit
    flex-col
    justify-between
  `;

  const mostPopularCn = () => cntl`
    h-11
    flex
    w-full
    items-center
    justify-center
    bg-primary-dark-9
    dark:bg-primary-dark-9
  `;

  const textMostPopularCn = () => cntl`
    text-sm
    text-white
    font-medium
    first-letter:uppercase
  `;

  const ctaWrapperCn = () => cntl`
    flex
    w-full
    flex-col
    items-center
    justify-center
    py-6
  `;

  const checkItemsContainerCn = () => cntl`
    flex
    w-full
    gap-y-2
    flex-col
    justify-start
    min-h-[8.5rem]
    ${textColor}
  `;

  const descriptionCn = () => cntl`
    text-xs
    font-normal
    text-left
    ${style[theme]?.title || style["default"]?.title}
  `;

  const headerPricingProps = {
    icon,
    title,
    theme,
    pretitle,
    subtitle,
    alignment,
    badgeLabel,
  };

  return (
    <div className={wrapperCn()}>
      {isMostPopular === true ? (
        <div className={mostPopularCn()}>
          <div className={textMostPopularCn()}>{labelMostPopular}</div>
        </div>
      ) : null}
      <div className={container()}>
        <HeaderPricing {...headerPricingProps} />
        <div className={ctaWrapperCn()}>
          {buttonColor === COLOR.primary ? (
            <ButtonPrimary
              size={SIZE.md}
              className="w-full"
              label={buttonLabel}
              onClick={buttonOnClick}
              isDisabled={buttonIsDisabled}
            />
          ) : (
            <ButtonGrey
              size={SIZE.md}
              className="w-full"
              label={buttonLabel}
              onClick={buttonOnClick}
              isDisabled={buttonIsDisabled}
              variant={BUTTON_VARIANTS.outline}
            />
          )}
        </div>
        <div className={checkItemsContainerCn()}>
          {description?.length && (
            <div className={descriptionCn()}>
              {t(`card.${description?.toLowerCase().replaceAll(" ", "_")}`)}
            </div>
          )}
          {checkItems?.map((checkItem, index) => {
            return (
              <CheckItem
                text={checkItem}
                color={COLOR.dark}
                key={`check-item-${index}`}
                variant={CHECK_ITEM_ICON_VARIANTS.light}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

PricingTierCard.prototype = propTypes;
export default PricingTierCard;
