import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
};
function LoaderIcon({ size, className }) {
  const dispatch = useDispatch();
  const [scale, setScale] = useState("");

  useEffect(() => {
    switch (size) {
      case SIZE.sm:
        setScale("scale-50");
        break;
      case SIZE.md:
        setScale("scale-75");
        break;
      case SIZE.lg:
        setScale("scale-100");
        break;
      default:
        setScale("scale-75");
        break;
    }
  }, [dispatch, size]);

  return (
    <>
      {size === "btn" ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM2.99512 10C2.99512 13.8687 6.13131 17.0049 10 17.0049C13.8687 17.0049 17.0049 13.8687 17.0049 10C17.0049 6.13131 13.8687 2.99512 10 2.99512C6.13131 2.99512 2.99512 6.13131 2.99512 10Z"
            fill="#007EB8"
          />
          <path
            d="M18.5024 10C19.3295 10 20.0115 9.32583 19.8881 8.50801C19.7048 7.29336 19.2986 6.1185 18.6856 5.04414C17.8242 3.53449 16.5842 2.27542 15.0879 1.39109C13.5916 0.506759 11.8906 0.027704 10.1527 0.0011657C8.91589 -0.0177205 7.69079 0.193037 6.53833 0.618267C5.76239 0.904571 5.50069 1.82711 5.89956 2.55165V2.55165C6.29843 3.27619 7.20796 3.52258 8.00063 3.28651C8.6811 3.08386 9.39123 2.985 10.107 2.99593C11.3243 3.01452 12.5159 3.3501 13.564 3.96956C14.6122 4.58902 15.4808 5.47099 16.0842 6.52848C16.4389 7.1502 16.6948 7.81997 16.8454 8.51381C17.0209 9.32205 17.6754 10 18.5024 10V10Z"
            fill="white"
          />
        </svg>
      ) : (
        <div className={scale}>
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
          >
            <path
              d="M100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0 22.3858 22.3858 0 50 0C77.6142 0 100 22.3858 100 50ZM9.66448 50C9.66448 72.2767 27.7233 90.3355 50 90.3355C72.2767 90.3355 90.3355 72.2767 90.3355 50C90.3355 27.7233 72.2767 9.66448 50 9.66448C27.7233 9.66448 9.66448 27.7233 9.66448 50Z"
              fill={size === `btn` ? `#007EB8` : `#E2E2E5`}
            />
            <path
              d="M95.1678 50C97.8365 50 100.024 47.8316 99.7667 45.1752C99.2905 40.2639 98.0891 35.441 96.194 30.8658C93.6812 24.7995 89.9983 19.2876 85.3553 14.6447C80.7124 10.0017 75.2004 6.31876 69.1342 3.80602C64.559 1.91093 59.7361 0.709471 54.8248 0.233325C52.1684 -0.0242004 50 2.16347 50 4.83224V4.83224C50 7.50101 52.1711 9.63463 54.8207 9.95359C58.4618 10.3919 62.0337 11.3257 65.4357 12.7348C70.3295 14.7619 74.776 17.733 78.5215 21.4785C82.267 25.224 85.2381 29.6705 87.2652 34.5643C88.6743 37.9663 89.6081 41.5382 90.0464 45.1793C90.3654 47.8289 92.499 50 95.1678 50V50Z"
              fill={size === `btn` ? `#FFFFFF` : `#006493`}
            />
          </svg>
        </div>
      )}
    </>
  );
}

LoaderIcon.propTypes = propTypes;
export default LoaderIcon;
