import cntl from "cntl";
import PropTypes from "prop-types";
import Content from "./SectionContent";
import SectionItem from "./SectionItem";
import { POSITIONS } from "../constants";
import { IconCircleCheck } from "@tabler/icons-react";

const propTypes = {
  content: PropTypes.object,
  sectionItem: PropTypes.array,
};

function FeatureSectionAlternated({ content, sectionItem }) {
  const containerCn = () => cntl`
    px-4
    pt-16
    lg:px-8
    mx-auto
    lg:pt-24
    container
  `;

  const contentAndItemCn = () => cntl`
    flex
    pb-16
    lg:pb-24
    flex-col
    gap-y-12
    lg:gap-y-24
  `;

  const sectionCn = () => cntl`
    flex
    flex-col
    gap-y-10
    gap-x-24
    relative
    xl:h-[32rem]
    lg:flex-row
    lg:items-center
    justify-between
  `;

  const listCn = () => cntl`
    flex
    px-4
    h-full
    mx-auto
    gap-y-8
    lg:px-8
    flex-col
    lg:py-24
    container
  `;

  const wrapperListCn = () => cntl`
    flex
    flex-col
    gap-y-12
    lg:gap-y-0
    xl:gap-y-12
    2xl:gap-y-24
  `;

  const listElementCn = () => cntl`
    flex
    gap-x-3
    flex-row
  `;

  const iconCn = () => cntl`
    w-7
    h-7
    shrink-0
    text-primary-light-3 dark:text-primary-dark-3 
  `;

  const sectionAndListCn = ({ isOdd }) => cntl`
    lg:w-1/2
    ${!isOdd ? `mr-auto lg:pr-8` : `ml-auto lg:pl-8`}
  `;

  const olCn = () => cntl`
    mt-8
  `;

  const imageCn = ({ isOdd }) => cntl`
    flex
    lg:w-1/2
    lg:absolute
    justify-center
    ${!isOdd ? `lg:right-0 lg:justify-end` : `lg:left-0 lg:justify-start`}
  `;

  return (
    <div className={contentAndItemCn()}>
      <div className={containerCn()}>
        <Content {...content} position={POSITIONS.center} />
      </div>
      <div className={wrapperListCn()}>
        {sectionItem?.map((item, index) => {
          return (
            <section className={sectionCn()} key={`section-${index}`}>
              <div className={listCn()}>
                <div className={sectionAndListCn({ isOdd: index % 2 })}>
                  <SectionItem {...item} image={null} position={POSITIONS.left} key={`section-item-${index}`} />
                  <ol className={olCn()}>
                    {item?.elementList?.map((element, index) => {
                      return (
                        <li key={`list-element-${index}`} className={listElementCn()}>
                          <IconCircleCheck className={iconCn()} />
                          {element}
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
              <div className={imageCn({ isOdd: index % 2 })}>{item?.image}</div>
            </section>
          );
        })}
      </div>
    </div>
  );
}

FeatureSectionAlternated.prototype = propTypes;
export default FeatureSectionAlternated;
