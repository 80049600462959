import cntl from "cntl";
import { PropTypes } from "prop-types";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const Card = ({ children, className, ...props }) => {
  const cardCn = () => cntl`
    ${className ? className : undefined}
  `;

  return (
    <article className={cardCn()} {...props}>
      {children}
    </article>
  );
};

Card.propTypes = propTypes;
export default Card;
