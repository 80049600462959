import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import { useState, useCallback } from "react";
import { POSITIONS, SIZE } from "../../constants";
import { ButtonGrey, Dropdown, ListItem, Menu, Trigger } from "../../atoms";
import cntl from "cntl";
import { uniqueId } from "lodash";

const propTypes = {
  width: PropTypes.string,
  options: PropTypes.array,
  menuItems: PropTypes.array,
  className: PropTypes.string,
  onItemSelect: PropTypes.func,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl, SIZE["2xl"]]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

function ButtonDropdown({ menuItems, size, width, options, children, className, onItemSelect, ...props }) {
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const listItemCn = () => cntl`
    p-1
    border-b
    last:border-b-0
    whitespace-nowrap
    border-gray-light-6
    dark:border-gray-dark-6
  `;

  const offset = useCallback(
    ({ reference, popper }) => {
      if (props.buttonPosition === POSITIONS.left) {
        return [-((reference.width - popper.width) / 2), 5];
      } else {
        return [(reference.width - popper.width) / 2, 5];
      }
    },
    [props.buttonPosition]
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset,
        },
      },
    ],
    strategy: "absolute",
  });

  return (
    <Dropdown size={size} width={width} className={className} onSelect={onItemSelect}>
      <Trigger ref={setReferenceElement}>
        <ButtonGrey {...props} />
      </Trigger>
      <Menu ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {menuItems?.map((props) => {
          return (
            <div key={uniqueId("menu-button-dropdown-")} className={listItemCn()}>
              <ListItem {...props} />
            </div>
          );
        })}
      </Menu>
    </Dropdown>
  );
}

ButtonDropdown.propTypes = propTypes;
export default ButtonDropdown;
