import cntl from "cntl";
import { PropTypes } from "prop-types";

// Constants
import { SIZE } from "@dbox/components/constants";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.base, SIZE.lg, SIZE.xl, SIZE["2xl"], SIZE["4xl"]]),
};

function Typography({ className, size, children }) {
  const fourXlCn = () => cntl`
    text-4xl
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-12
    ${className ? className : undefined}
  `;

  const twoXlCn = () => cntl`
    text-2xl
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-12
    ${className ? className : undefined}
  `;

  const xlCn = () => cntl`
    text-xl
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-12
    ${className ? className : undefined}
  `;

  const lgCn = () => cntl`
    text-lg
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-12
    ${className ? className : undefined}
  `;

  const baseCn = () => cntl`
    text-base
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-12
    ${className ? className : undefined}
  `;

  const smCn = () => cntl`
    text-sm
    text-gray-light-11
    dark:text-gray-dark-11
    ${className ? className : undefined}
  `;

  const xsCn = () => cntl`
    text-xs
    text-gray-light-11
    dark:text-gray-dark-11
    ${className ? className : undefined}
  `;

  if (size === SIZE["4xl"]) {
    return <div className={fourXlCn()}>{children}</div>;
  } else if (size === SIZE["2xl"]) {
    return <div className={twoXlCn()}>{children}</div>;
  } else if (size === SIZE.xl) {
    return <div className={xlCn()}>{children}</div>;
  } else if (size === SIZE.lg) {
    return <div className={lgCn()}>{children}</div>;
  } else if (size === SIZE.base) {
    return <div className={baseCn()}>{children}</div>;
  } else if (size === SIZE.sm) {
    return <div className={smCn()}>{children}</div>;
  } else if (size === SIZE.xs) {
    return <div className={xsCn()}>{children}</div>;
  } else {
    return null;
  }
}

Typography.propTypes = propTypes;
export default Typography;
