import cntl from "cntl";
import { BadgeText } from "..";
import PropTypes from "prop-types";
import { cloneElement } from "react";
import { useNavigate } from "react-router";
import { BUTTON_VARIANTS, POSITIONS, SIZE } from "../constants";
import { BADGE_VALUES } from "@dbox/core/constants";
import { ButtonGrey, ButtonPrimary, TextLink } from "../atoms";
import { IconArrowRight } from "@tabler/icons-react";

const propTypes = {
  title: PropTypes.text,
  cta: PropTypes.object,
  isCompleted: PropTypes.bool,
  stepNumber: PropTypes.number,
  actionProps: PropTypes.object,
  supportingText: PropTypes.text,
};

function CardStep({ title, supportingText, actionProps, cta, stepNumber, isCompleted }) {
  const navigate = useNavigate();

  const cardHeaderCn = () => cntl`
    flex
    px-4
    py-5
    border
    w-full
    lg:px-6
    flex-col
    shadow-light-sm
    dark:shadow-dark-sm
    rounded-lg
    justify-between
    border-gray-light-6
    dark:border-gray-dark-6
    ${isCompleted ? cntl`bg-gray-light-2 dark:bg-gray-dark-2` : cntl`bg-white`}
  `;

  const textCn = () => cntl`
    flex
    gap-x-1
    flex-col
  `;

  const actionWrapperCn = () => cntl`
    flex
    mt-6
    gap-4
    flex-col
    sm:flex-row
    justify-between
  `;

  const titleCn = () => cntl`
    text-lg
    font-semibold
  `;

  const supportingTextCn = () => cntl`
    text-sm
    text-gray-light-11
    dark:text-gray-dark-11
  `;

  const stepTextCn = () => cntl`
    text-sm
    font-medium
    text-center
    text-primary-light-11
    dark:text-primary-dark-11
  `;

  const badgeAndTextCn = () => cntl`
    flex 
    mb-1
    flex-row 
    justify-between 
  `;

  const iconCn = () => cntl`
    w-5 
    h-5 
    text-white
  `;

  const ctaIconCn = () => cntl`
    w-5 
    h-5 
    text-primary-light-11
    dark:text-primary-dark-11
  `;

  const buttonCn = () => cntl`
    w-fit
    ml-auto
  `;

  const defaultIcon = () => {
    return cloneElement(cta.icon, { className: ctaIconCn() });
  };

  return (
    <div className={cardHeaderCn()}>
      <div className={badgeAndTextCn()}>
        {stepNumber && <div className={stepTextCn()}>Step {stepNumber}</div>}
        <BadgeText label={isCompleted ? BADGE_VALUES.completed : BADGE_VALUES.pending} />
      </div>
      <div className={textCn()}>
        <div className={titleCn()}>{title}</div>
        <div className={supportingTextCn()}>{supportingText}</div>
      </div>
      <div className={actionWrapperCn()}>
        {cta?.text?.length && cta?.link?.length && (
          <TextLink
            label={cta.text}
            icon={defaultIcon()}
            iconPosition={POSITIONS.left}
            to={cta.link}
          />
        )}
        {!isCompleted ? (
          <ButtonPrimary
            size={SIZE.md}
            className={buttonCn()}
            disabled={isCompleted}
            iconPosition={POSITIONS.right}
            icon={<IconArrowRight className={iconCn()} />}
            {...actionProps}
          />
        ) : (
          <ButtonGrey
            size={SIZE.md}
            className={buttonCn()}
            disabled={isCompleted}
            iconPosition={POSITIONS.right}
            variant={BUTTON_VARIANTS.outline}
            icon={<IconArrowRight className={iconCn()} />}
            {...actionProps}
          />
        )}
      </div>
    </div>
  );
}

CardStep.prototype = propTypes;
export default CardStep;
