function IconsPlans({ type }) {
  if (type?.toString() === "0") {
    return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.9998 19.9998L34.1437 5.85595L39.9996 0H19.9998C14.6955 0 9.6085 2.10712 5.85781 5.85781C2.10712 9.60851 0 14.6955 0 19.9998H19.9998Z"
          fill="#27FB6B"
        />
      </svg>
    );
  } else if (type?.toString() === "1") {
    return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2579_38375)">
          <path
            d="M19.9998 19.9998L34.1437 5.85595L39.9996 0H19.9998C14.6955 0 9.6085 2.10712 5.85781 5.85781C2.10712 9.60851 0 14.6955 0 19.9998H19.9998Z"
            fill="#27FB6B"
          />
          <path
            d="M39.9998 19.9998V0L34.1439 5.85595L20 19.9998V39.9996C25.3043 39.9996 30.3913 37.8925 34.142 34.1418C37.8927 30.3911 39.9998 25.3041 39.9998 19.9998Z"
            fill="#036D19"
          />
        </g>
        <defs>
          <clipPath id="clip0_2579_38375">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2579_38446)">
          <path d="M19.9998 19.9996H0V39.9995H19.9998V19.9996Z" fill="#09A129" />
          <path
            d="M19.9998 19.9998L34.1437 5.85595L39.9996 0H19.9998C14.6955 0 9.6085 2.10712 5.85781 5.85781C2.10712 9.60851 0 14.6955 0 19.9998H19.9998Z"
            fill="#27FB6B"
          />
          <path
            d="M39.9998 19.9998V0L34.1439 5.85595L20 19.9998V39.9996C25.3043 39.9996 30.3913 37.8925 34.142 34.1418C37.8927 30.3911 39.9998 25.3041 39.9998 19.9998Z"
            fill="#036D19"
          />
        </g>
        <defs>
          <clipPath id="clip0_2579_38446">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default IconsPlans;
