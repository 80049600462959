function LogoAldys() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9999 8.00006H11.9999V32H19.9999V8.00006Z" fill="#966440" />
      <path d="M19.9999 16H11.9999V23.9999H19.9999V16Z" fill="#09A129" />
      <path
        d="M19.9999 16.0001L25.6575 10.3425L27.9999 8.00006H19.9999C17.8782 8.00006 15.8434 8.84292 14.3431 10.3432C12.8428 11.8435 11.9999 13.8783 11.9999 16.0001H19.9999Z"
        fill="#27FB6B"
      />
      <path
        d="M28.0001 16.0001V8.00006L25.6577 10.3425L20.0001 16.0001V24.0001C22.1218 24.0001 24.1566 23.1572 25.6569 21.6569C27.1572 20.1566 28.0001 18.1218 28.0001 16.0001Z"
        fill="#036D19"
      />
      <path
        d="M12 8L6.3424 2.3424L4 0V8C4 10.1217 4.84285 12.1566 6.34314 13.6568C7.84343 15.1571 9.87826 16 12 16V8Z"
        fill="#036D19"
      />
      <path
        d="M12 0H4L6.3424 2.3424L12 8H20C20 5.87826 19.1571 3.84343 17.6568 2.34314C16.1566 0.842854 14.1217 0 12 0V0Z"
        fill="#27FB6B"
      />
    </svg>
  );
}

export default LogoAldys;
