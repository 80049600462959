import cntl from "cntl";
import PropTypes from "prop-types";

const propTypes = {
  text: PropTypes.string,
};

function HeaderItem({ text }) {
  const textValue = () => cntl`
    text-sm
    font-medium
    text-gray-light-11
    dark:text-gray-light-11
  `;

  const headerItemCn = () => cntl`
    pb-3
    dark:bg-gray-dark-3
    ${textValue()}
  `;

  return <div className={headerItemCn()}>{text}</div>;
}

HeaderItem.propTypes = propTypes;
export default HeaderItem;
