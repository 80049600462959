import { useMemo } from "react";
import { DOTS } from "../constants";

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  total, // Represents the total count of data available from the source.
  paginationSize, //    Represents the maximum data that is visible in a single page.
  siblingCount = 1, //  Represents the min number of page buttons to be shown on each side of the current page button. Defaults to 1.
  pageIndex, // Represents the current active page.
}) => {
  const paginationRange = useMemo(() => {
    //  Calculation of the total pages
    const totalPageCount = Math.ceil(total / paginationSize);

    //  Pages count is determined as siblingCount + firstPage + lastPage + pageIndex + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
        CASE 1:
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    //  Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    const leftSiblingIndex = Math.max(pageIndex, 1);
    const rightSiblingIndex = Math.min(
      pageIndex + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 1;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
        CASE 2: 
        No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 1 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
        CASE 3:
        No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 1 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );

      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	CASE 4: 
        Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex + 1);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [total, paginationSize, siblingCount, pageIndex]);

  //    Return the range of numbers to be displayed in our pagination component as an array.
  return paginationRange;
};
