import cntl from "cntl";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ContentNavItem from "./ContentNavItem";
import { Fragment, cloneElement } from "react";
import { NAV_ITEM_BASE_VARIANTS } from "../../../constants";

const propTypes = {
  to: PropTypes.string,
  icon: PropTypes.object,
  text: PropTypes.string,
  children: PropTypes.array,
  variant: PropTypes.oneOf([NAV_ITEM_BASE_VARIANTS.main, NAV_ITEM_BASE_VARIANTS.sub_main]),
};

function NavItemBase({ to, icon, text, variant, children }) {
  const props = {
    to,
    icon,
    text,
    variant,
    children,
  };

  if (variant === NAV_ITEM_BASE_VARIANTS.main) {
    return <MainNavItemBase {...props} />;
  } else if (variant === NAV_ITEM_BASE_VARIANTS.sub_main) {
    return <SubMainNavItemBase {...props} />;
  } else {
    return <MainNavItemBase {...props} />;
  }
}

const MainNavItemBase = ({ to, icon, text }) => {
  const mainNavItemBaseCn = () => cntl`
    py-1
    px-2
  `;

  return (
    <li>
      <WrapperNavItem to={to} icon={icon} text={text} className={mainNavItemBaseCn()}>
        <ContentNavItem />
      </WrapperNavItem>
    </li>
  );
};

const SubMainNavItemBase = ({ to, icon, text, children }) => {
  const subMainNavItemBaseCn = () => cntl`
    flex
    flex-col
  `;

  const header = () => cntl`
    py-1
    pl-6
    pr-2
  `;

  const listCn = () => cntl`
    ml-8
    pl-2
    border-l
    border-l-[#D0D5DD]
  `;

  return (
    <li className={subMainNavItemBaseCn()}>
      <WrapperNavItem to={to} icon={icon} text={text} className={header()}>
        <ContentNavItem />
      </WrapperNavItem>
      {children ? (
        <div className={listCn()}>
          {children.map((props) => {
            return (
              <Fragment key={uniqueId("sub-nav-item-opt-")}>
                <SubMainOptionNavItemBase {...props} />
              </Fragment>
            );
          })}
        </div>
      ) : null}
    </li>
  );
};

const SubMainOptionNavItemBase = ({ to, text, className }) => {
  const subMainOptNavItemBaseCn = () => cntl`
    py-1
    px-1.5
    ${className ? className : undefined}
  `;

  return (
    <li>
      <WrapperNavItem to={to} text={text} className={subMainOptNavItemBaseCn()}>
        <ContentNavItem />
      </WrapperNavItem>
    </li>
  );
};

const WrapperNavItem = ({ to, icon, text, isOnline, children, className }) => {
  const navLinkActive = () => cntl`
      transition
      bg-gray-light-5
      hover:bg-gray-light-5
      focus:bg-gray-light-5
      dark:bg-gray-dark-5
      dark:hover:bg-gray-dark-5
      dark:focus:bg-gray-dark-5
    `;

  const navLinkNoActive = () => cntl`
      transition
      bg-transparent
      hover:bg-gray-light-4
      dark:hover:bg-gray-dark-4
    `;

  const navLinkCn = ({ isActive }) => cntl`
      flex
      rounded-md
      ${isActive ? navLinkActive() : navLinkNoActive()}
      ${className ? className : undefined}
    `;

  return (
    <NavLink to={to} className={({ isActive }) => navLinkCn({ isActive })}>
      {children
        ? cloneElement(children, {
            icon,
            text,
            isOnline,
          })
        : null}
    </NavLink>
  );
};

NavItemBase.propTypes = propTypes;
export default NavItemBase;
