import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneElement } from "react";
import { Link } from "react-router-dom";
import { LINK_VARIANTS, POSITIONS, SIZE } from "../constants";
import { IconArrowRight, IconArrowLeft } from "@tabler/icons-react";

const propTypes = {
  to: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.base]),
  iconPosition: PropTypes.oneOf([POSITIONS.left, POSITIONS.right]),
  variant: PropTypes.oneOf([LINK_VARIANTS.normal, LINK_VARIANTS.medium, LINK_VARIANTS.semibold, LINK_VARIANTS.bold]),
};

function TextLink({
  to,
  size = SIZE.sm,
  icon,
  label,
  variant = LINK_VARIANTS.normal,
  className,
  iconPosition,
  onClick,
}) {
  const style = {
    base: "",
    [SIZE.xs]: {
      label: {
        fontWeight: variant,
        fontSize: "text-xs leading-5",
      },
      icon: {
        size: "w-4 h-4",
      },
    },
    [SIZE.sm]: {
      label: {
        fontWeight: variant,
        fontSize: "text-sm",
      },
      icon: {
        size: "w-4 h-4",
      },
    },
    [SIZE.base]: {
      label: {
        fontWeight: variant,
        fontSize: "text-base",
      },
      icon: {
        size: "w-4 h-4",
      },
    },
  };

  const linkCn = () => cntl`
    gap-x-1
    inline-flex
    items-center
    hover:underline
    text-primary-lightAlpha-11
    dark:text-primary-darkAlpha-11
    ${style[size]?.label?.fontSize}
    ${style[size]?.label?.fontWeight}
    ${className ? className : undefined}
  `;

  const iconCn = () => cntl`
    ${style[size]?.icon?.size}
    text-primary-lightAlpha-11
    dark:text-primary-darkAlpha-11
  `;

  const labelCn = () => cntl`
    first-letter:uppercase
  `;

  return (
    <Link to={to} className={linkCn()} onClick={onClick}>
      {iconPosition === POSITIONS.left &&
        (icon ? cloneElement(icon, { className: iconCn() }) : <IconArrowLeft className={iconCn()} />)}
      <span className={labelCn()}>{label}</span>
      {iconPosition === POSITIONS.right &&
        (icon ? cloneElement(icon, { className: iconCn() }) : <IconArrowRight className={iconCn()} />)}
    </Link>
  );
}

TextLink.prototype = propTypes;
export default TextLink;
