import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { BUTTON_VARIANTS, COLOR, SIZE } from "../constants";
import { ButtonGrey, ButtonPrimary, MemberCard } from "../atoms/";

const proptypes = {
  title: PropTypes.string,
  members: PropTypes.array,
  ctaGrey: PropTypes.object,
  preTitle: PropTypes.string,
  contentRow: PropTypes.bool,
  textCentered: PropTypes.bool,
  ctaPrimary: PropTypes.object,
  description: PropTypes.string,
  theme: PropTypes.oneOf([COLOR.light, COLOR.dark, COLOR.gray, COLOR.primary, COLOR.primary_light]),
};

function TeamSection({ textCentered, theme, members, preTitle, title, description, ctaGrey, ctaPrimary, contentRow }) {
  const [textColor, setTextColor] = useState("");
  const [titleColor, setTitleColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  useMemo(() => {
    switch (theme) {
      case COLOR.gray:
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        setBgColor("bg-gray-light-2 dark:bg-gray-dark-2");
        break;
      case COLOR.primary_light:
        setTextColor("text-primary-light-11 dark:text-primary-dark-11");
        setTitleColor("text-primary-light-12 dark:text-primary-dark-12");
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        break;
      case COLOR.light:
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setBackgroundColor("bg-white");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
      default:
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setBackgroundColor("bg-white");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
    }
  }, [theme]);

  const contentCn = () => cntl`
    flex
    px-4
    w-full
    lg:px-8
    flex-col
    justify-center
    ${contentRow && "xl:px-0"}
    ${textCentered ? "items-center" : "items-start"}
    ${!contentRow ? "max-w-3xl" : "max-w-[21.5rem]"}
  `;

  const titleCn = () => cntl`
    mt-3
    mb-5
    text-4xl
    font-medium
    ${titleColor}
  `;

  const preTitleCn = () => cntl`
    text-base
    text-primary-light-11 dark:text-primary-dark-11
  `;

  const descriptionCn = () => cntl`
    text-xl
    ${textColor}
    ${textCentered ? "text-center" : "text-left"}
  `;

  const containerWrapperCn = () => cntl`
    py-16
    w-full
    lg:py-24
    lg:px-20
    ${backgroundColor}
  `;

  const containerCn = () => cntl`
    flex
    mx-auto
    flex-col
    gap-y-12
    gap-x-24
    container
    lg:gap-y-16
    justify-center
    ${contentRow && "xl:flex-row"}
    ${contentRow && "items-center lg:items-start"}
    ${!textCentered && !contentRow ? "items-start" : "items-center"}
  `;

  const membersWrapperCn = () => cntl`
    flex
    px-4
    lg:px-8
    md:grid
    gap-x-8
    flex-col
    gap-y-10
    lg:gap-y-16
    lg:flex-none
    md:grid-cols-2
    lg:grid-cols-4
    ${contentRow && "xl:grid-cols-3"}
  `;

  const ctaWrappenCn = () => cntl`
    flex
    gap-3
    mt-10
    w-full
    flex-col
    md:flex-row-reverse
    ${textCentered ? "md:justify-center" : "md:justify-end"}
  `;

  return (
    <div className={containerWrapperCn()}>
      <div className={containerCn()}>
        <div className={contentCn()}>
          {preTitle?.length > 0 && <div className={preTitleCn()}>{preTitle}</div>}
          {title?.length > 0 && <div className={titleCn()}>{title}</div>}
          {description?.length > 0 && <div className={descriptionCn()}>{description}</div>}
          {(ctaPrimary?.text?.length > 0 || ctaGrey?.text?.length > 0) && (
            <div className={ctaWrappenCn()}>
              {ctaPrimary?.text?.length > 0 && (
                <ButtonPrimary label={ctaPrimary?.text} size={SIZE.md} onClick={ctaPrimary?.action} />
              )}
              {ctaGrey?.text?.length > 0 && (
                <ButtonGrey
                  size={SIZE.md}
                  label={ctaGrey?.text}
                  onClick={ctaGrey?.action}
                  variant={BUTTON_VARIANTS.outline}
                />
              )}
            </div>
          )}
        </div>
        <div className={membersWrapperCn()}>
          {members?.map((member, index) => {
            return (
              <MemberCard
                theme={theme}
                textCentered={textCentered}
                key={`member-card-${index}`}
                memberName={member?.memberName}
                memberRole={member?.memberRole}
                socialIcons={member?.socialIcons}
                profileImage={member?.profileImage}
                memberDescription={member?.memberDescription}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

TeamSection.prototype = proptypes;
export default TeamSection;
