import cntl from "cntl";
import { get } from "lodash";
import PropTypes from "prop-types";
import { SIZE, TEXTAREA_VARIANTS } from "../constants";

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  textHelper: PropTypes.string,
  placeholder: PropTypes.string,
  formRegistration: PropTypes.object,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md,SIZE.lg]),
  variant: PropTypes.oneOf([TEXTAREA_VARIANTS.surface]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function TextArea({
  id,
  name,
  label,
  value,
  errors,
  onChange,
  className,
  textHelper,
  isDisabled,
  placeholder,
  size = SIZE.lg,
  formRegistration = null,
  variant = TEXTAREA_VARIANTS.surface,
}) {
  const { onChange: onFormChange, onBlur: onFormBlur, name: formName, ...rest } = formRegistration || {};

  const computedId = id || formName;
  const computedName = name || formName;
  const errorMessage = get(errors, `[${formName}].message`, "");

  const wrapperCn = () => cntl`
    flex
    flex-col
    select-wrapper
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    mb-1
    text-sm
    text-gray-lightAlpha-12
    first-letter:uppercase
    dark:text-gray-darkAlpha-12
  `;

  const textAreaCn = ({ hasErrors }) => cntl`
    py-1.5
    w-full
    border
    form-input
    focus:ring-0
    focus:ring-transparent
    focus:outline-0
    focus:outline-none
    focus:outline-transparent
    ${styleBase[size]?.container?.size}
    ${styleBase[size]?.text?.fontStyle}
    ${styleBase[size]?.container?.rounded}
    ${hasErrors ? styleVariant[variant]?.style?.error : styleVariant[variant]?.style?.default}
  `;

  const textHelpCn = () => cntl`
    mb-1
    text-sm
    first-letter:uppercase
    text-gray-lightAlpha-11
    dark_text-gray-darkAlpha-11
  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1
    text-sm
    first-letter:uppercase
    text-error-lightAlpha-11
    dark:text-error-darkAlpha-11
    ${!hasErrors && cntl`invisible`}
  `;

  const surfaceVariantBase = () => cntl`
    bg-white/[.9]
    text-gray-light-12
    dark:bg-black/[.25]
    dark:text-gray-dark-12
    disabled:bg-gray-lightAlpha-3
    dark:disabled:bg-gray-darkAlpha-3
    placeholder:text-gray-lightAlpha-10
    dark:placeholder:text-gray-darkAlpha-10
  `;

  const surfaceVariantDefault = () => cntl`
    border-gray-light-6
    dark:border-gray-dark-6
    focus:border-primary-lightAlpha-8
    dark:focus:border-primary-darkAlpha-8
    ${surfaceVariantBase()}
  `;

  const surfaceVariantError = () => cntl`
    border-error-lightAlpha-8
    dark:border-error-darkAlpha-8
    focus:border-error-lightAlpha-8
    dark:focus:border-error-darkAlpha-8
  `;

  const styleBase = {
    [SIZE.sm]: {
      container: {
        size: "min-h-6 h-6",
        rounded: "rounded-0.75",
      },
      text: {
        fontStyle: "text-xs leading-5",
      },
    },
    [SIZE.md]: {
      container: {
        size: "min-h-8 h-8",
        rounded: "rounded",
      },
      text: {
        fontStyle: "text-sm",
      },
    },
    [SIZE.lg]: {
      container: {
        size: "min-h-10 h-10",
        rounded: "rounded-md",
      },
      text: {
        fontStyle: "text-base",
      },
    },
  };

  const styleVariant = {
    [TEXTAREA_VARIANTS.surface]: {
      style: {
        error: surfaceVariantError(),
        default: surfaceVariantDefault(),
      },
    },
  };

  return (
    <div className={wrapperCn()}>
      {label?.length > 0 ? <label className={labelCn()}>{label}</label> : null}
      {textHelper?.length > 0 ? (
        <div className={textHelpCn()} data-cypress="form-help-msg">
          {textHelper}
        </div>
      ) : null}
      <textarea
        value={value}
        id={computedId}
        name={computedName}
        disabled={isDisabled}
        placeholder={placeholder}
        aria-invalid={errorMessage ? "true" : "false"}
        className={textAreaCn({ hasErrors: !!errorMessage })}
        onChange={(e) => {
          if (onFormChange) {
            onFormChange(e);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        {...rest}
      />
      {errorMessage?.length > 0 ? (
        <div
          className={errorCn({
            hasErrors: !!errorMessage,
          })}
          data-cypress="form-error-msg"
        >
          {errorMessage ? errorMessage : "Error message"}
        </div>
      ) : null}
    </div>
  );
}

TextArea.propTypes = propTypes;
export default TextArea;
