class PollingService {
  constructor() {
    this.intervalId = null;
  }

  startPolling(timeout, callback) {
    if (!this.intervalId) {
      this.intervalId = setInterval(callback, timeout);
    }
  }

  stopPolling() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}

export default new PollingService();
