import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE, WIDTH } from "../../constants";
import { useRef, Children, Fragment, useState, useEffect, useCallback, cloneElement } from "react";

const propTypes = {
  isOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  inputValue: PropTypes.string,
  handleChangeInput: PropTypes.bool,
  onInputValueChange: PropTypes.func,
  width: PropTypes.oneOf([WIDTH.xs, WIDTH.sm, WIDTH.md, WIDTH.lg, WIDTH.xl]),
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.md, SIZE.lg, SIZE.xl, SIZE["2xl"]]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function Dropdown({
  size,
  width,
  children,
  onSelect,
  className,
  isDisabled,
  inputValue,
  isOpen = null,
  onInputValueChange,
  handleChangeInput = true,
}) {
  const dropdownRef = useRef();
  const [isDropdpwnOpen, setIsDropdownOpen] = useState(isOpen ?? false);

  const dropdownCn = () => cntl`
    relative
    ${className ? className : undefined}
  `;

  const openDropdown = (state) => {
    setIsDropdownOpen(state);
  };

  const onSelectItem = (item) => {
    onSelect(item);
    setIsDropdownOpen(false);
  };

  const onInputChange = (e) => {
    if (handleChangeInput && e.target.value) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
    onInputValueChange(e.target.value);
  };

  const handleClickOutside = useCallback((e) => {
    if (!dropdownRef?.current?.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return (
    <div ref={dropdownRef} className={dropdownCn()}>
      {children &&
        Children.map(children, (child, index) => {
          return (
            <Fragment key={`dropdown-child-${index}`}>
              {cloneElement(child, {
                size,
                width,
                isDisabled,
                value: inputValue,
                onChange: onInputChange,
                onSelect: onSelectItem,
                isVisible: isDropdpwnOpen,
                onClick: () => openDropdown(!isDropdpwnOpen),
              })}
            </Fragment>
          );
        })}
    </div>
  );
}

Dropdown.propTypes = propTypes;
export default Dropdown;
