import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function Footer({ children, ...props }) {
  return <div {...props}>{children}</div>;
}

Footer.propTypes = propTypes;
export default Footer;
