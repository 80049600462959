import cntl from "cntl";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";

// Components
import { PaginationNumberBase } from "../../atoms";

// Constants
import { DOTS } from "../../constants";

const propTypes = {
  range: PropTypes.array,
  onPageClick: PropTypes.func,
  pagesInfo: PropTypes.exact({
    lastPage: PropTypes.number,
    currentPage: PropTypes.number,
  }),
};

function isActiveOrDisabled(range, index, pagesInfo) {
  return range?.findIndex((number) => number === pagesInfo?.currentPage + 1) === index;
}

const PaginationNumbers = ({ range, pagesInfo, onPageClick }) => {
  const paginationNumbersCn = () => cntl`
    flex
    gap-x-0.5
  `;

  return (
    <div className={paginationNumbersCn()}>
      {range?.map((value, index) => (
        <PaginationNumberBase
          value={value}
          onClick={onPageClick}
          key={uniqueId("pagination-number-base-")}
          isActive={isActiveOrDisabled(range, index, pagesInfo)}
          isDisabled={value === DOTS ? true : isActiveOrDisabled(range, index, pagesInfo)}
        />
      ))}
    </div>
  );
};

PaginationNumbers.propTypes = propTypes;
export default PaginationNumbers;
