import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../../constants";
import { cloneElement } from "react";

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

function Badge({ label, size = SIZE.sm, className, children }) {
  const badgeCn = () => cntl`
    flex
    w-fit
    gap-x-1
    rounded
    box-border
    items-center
    justify-center
    ${style[size]?.container?.height}
    ${style[size]?.container?.padding}
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    font-medium
    first-letter:uppercase
    ${style[size]?.label?.fontSize}
  `;

  const style = {
    [SIZE.sm]: {
      container: {
        height: "h-5",
        padding: "px-2",
      },
      label: {
        fontSize: "text-xs",
      },
      children: {
        size: "w-3.5 h-3.5",
      },
    },
    [SIZE.md]: {
      container: {
        height: "h-6",
        padding: "px-2.5",
      },
      label: {
        fontSize: "text-sm",
      },
      children: {
        size: "w-3.5 h-3.5",
      },
    },
  };

  return (
    <span className={badgeCn()}>
      {children
        ? cloneElement(children, { className: `${children.props.className} ${style[size]?.children?.size}` })
        : null}
      {label ? <p className={labelCn()}>{label}</p> : null}
    </span>
  );
}

Badge.propTypes = propTypes;
export default Badge;
