function SpinnerXs({ className }) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.5 9C15.5 9.91925 15.3189 10.8295 14.9672 11.6788C14.6154 12.5281 14.0998 13.2997 13.4497 13.9497C12.7997 14.5998 12.0281 15.1154 11.1788 15.4672C10.3295 15.8189 9.41925 16 8.5 16C7.58075 16 6.67049 15.8189 5.82121 15.4672C4.97194 15.1154 4.20026 14.5998 3.55025 13.9497C2.90024 13.2997 2.38463 12.5281 2.03284 11.6788C1.68106 10.8295 1.5 9.91925 1.5 9C1.5 8.08074 1.68106 7.17049 2.03284 6.32121C2.38463 5.47193 2.90024 4.70026 3.55025 4.05025C4.20027 3.40024 4.97194 2.88462 5.82122 2.53284C6.6705 2.18106 7.58075 2 8.5 2C9.41926 2 10.3295 2.18106 11.1788 2.53284C12.0281 2.88463 12.7997 3.40024 13.4498 4.05026C14.0998 4.70027 14.6154 5.47194 14.9672 6.32122C15.3189 7.1705 15.5 8.08075 15.5 9L15.5 9Z"
        stroke="#15B7B7"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.55025 4.05025C4.33138 3.26913 5.28628 2.68397 6.33688 2.3426C7.38749 2.00124 8.50397 1.91337 9.59504 2.08618C10.6861 2.25899 11.7208 2.68757 12.6145 3.33688C13.5082 3.98619 14.2355 4.8378 14.737 5.82207C15.2386 6.80634 15.5 7.89533 15.5 9C15.5 10.1047 15.2386 11.1937 14.737 12.1779C14.2355 13.1622 13.5082 14.0138 12.6145 14.6631C11.7208 15.3124 10.6861 15.741 9.59504 15.9138"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SpinnerXs;
