import cntl from "cntl";
import PropTypes from "prop-types";
import { IconCheck } from "@tabler/icons-react";

const propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  isCurrent: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

function ListItem({ text, isCurrent, value, onSelect, disabled, children, className }) {
  const textValue = () => cntl`
    text-sm
    font-medium
    whitespace-nowrap
    ${isCurrent ? `text-gray-light-12 dark:text-gray-dark-12` : `text-gray-light-11 dark:text-gray-dark-11`}
    ${disabled ? `text-gray-light-7 dark:text-gray-dark-7` : `text-gray-light-11 dark:text-gray-dark-11`}
  `;

  const currentItem = () => cntl`
    ${isCurrent ? `bg-gray-light-4 dark:bg-gray-dark-4 pointer-events-none` : `bg-tranparent`}
  `;

  const listItemCn = () => cntl`
    h-8
    px-2
    flex
    gap-x-4
    rounded-md
    items-center
    justify-between
    ${currentItem()}
    ${!disabled ? `cursor-pointer hover:bg-gray-light-4 dark:hover:bg-gray-dark-4` : `pointer-events-none cursor-auto`}
    ${className ? className : undefined}
  `;

  const childrenAndTextCn = () => cntl`
    flex
    gap-x-2
    items-center
  `;

  const iconWrapper = () => cntl`
    w-5
    h-5
  `;

  const iconCn = () => cntl`
    text-primary-light-9
    dark:text-primary-dark-9
  `;

  const childrenCn = () => cntl`
    w-4
    h-4
    flex
    shrink-0
    items-center
    ${disabled ? `text-gray-light-7 dark:text-gray-dark-7` : undefined}
  `;

  const handleListItemClick = () => {
    if (value && onSelect) {
      onSelect(value);
    }
  };

  return (
    <div className={listItemCn()} onClick={handleListItemClick}>
      <div className={childrenAndTextCn()}>
        {children ? <div className={childrenCn()}>{children}</div> : null}
        <div className={textValue()}>{text}</div>
      </div>
      {isCurrent ? (
        <div className={iconWrapper()}>
          <IconCheck size="1.25rem" className={iconCn()} />
        </div>
      ) : null}
    </div>
  );
}

ListItem.propTypes = propTypes;
export default ListItem;
