import { get } from "lodash";
import jwtDecode from "jwt-decode";
import { localStorage } from "../common/storage";
import { ACCESS_TOKEN_STORAGE_KEY } from "../../httpClient";
import pollingServiceInstance from "../common/PollingService";
import { logOut, refresh, signIn } from "../../api/aldys/auth";

export async function authenticateUser(email, two_factors_auth_token, device_info) {
  try {
    const { data } = await signIn(email, two_factors_auth_token, device_info);
    const accessToken = get(data, "access_token", "");
    await localStorage.setItem(
      ACCESS_TOKEN_STORAGE_KEY,
      JSON.stringify(accessToken)
    );

    return data;
  } catch (err) {
    throw err;
  }
}

export async function refreshToken(accessToken) {
  try {
    if (!accessToken) {
      throw new Error("Access token is missing");
    }
    const decodedToken = jwtDecode(accessToken);

    if (Date.now() > decodedToken.exp * 1000) {
      throw new Error("Access token is expired");
    }

    const res = await refresh();
    const token = get(res, "data.access_token", null);
    await localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, JSON.stringify(token));
    return token;
  } catch (err) {
    await localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    throw err;
  }
}

export async function logout() {
  try {
    await localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    await logOut();
  } catch (err) {
    throw err;
  } finally {
    pollingServiceInstance.stopPolling();
  }
}
