import cntl from "cntl";
import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import { forwardRef, useState } from "react";
import { Dropdown, Menu, Trigger } from "../../atoms";
import { IconChevronDown } from "@tabler/icons-react";

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

function SelectDropdown({ placeholder, value, label, children }) {
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const menuCn = () => cntl`
    p-3
    w-full
    max-h-80
    overflow-y-auto
  `;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
    strategy: "absolute",
  });

  const selectProps = {
    label,
    value,
    placeholder,
  };

  return (
    <Dropdown>
      <Trigger ref={setReferenceElement}>
        <Select {...selectProps} />
      </Trigger>
      <Menu className={menuCn()} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {children}
      </Menu>
    </Dropdown>
  );
}

const Select = forwardRef(function Select({ value, label, onClick, placeholder }, ref) {
  const selectCn = () => cntl`
    flex
    flex-col
  `;

  const labelCn = () => cntl`
    mb-1
    text-sm
    text-gray-lightAlpha-12
    first-letter:uppercase
    dark:text-gray-darkAlpha-12
  `;

  const dropdownHeaderCn = () => cntl`
    h-10
    px-3
    flex
    bg-gray-light-1
    dark:bg-gray-dark-1
    border
    w-full
    items-center
    border-gray-light-6
    dark:border-gray-dark-6
    disabled:bg-gray-light-4
    dark:disabled:bg-gray-dark-4
    rounded-lg
    disabled:text-gray-light-10
    dark:disabled:text-gray-dark-10
    focus:outline-2
    focus:outline-offset-2
    text-gray-light-12
    dark:text-gray-dark-12
    focus:outline-primary-light-8
    focus:border-primary-light-8
    dark:focus:outline-primary-dark-8
    dark:focus:border-primary-dark-8
  `;

  const currentValueCn = ({ isPlaceholder }) => cntl`
    text-base
    ${
      isPlaceholder
        ? `
            text-gray-light-11
            dark:text-gray-dark-11
        `
        : `
            font-regular
            text-gray-light-12
            dark:text-gray-dark-12
        `
    }
  `;

  const iconCn = () => cntl`
    w-5
    h-5
    ml-auto
    text-gray-light-11
    dark:text-gray-dark-11
  `;

  return (
    <div className={selectCn()}>
      {label?.length > 0 ? <label className={labelCn()}>{label}</label> : null}
      <div tabIndex="0" ref={ref} className={dropdownHeaderCn()} onClick={onClick}>
        <div className={currentValueCn({ isPlaceholder: !value })}>{value ? value : placeholder}</div>
        <IconChevronDown className={iconCn()} />
      </div>
    </div>
  );
});

SelectDropdown.propTypes = propTypes;
export default SelectDropdown;
