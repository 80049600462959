import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useState, useMemo } from "react";

const propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onPaste: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function MegaInput({
  id,
  size,
  name,
  value,
  onBlur,
  onKeyUp,
  onPaste,
  onClick,
  onFocus,
  inputRef,
  onChange,
  onKeyDown,
  className,
  isDisabled,
  placeholder,
}) {
  const [fontStyle, setFontStyle] = useState("");
  const [dimensions, setDimensions] = useState("");

  const inputCn = () => cntl`
    mega-input
    border
    p-0
    form-input
    shadow-light-xs
    dark:shadow-dark-xs
    rounded-lg
    text-center
    border-gray-light-6
    dark:border-gray-dark-6
    enable:bg-gray-light-1
    disabled:bg-gray-light-1
    dark:bg-gray-dark-1
    enabled:text-primary-light-9
    dark:enabled:text-primary-dark-9
    disabled:text-gray-light-7
    dark:disabled:text-gray-dark-7
    focus:placeholder:text-transparent
    focus:shadow-none
    focus:border-transparent
    focus:outline-2
    focus:outline-offset-0
    focus:outline-primary-light-8
    dark:focus:outline-primary-dark-8
    focus:enabled:text-primary-light-9
    dark:focus:enabled:text-primary-dark-9
    placeholder:text-gray-light-8
    dark:placeholder:text-gray-dark-8
    selection:bg-transparent
    ${fontStyle}
    ${dimensions}
    ${className ? className : undefined}
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setFontStyle("text-4xl sm:text-5xl");
        setDimensions("w-full sm:w-16 h-16");
        break;
      case SIZE.md:
        setFontStyle("text-4xl sm:text-5xl");
        setDimensions("w-full sm:w-20 h-20");
        break;
      case SIZE.lg:
        setFontStyle("text-5xl sm:text-6xl");
        setDimensions("w-full sm:w-24 h-24");
        break;
      default:
        setFontStyle("text-4xl sm:text-5xl");
        setDimensions("w-full sm:w-16 h-16");
        break;
    }
  }, [size]);

  return (
    <input
      id={id}
      name={name}
      value={value}
      ref={inputRef}
      type="text"
      maxLength={1}
      onPaste={onPaste}
      onKeyUp={onKeyUp}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      disabled={isDisabled}
      className={inputCn()}
      placeholder={placeholder}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
      }}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
      }}
    />
  );
}

MegaInput.propTypes = propTypes;
export default MegaInput;
