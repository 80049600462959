import cntl from "cntl";
import PropTypes from "prop-types";
import TabButton from "./TabButton";
import { SIZE } from "../constants/index";

const propTypes = {
  className: PropTypes.string,
  ariaSelected: PropTypes.string,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

function TabButtonUnderline({ children, className, ariaSelected, size = SIZE.xs, ...props }) {
  const tabButtonSelected = () => cntl`
    border-b-2
    border-b-primary-light-10
    dark:border-b-primary-dark-10
  `;

  const tabButtonNotSelected = () => cntl`
    border-b-2
    border-b-transparent
    ${
      !props?.isDisabled
        ? `hover:border-b-primary-light-10 dark:hover:border-b-primary-dark-10`
        : `border-b-0`
    }
  `;

  const tabButtonUnderlineCn = () => cntl`
    flex
    transition
    items-center
    bg-transparent
    ${style[size]?.height}
    ${style[size]?.padding}
    ${ariaSelected === "true" ? tabButtonSelected() : tabButtonNotSelected()}
    ${className ? className : undefined}
  `;

  const style = {
    [SIZE.xs]: {
      height: "h-8",
      padding: "px-1",
    },
    [SIZE.sm]: {
      height: "h-10",
      padding: "px-2",
    },
  };

  return (
    <TabButton size={size} className={tabButtonUnderlineCn()} {...props}>
      {children}
    </TabButton>
  );
}

TabButtonUnderline.propTypes = propTypes;
export default TabButtonUnderline;
