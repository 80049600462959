import cntl from "cntl";
import PropTypes from "prop-types";
import Content from "./SectionContent";
import SectionItem from "./SectionItem";
import { useMemo, useState } from "react";
import { COLOR, POSITIONS } from "../constants";

const propTypes = {
  image: PropTypes.object,
  content: PropTypes.object,
  sectionItems: PropTypes.array,
  position: PropTypes.oneOf([POSITIONS.center, POSITIONS.left]),
  theme: PropTypes.oneOf([COLOR.dark, COLOR.gray, COLOR.light, COLOR.primary, COLOR.primary_light]),
};

function FeatureSectionImageCentered({ image, theme, content, position, sectionItems }) {
  const [bgColor, setBgColor] = useState("");

  const wrapperCn = () => cntl`
    ${bgColor}
  `;

  const containerCn = () => cntl`
    px-4
    py-16
    md:px-8
    mx-auto
    md:py-24
    container
  `;

  const contentAndGridCn = () => cntl`
    flex
    flex-col
    gap-y-12
    md:gap-y-16
  `;

  const imageCn = () => cntl`
    flex
    w-full
    items-center
    max-h-[45rem]
    justify-center
  `;

  const gridCn = () => cntl`
    grid
    gap-x-8
    gap-y-10
    grid-cols-1
    md:grid-cols-3
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-gray-light-10 dark:bg-gray-dark-10");
        break;
      case COLOR.gray:
        setBgColor("bg-gray-light-2 dark:bg-gray-dark-2");
        break;
      case COLOR.primary:
        setBgColor("bg-primary-light-10 dark:bg-primary-dark-10");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        break;
      case COLOR.light:
        setBgColor("bg-white dark:bg-black");
        break;
      default:
        setBgColor("bg-white dark:bg-black");
        break;
    }
  }, [theme]);

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <div className={contentAndGridCn()}>
          <Content {...content} theme={theme} position={position} />
          {image && <div className={imageCn()}>{image}</div>}
          <div className={gridCn()}>
            {sectionItems?.map((item, index) => {
              return <SectionItem {...item} theme={theme} position={position} key={`section-item-${index}`} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

FeatureSectionImageCentered.prototype = propTypes;
export default FeatureSectionImageCentered;
