import cntl from "cntl";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// Components
import { ButtonGrey } from "../../atoms";
import { PaginationNumber } from "../../molecules";
import Typography from "components/atoms/typography/Typography";

// Constants
import { BUTTON_VARIANTS, POSITIONS, SIZE } from "../../constants";

// Icons
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";

const propTypes = {
  isMobile: PropTypes.bool,
  nextPage: PropTypes.func,
  goToPage: PropTypes.func,
  previousPage: PropTypes.func,
  pagesInfo: PropTypes.exact({
    lastPage: PropTypes.number,
    currentPage: PropTypes.number,
  }),
  paginationProps: PropTypes.exact({
    canNextPage: PropTypes.bool,
    canPreviousPage: PropTypes.bool,
    paginationRange: PropTypes.array,
  }),
};

function PaginationCenter({ isMobile, nextPage, goToPage, pagesInfo, previousPage, paginationProps }) {
  const { t } = useTranslation();

  const PaginationCenterCn = () => cntl`
    flex
    flex-row
    items-center
    justify-between
  `;

  const buttonBaseProps = {
    size: SIZE.sm,
    variant: BUTTON_VARIANTS.outline,
  };

  const buttonLeftProps = {
    onClick: previousPage,
    icon: <IconArrowLeft />,
    iconPosition: POSITIONS.left,
    disabled: !paginationProps?.canPreviousPage,
    label: !isMobile ? t("button.label.previous") : null,
    ...buttonBaseProps,
  };

  const buttonRightProps = {
    onClick: nextPage,
    icon: <IconArrowRight />,
    iconPosition: POSITIONS.right,
    disabled: !paginationProps?.canNextPage,
    label: !isMobile ? t("button.label.next") : null,
    ...buttonBaseProps,
  };

  const paginationNumberProps = {
    pagesInfo,
    range: paginationProps?.paginationRange,
    onPageClick: (value) => goToPage(value - 1),
  };

  const detailsProps = {
    details: t("pagination.details", { currentPage: pagesInfo?.currentPage + 1, totalPages: pagesInfo?.lastPage }),
  };

  return (
    <div className={PaginationCenterCn()}>
      <ButtonGrey {...buttonLeftProps} />
      {!isMobile ? <PaginationNumber {...paginationNumberProps} /> : <Details {...detailsProps} />}
      <ButtonGrey {...buttonRightProps} />
    </div>
  );
}

const Details = ({ details }) => {
  const typographyProps = {
    size: SIZE.sm,
  };

  return <Typography {...typographyProps}>{details}</Typography>;
};

PaginationCenter.propTypes = propTypes;
export default PaginationCenter;
