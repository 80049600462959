import cntl from "cntl";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import { cloneElement, Children, Fragment } from "react";

const propTypes = {
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const SegmentedControl = ({ selectedItem, children }) => {
  const segmentedControlCn = () => cntl`
    m-0
    p-0.5
    h-8
    rounded
    inline-flex
    bg-gray-lightAlpha-3
    dark:bg-gray-darkAlpha-3
  `;

  return (
    <ul className={segmentedControlCn()}>
      {children &&
        Children.map(children, (child) => {
          return (
            <Fragment key={uniqueId("child-")}>
              {cloneElement(child, { isSelected: child.props.value === selectedItem })}
            </Fragment>
          );
        })}
    </ul>
  );
};

SegmentedControl.propTypes = propTypes;
export default SegmentedControl;
