import axios from "axios";
import get from "lodash/get";

export const ACCESS_TOKEN_STORAGE_KEY = "access_token";

let httpClient;

const createHttpClient = ({ getToken, baseURL, logOut, storage }) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use((config) => {
    const accessToken = getToken();
    if (accessToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      if (get(err, "response.status", "") === 401) {
        const token = await storage.getItem(ACCESS_TOKEN_STORAGE_KEY);
        if (token) {
          logOut();
        }
      }
      throw err;
    }
  );

  httpClient = instance;

  return instance;
};

export { createHttpClient, httpClient };
