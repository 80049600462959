import cntl from "cntl";
import { PropTypes } from "prop-types";

import { LazyLoadImage } from "react-lazy-load-image-component";

const propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  imagePathTop: PropTypes.string,
  imagePathBottom: PropTypes.string,
};

const UnderConstructions = ({ imagePathTop, title, text, imagePathBottom }) => {
  const underConstructionCn = () => cntl`
    flex
    gap-y-6
    flex-col  
  `;

  const titleAndDescriptionCn = () => cntl`
    flex
    gap-y-1
    flex-col
  `;

  const titleCn = () => cntl`
    text-base
    text-center
    font-semibold
    text-gray-lightAlpha-12
    dark:text-gray-darkAlpha-12
  `;

  const descriptionCn = () => cntl`
    text-sm
    text-center
    text-gray-lightAlpha-11
    dark:text-gray-darkAlpha-11
  `;

  const imageTopCn = () => cntl`
    px-4
    w-full
    lg:px-0
    mx-auto
    max-w-16
    max-h-16
  `;

  const imageBottomCn = () => cntl`
    px-4
    mt-16
    w-full
    lg:px-0
    mx-auto
    max-w-3xl
  `;

  const lazyLoadTopProps = {
    src: imagePathTop,
    className: imageTopCn(),
    alt: "image-top-section",
  };

  const lazyLoadBottomProps = {
    src: imagePathBottom,
    className: imageBottomCn(),
    alt: "image-bottom-section",
  };

  return (
    <div className={underConstructionCn()}>
      {imagePathTop ? <LazyLoadImage {...lazyLoadTopProps} /> : null}
      <div className={titleAndDescriptionCn()}>
        <div className={titleCn()}>{title}</div>
        {text ? <div className={descriptionCn()}>{text}</div> : null}
        {imagePathBottom ? <LazyLoadImage {...lazyLoadBottomProps} /> : null}
      </div>
    </div>
  );
};

UnderConstructions.propTypes = propTypes;
export default UnderConstructions;
