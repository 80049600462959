import { IconClock, IconMapPin } from "@tabler/icons-react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { IconWithTitle } from "..";
import { COLOR } from "../constants";

const propTypes = {
  JobDetailsBox: PropTypes.array,
  title: PropTypes.array,
  text: PropTypes.array,
  image: PropTypes.object,
  lateralTitle: PropTypes.bool,
  hasGrid: PropTypes.bool,
  theme: PropTypes.oneOf([COLOR.light, COLOR.dark, COLOR.gray, COLOR.primary, COLOR.primary_light]),
};

function SectionCareer({ JobDetailsBox, theme, text, title, image, lateralTitle, hasGrid }) {
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [titleColor, setTitleColor] = useState("");

  useMemo(() => {
    switch (theme) {
      case COLOR.gray:
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        setTextColor("text-primary-light-5");
        setTitleColor("text-primary-light-12");
        break;
      case COLOR.primary:
        setBgColor("bg-primary-light-10 dark:bg-primary-dark-10");
        setTextColor("text-primary-light-4");
        setTitleColor("text-white");
        break;
      case COLOR.dark:
        setBgColor("text-gray-light-12 dark:text-gray-dark-12");
        setTextColor("text-gray-light-3 dark:text-gray-dark-3");
        setTitleColor("text-white dark:text-black");
        break;
      case COLOR.light:
        setBgColor("bg-white dark:bg-black");
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
      default:
        setBgColor("bg-white dark:bg-black");
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
    }
  }, [theme]);

  const wrapperCn = () => cntl`
    px-4
    py-16
    lg:px-20
    lg:py-24
    w-full
    ${bgColor}
    ${textColor}
  `;
  const containerCn = () => cntl`
    w-full
    container
    lg:mx-auto
    flex
    ${lateralTitle ? "flex-row items-start" : "flex-col items-center"}
    gap-y-12
    lg:gap-y-16
    justify-center
  `;
  const badgeCn = () => cntl`
    flex
    flex-col
    lg:flex-row
    items-center
    gap-2
  `;

  const textIconsCn = () => cntl`
    flex
    flex-row
    items-center
    gap-x-6
    mt-5
  `;

  const contentCn = () => cntl`
    flex
    flex-col
    justify-center
    items-center
    gap-y-4
    lg:gap-y-5
    lg:px-8
    w-full
  `;
  const titleCn = () => cntl`
    text-center
    font-medium
    text-3xl
    lg:text-4xl
    lg:px-8
    ${titleColor}
  `;
  const textCn = () => cntl`
    font-normal
    text-base
  `;
  const subTitletCn = () => cntl`
    text-center
    font-normal
    text-base
  `;
  const jobsCn = () => cntl`
    ${hasGrid ? "grid grid-cols-2 gap-x-16" : "flex flex-col"}
    justify-center
    justify-items-stretch	
    items-start
    gap-y-8
    w-full
    max-w-3xl
  `;
  const jobsWrapperCn = () => cntl`
    flex 
    flex-row 
    gap-x-16 
    w-full 
    justify-center
  `;
  const jobCn = () => cntl`
    flex
    flex-col
    gap-y-1
    justify-center
    items-start
    border-t
    pt-6
    w-full
  `;
  const roleCn = () => cntl`
    text-center
    text-lg
    font-medium
    ${titleColor}
  `;

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <div className={contentCn()}>
          <div className={titleCn()}>{title}</div>
          <div className={subTitletCn()}>{text}</div>
        </div>
        <div className={jobsWrapperCn()}>
          <div className={jobsCn()}>
            {JobDetailsBox.map((job, index) => {
              return (
                <div className={jobCn()} key={`job-${index}`}>
                  <div className={badgeCn()}>
                    <div className={roleCn()}>{job.role}</div>
                    {/* <BadgeIcon icon={job.nation} label={job.tag} /> */}
                  </div>
                  <div className={textCn()}>{job.description}</div>
                  <div className={textIconsCn()}>
                    <IconWithTitle icon={<IconMapPin />} text={job.location} />
                    <IconWithTitle icon={<IconClock />} text={job.time} />
                  </div>
                </div>
              );
            })}
          </div>
          {image && (
            <div>
              <img alt="" src={image} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

SectionCareer.prototype = propTypes;
export default SectionCareer;
