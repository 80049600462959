import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneElement } from "react";
import { IconTriangleFilled } from "@tabler/icons-react";
import { NAV_ITEM_BASE_VARIANTS } from "../../constants";
import { ContentNavItem, NavItemBase } from "../../atoms";

const propTypes = {
  items: PropTypes.object,
  toggleIndex: PropTypes.array,
  handleToggle: PropTypes.func,
};

function NavItemExpandible({ items, handleToggle, toggleIndex }) {
  const liCn = () => cntl`
    list-none
  `;

  const navItemBaseCn = ({ currentIsOpen }) => cntl`
    overflow-hidden
    transition-[max-height]
    ${currentIsOpen ? cntl`max-h-[21rem]` : cntl`max-h-0`}
  `;

  return (
    <li className={liCn()}>
      {items?.text ? (
        <SmallNavItemExpandible
          icon={items?.icon}
          text={items?.text}
          isOpen={toggleIndex?.includes(-1)}
          onClick={(index) => handleToggle({ index })}
        />
      ) : null}
      <div>
        {items?.children?.map((props, index) => {
          if (props?.children) {
            return (
              <div key={index} className={navItemBaseCn({ currentIsOpen: toggleIndex?.includes(-1) })}>
                <MainNavItemExpandible
                  isOpen={toggleIndex?.includes(index)}
                  onClick={() => handleToggle({ index })}
                  {...props}
                />
                <ul className={navItemBaseCn({ currentIsOpen: toggleIndex?.includes(index) })}>
                  {props?.children?.map((props, index) => {
                    return <NavItemBase key={index} variant={NAV_ITEM_BASE_VARIANTS.sub_main} {...props} />;
                  })}
                </ul>
              </div>
            );
          } else {
            return <MainNavItemExpandible {...props} />;
          }
        })}
      </div>
    </li>
  );
}

const MainNavItemExpandible = ({
  text,
  icon,
  isOpen,
  onClick,
  className,
  positionTriangleIcon,
  isVisibleTriangleIcon,
}) => {
  const mainNavItemExpandibleCn = () => cntl`
    flex
    flex-col
    ${className ? className : undefined}
  `;

  const contentNavItemCn = () => cntl`
    py-1
    px-2
    transition
    rounded-md
    cursor-pointer
    hover:bg-gray-light-4
    dark:hover:bg-gray-dark-4
  `;

  return (
    <div className={mainNavItemExpandibleCn()} onClick={onClick}>
      <ContentNavItem
        text={text}
        icon={icon}
        isOpen={isOpen}
        className={contentNavItemCn()}
        positionTriangleIcon={positionTriangleIcon}
        isVisibleTriangleIcon={isVisibleTriangleIcon}
      />
    </div>
  );
};

const SmallNavItemExpandible = ({ text, isOpen, onClick }) => {
  const smallNavItemExpandibleCn = () => cntl`
    flex
    flex-col
  `;

  const headerCn = () => cntl`
    py-1
    px-2
    flex
    gap-x-1.5
    transition
    rounded-md
    items-center
    cursor-pointer
    hover:bg-gray-light-4
    dark:hover:bg-gray-dark-4
  `;

  const textCn = () => cntl`
    text-xs
    transition
    font-medium
    text-gray-light-10
    dark:text-gray-dark-10
    hover:text-gray-light-11
    dark:hover:text-gray-dark-11
  `;

  const iconCn = () => cntl`
    w-2
    h-2
    text-gray-light-10
    dark:text-gray-dark-10
    transition-transform
    ${isOpen ? cntl`rotate-180` : cntl`rotate-90`}
  `;

  return (
    <div className={smallNavItemExpandibleCn()} onClick={() => onClick(-1)}>
      <div className={headerCn()}>
        {text ? <div className={textCn()}>{text}</div> : null}
        <IconTriangleFilled className={iconCn()} />
      </div>
    </div>
  );
};

NavItemExpandible.propTypes = propTypes;
export default NavItemExpandible;
