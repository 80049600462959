import { combineReducers } from "@reduxjs/toolkit";
import authSlice, * as fromAuth from "./auth.reducer";
import uiSlice, * as fromUi from "@dbox/core/reducers/ui.reducer";

const appReducer = combineReducers({
  auth: authSlice.reducer,
  ui: uiSlice.reducer,
});

export const getIsLoggedIn = (state) => fromAuth.getIsLoggedIn(state.auth);
export const getAccessToken = (state) => fromAuth.getAccessToken(state.auth);
export const getIsRegistrationCompleted = (state) =>
  fromAuth.getIsRegistrationCompleted(state.auth);
export const getUser = (state) => fromAuth.getUser(state.auth);

export const getSnackbarState = (state) => fromUi.getSnackbarState(state.ui);
export const getBottomToolbarState = (state) =>
  fromUi.getBottomToolbarState(state.ui);
  export const getLoginVerificationState = (state) => fromAuth.getLoginVerificationState(state.auth);

export default appReducer;
