import cntl from "cntl";
import PropTypes from "prop-types";
import { ReviewStar } from "../atoms";

const proptypes = {
  hasHalf: PropTypes.bool,
  nFullStars: PropTypes.number,
};

const ReviewRating = ({ nFullStars, hasHalf }) => {
  const numbOfStar = [...Array(5)];

  const starsCn = () => cntl`
    flex 
    gap-x-1
    flex-row 
  `;

  return (
    <div className={starsCn()}>
      {numbOfStar?.map((_, index) => {
        return (
          <ReviewStar key={`star-${index}`} isFull={index < nFullStars} isHalf={hasHalf && index === nFullStars} />
        );
      })}
    </div>
  );
};

ReviewRating.prototype = proptypes;
export default ReviewRating;
