function LogoMarkCromie() {
  return (
    <svg
      width="122"
      height="32"
      viewBox="0 0 122 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9227 29.821C13.3419 31.2212 16.1592 32 18.9514 32C21.7485 32 24.4963 31.2603 26.9186 29.8553C27.1969 29.6939 27.4697 29.5244 27.7368 29.3471C29.206 28.3716 29.1945 26.3102 27.9492 25.0591C26.7063 23.8104 24.6988 23.871 23.1157 24.6387C20.3617 25.9743 17.0192 25.9618 14.171 24.3098C12.7604 23.4916 11.6358 22.3668 10.8325 21.0675C11.7515 22.6841 13.2715 25.8418 10.9227 29.821Z"
        fill="url(#paint0_linear_12_102674)"
      />
      <path
        d="M10.9958 29.8644C8.56476 28.4597 6.54602 26.4393 5.14246 24.0063C3.73891 21.5734 3 18.8135 3 16.0041C3 13.1948 3.73891 10.4349 5.14246 8.00192C6.54602 5.56894 8.56524 3.54885 10.9963 2.14417C10.9963 2.14417 18.1748 4.67005 18.9579 6.43192C15.948 6.43192 14.1941 7.68799 14.1941 7.68799C9.60491 10.3397 8.03254 16.2126 10.6821 20.8055C11.5561 22.3205 13.4806 25.6565 10.9958 29.8644Z"
        fill="url(#paint1_linear_12_102674)"
      />
      <circle cx="18.8992" cy="15.8991" r="3.99369" fill="#333333" />
      <path
        d="M28.03 7.00973C29.2774 5.75254 29.2916 3.68179 27.8227 2.69717C27.5421 2.50908 27.2551 2.32963 26.9622 2.15919C24.5311 0.744678 21.7735 0 18.9663 0C16.1592 0 13.4015 0.744678 10.9705 2.15919C13.3692 6.44655 17.2183 6.44655 18.9663 6.44655C20.4747 6.44655 21.9018 6.79732 23.1716 7.42232C24.7605 8.20433 26.7796 8.26978 28.03 7.00973Z"
        fill="#FFD600"
      />
      <path
        d="M53.7074 13.6562H49.9744C49.9062 13.1733 49.767 12.7443 49.5568 12.3693C49.3466 11.9886 49.0767 11.6648 48.7472 11.3977C48.4176 11.1307 48.0369 10.9261 47.6051 10.7841C47.179 10.642 46.7159 10.571 46.2159 10.571C45.3125 10.571 44.5256 10.7954 43.8551 11.2443C43.1847 11.6875 42.6648 12.3352 42.2955 13.1875C41.9261 14.0341 41.7415 15.0625 41.7415 16.2727C41.7415 17.517 41.9261 18.5625 42.2955 19.4091C42.6705 20.2557 43.1932 20.8949 43.8636 21.3267C44.5341 21.7585 45.3097 21.9744 46.1903 21.9744C46.6847 21.9744 47.142 21.9091 47.5625 21.7784C47.9886 21.6477 48.3665 21.4574 48.696 21.2074C49.0256 20.9517 49.2983 20.642 49.5142 20.2784C49.7358 19.9148 49.8892 19.5 49.9744 19.0341L53.7074 19.0511C53.6108 19.8523 53.3693 20.625 52.983 21.3693C52.6023 22.1079 52.0881 22.7699 51.4403 23.3551C50.7983 23.9346 50.0312 24.3949 49.1392 24.7358C48.2528 25.071 47.25 25.2386 46.1307 25.2386C44.5739 25.2386 43.1818 24.8863 41.9545 24.1818C40.733 23.4773 39.767 22.4574 39.0568 21.1221C38.3523 19.7869 38 18.1704 38 16.2727C38 14.3693 38.358 12.75 39.0739 11.4148C39.7898 10.0795 40.7614 9.06248 41.9886 8.36362C43.2159 7.65907 44.5966 7.3068 46.1307 7.3068C47.142 7.3068 48.0795 7.44885 48.9432 7.73294C49.8125 8.01703 50.5824 8.4318 51.2528 8.97726C51.9233 9.51703 52.4688 10.179 52.8892 10.9631C53.3153 11.7471 53.5881 12.6449 53.7074 13.6562Z"
        fill="#101828"
      />
      <path
        d="M55.8155 25V11.9091H59.3354V14.1932H59.4718C59.7104 13.3807 60.111 12.767 60.6735 12.3523C61.236 11.9318 61.8837 11.7216 62.6166 11.7216C62.7985 11.7216 62.9945 11.7329 63.2047 11.7557C63.4149 11.7784 63.5996 11.8096 63.7587 11.8494V15.071C63.5882 15.0199 63.3524 14.9744 63.0513 14.9346C62.7502 14.8949 62.4746 14.875 62.2246 14.875C61.6905 14.875 61.2132 14.9915 60.7928 15.2244C60.378 15.4517 60.0485 15.7699 59.8041 16.179C59.5655 16.5881 59.4462 17.0596 59.4462 17.5937V25H55.8155Z"
        fill="#101828"
      />
      <path
        d="M70.4712 25.2557C69.1474 25.2557 68.0025 24.9744 67.0366 24.4119C66.0763 23.8437 65.3349 23.054 64.8121 22.0426C64.2894 21.0256 64.028 19.8466 64.028 18.5057C64.028 17.1534 64.2894 15.9716 64.8121 14.9602C65.3349 13.9432 66.0763 13.1534 67.0366 12.5909C68.0025 12.0227 69.1474 11.7386 70.4712 11.7386C71.7951 11.7386 72.9371 12.0227 73.8974 12.5909C74.8633 13.1534 75.6076 13.9432 76.1303 14.9602C76.653 15.9716 76.9144 17.1534 76.9144 18.5057C76.9144 19.8466 76.653 21.0256 76.1303 22.0426C75.6076 23.054 74.8633 23.8437 73.8974 24.4119C72.9371 24.9744 71.7951 25.2557 70.4712 25.2557ZM70.4883 22.4432C71.0905 22.4432 71.5934 22.2727 71.9968 21.9318C72.4002 21.5852 72.7042 21.1136 72.9087 20.517C73.1189 19.9204 73.2241 19.2415 73.2241 18.4801C73.2241 17.7187 73.1189 17.0398 72.9087 16.4432C72.7042 15.8466 72.4002 15.375 71.9968 15.0284C71.5934 14.6818 71.0905 14.5085 70.4883 14.5085C69.8803 14.5085 69.3689 14.6818 68.9542 15.0284C68.5451 15.375 68.2354 15.8466 68.0252 16.4432C67.8207 17.0398 67.7184 17.7187 67.7184 18.4801C67.7184 19.2415 67.8207 19.9204 68.0252 20.517C68.2354 21.1136 68.5451 21.5852 68.9542 21.9318C69.3689 22.2727 69.8803 22.4432 70.4883 22.4432Z"
        fill="#101828"
      />
      <path
        d="M78.8052 25V11.9091H82.2654V14.2187H82.4188C82.6916 13.4517 83.1461 12.8466 83.7825 12.4034C84.4188 11.9602 85.1802 11.7386 86.0666 11.7386C86.9643 11.7386 87.7285 11.9631 88.3592 12.4119C88.9899 12.8551 89.4103 13.4574 89.6205 14.2187H89.7569C90.0239 13.4687 90.5069 12.8693 91.2058 12.4204C91.9103 11.9659 92.7427 11.7386 93.7029 11.7386C94.9245 11.7386 95.916 12.1278 96.6774 12.9062C97.4444 13.679 97.8279 14.7756 97.8279 16.196V25H94.2058V16.9119C94.2058 16.1846 94.0126 15.6392 93.6262 15.2756C93.2399 14.9119 92.7569 14.7301 92.1774 14.7301C91.5183 14.7301 91.0041 14.9403 90.6347 15.3608C90.2654 15.7756 90.0808 16.3238 90.0808 17.0057V25H86.5609V16.8352C86.5609 16.1932 86.3762 15.6818 86.0069 15.3011C85.6433 14.9204 85.1632 14.7301 84.5666 14.7301C84.1632 14.7301 83.7995 14.8324 83.4757 15.0369C83.1575 15.2358 82.9046 15.517 82.7171 15.8807C82.5296 16.2386 82.4359 16.6591 82.4359 17.142V25H78.8052Z"
        fill="#101828"
      />
      <path
        d="M100.226 25V11.9091H103.857V25H100.226ZM102.05 10.2216C101.51 10.2216 101.047 10.0426 100.66 9.68464C100.28 9.32101 100.089 8.88635 100.089 8.38067C100.089 7.88067 100.28 7.45169 100.66 7.09373C101.047 6.7301 101.51 6.54828 102.05 6.54828C102.589 6.54828 103.05 6.7301 103.43 7.09373C103.817 7.45169 104.01 7.88067 104.01 8.38067C104.01 8.88635 103.817 9.32101 103.43 9.68464C103.05 10.0426 102.589 10.2216 102.05 10.2216Z"
        fill="#101828"
      />
      <path
        d="M112.261 25.2557C110.914 25.2557 109.755 24.9829 108.784 24.4375C107.818 23.8863 107.073 23.1079 106.551 22.1023C106.028 21.0909 105.766 19.8949 105.766 18.5142C105.766 17.1676 106.028 15.9858 106.551 14.9687C107.073 13.9517 107.809 13.1591 108.758 12.5909C109.713 12.0227 110.832 11.7386 112.116 11.7386C112.98 11.7386 113.784 11.8778 114.528 12.1562C115.278 12.429 115.931 12.8409 116.488 13.392C117.051 13.9432 117.488 14.6363 117.801 15.4716C118.113 16.3011 118.269 17.2727 118.269 18.3863V19.3835H107.215V17.1335H114.852C114.852 16.6108 114.738 16.1477 114.511 15.7443C114.284 15.3409 113.968 15.0256 113.565 14.7983C113.167 14.5653 112.704 14.4488 112.176 14.4488C111.624 14.4488 111.136 14.5767 110.71 14.8324C110.289 15.0824 109.96 15.4204 109.721 15.8466C109.482 16.267 109.36 16.7358 109.355 17.2528V19.392C109.355 20.0398 109.474 20.5994 109.713 21.071C109.957 21.5426 110.301 21.9062 110.744 22.1619C111.187 22.4176 111.713 22.5454 112.32 22.5454C112.724 22.5454 113.093 22.4886 113.428 22.375C113.764 22.2613 114.051 22.0909 114.289 21.8636C114.528 21.6363 114.71 21.3579 114.835 21.0284L118.193 21.25C118.022 22.0568 117.673 22.7613 117.144 23.3636C116.622 23.9602 115.945 24.4261 115.116 24.7613C114.292 25.0909 113.34 25.2557 112.261 25.2557Z"
        fill="#101828"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12_102674"
          x1="18.6521"
          y1="29.1985"
          x2="9.5807"
          y2="26.1225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DB00FF" />
          <stop offset="1" stopColor="#850099" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12_102674"
          x1="8.29721"
          y1="10.6029"
          x2="16.299"
          y2="3.10724"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0BA5EC" />
          <stop offset="1" stopColor="#045C85" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LogoMarkCromie;
