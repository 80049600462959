import cntl from "cntl";
import PropTypes from "prop-types";
import { COLOR } from "../constants";
import { useMemo, useState } from "react";

const propTypes = {
  text: PropTypes.string,
  isBoxed: PropTypes.bool,
  socials: PropTypes.array,
  className: PropTypes.string,
  theme: PropTypes.oneOf([COLOR.dark, COLOR.gray, COLOR.light, COLOR.primary, COLOR.primary_light]),
};

function SocialProofSection({ className, socials, text, theme, isBoxed }) {
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [titleColor, setTitleColor] = useState("");

  const socialCn = () => cntl`
    flex
    w-fit
    gap-x-3
    flex-row
    items-center
    justify-center 
  `;

  const iconCn = () => cntl`
    ${textColor}
  `;

  const logolCn = () => cntl`
    ${titleColor}
  `;

  const textCn = () => cntl`
    text-base
    text-center
    ${textColor}
  `;

  const wrapperCn = () => cntl`
    flex
    h-full
    gap-y-8
    flex-col
    ${isBoxed && bgColor}
    ${isBoxed && "rounded-lg px-8 py-16"}
  `;

  const socialsCn = () => cntl`
    grid
    container
    mx-auto
    md:px-8
    gap-x-8
    gap-y-4
    lg:flex
    grid-cols-2
    lg:gap-x-15
    lg:flex-row
    lg:grid-none
    lg:justify-center
    lg:gap-x-[5.75rem]
    justify-items-center
  `;

  const containerWrapperCn = () => cntl`
    py-16
    md:px-20
    md:py-24
    ${!isBoxed && "px-4"}
    ${!isBoxed && bgColor}
    ${className ? className : undefined}
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-gray-light-10 dark:bg-gray-dark-10");
        setTitleColor("text-white dark:text-black");
        setTextColor("text-gray-light-8 dark:text-gray-dark-8");
        break;
      case COLOR.gray:
        setBgColor("bg-gray-light-2 dark:bg-gray-dark-2");
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
      case COLOR.primary:
        setTitleColor("text-white dark:text-black");
        setBgColor("bg-primary-light-10 dark:bg-primary-dark-10");
        setTextColor("text-primary-light-8 dark:text-primary-dark-8");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        setTextColor("text-primary-light-11 dark:text-primary-dark-11");
        setTitleColor("text-primary-light-12 dark:text-primary-dark-12");
        break;
      default:
        setBgColor("bg-gray-light-2 dark:bg-gray-dark-2");
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        break;
    }
  }, [theme]);

  return (
    <div className={containerWrapperCn()}>
      <div className={wrapperCn()}>
        {text?.length > 0 && <div className={textCn()}>{text}</div>}
        <div className={socialsCn()}>
          {socials?.map(({ link, logo, icon }, index) => {
            return (
              <a href={link} className={socialCn()} key={`social-link-${index}`}>
                {icon && <div className={iconCn()}>{icon}</div>}
                {logo && <div className={logolCn()}>{logo}</div>}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}

SocialProofSection.prototype = propTypes;
export default SocialProofSection;
