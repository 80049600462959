import cntl from "cntl";
import PropTypes from "prop-types";
import TabButton from "./TabButton";
import { useState, useMemo } from "react";
import { SIZE } from "../constants/index";

const propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  tabIndex: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  ariaSelected: PropTypes.string,
  ariaControls: PropTypes.string,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.md]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

function TabButtonGrey({
  id,
  size,
  label,
  onClick,
  children,
  tabIndex,
  className,
  isDisabled,
  ariaSelected,
  ariaControls,
}) {
  const [padding, setPadding] = useState("");

  const tabButtonGreyCn = () => cntl`
    
    ${padding}
    ${
      ariaSelected === "true"
        ? cntl`text-gray-light-12 dark:text-gray-dark-12 bg-gray-light-1 dark:bg-gray-dark-1 shadow focus:shadow-light-sm dark:shadow-dark-sm`
        : !!!isDisabled &&
          cntl`text-gray-light-11 bg-transparent hover:bg-gray-light-1 dark:hover:bg-gray-dark-1 hover:text-gray-light-11 dark:hover:text-gray-dark-11 hover:shadow-light-xs dark:hover:shadow-dark-xs`
    }
    ${className ? className : undefined}
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.xs:
        setPadding("px-3 py-0.5");
        break;
      case SIZE.sm:
        setPadding("px-3 py-2");
        break;
      case SIZE.md:
        setPadding("px-3.5 py-2.5");
        break;
      default:
        setPadding("px-3 py-2");
        break;
    }
  }, [size]);

  return (
    <TabButton
      id={id}
      size={size}
      label={label}
      onClick={onClick}
      tabIndex={tabIndex}
      isDisabled={isDisabled}
      ariaSelected={ariaSelected}
      ariaControls={ariaControls}
      className={tabButtonGreyCn()}
    >
      {children}
    </TabButton>
  );
}

TabButtonGrey.propTypes = propTypes;
export default TabButtonGrey;
