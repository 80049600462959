import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneElement } from "react";

const propTypes = {
  icon: PropTypes.object,
  onClick: PropTypes.func,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const ButtonSegmentedControl = ({ label, icon, onClick, value, isSelected }) => {
  const isOnlyIcon = icon && !label;
  const isOnlyLabel = !icon && label;
  const isIconAndLabel = icon && label;

  const liCn = () => cntl`
    grow
    block
    relative
    [&:not(:last-child):after]:content-['']
    [&:not(:last-child):after]:absolute
    [&:not(:last-child):after]:-right-0.5
    [&:not(:last-child):after]:top-2
    [&:not(:last-child):after]:bottom-2
    [&:not(:last-child):after]:w-px
    ${
      isSelected
        ? `[&:not(:last-child):after]:bg-transparent`
        : `[&:not(:last-child):after]:bg-gray-light-6 [&:not(:last-child):after]:dark:bg-gray-dark-6`
    }
  `;

  const buttonActiveCn = () => cntl`
    bg-transparent
    rounded
    h-full
  `;

  const buttonNoActiveCn = () => cntl`
    bg-transparent
    rounded
    h-full
    ${isOnlyIcon ? `p-1` : `p-2`}
  `;

  const spanNoActiveCn = () => cntl`
    px-1
    flex
    h-full
    rounded
    items-center
    bg-transparent
    justify-center
  `;

  const spanActiveCn = () => cntl`
    flex
    h-full
    rounded
    items-center
    bg-white/[.9]
    justify-center
    ${isOnlyIcon ? `px-2` : `px-3`}
  `;

  const buttonCn = () => cntl`
    ${isSelected ? buttonActiveCn() : buttonNoActiveCn()}
  `;

  const spanCn = () => cntl`
    ${isSelected ? spanActiveCn() : spanNoActiveCn()}
  `;

  const buttonProps = {
    className: buttonCn(),
    onClick: () => onClick(value),
  };

  const onlyIconProps = {
    icon,
    className: spanCn(),
  };

  const onlyLabelProps = {
    label,
    isSelected,
    className: spanCn(),
  };

  const iconAndLabelProps = {
    ...onlyIconProps,
    ...onlyLabelProps,
  };

  return (
    <li className={liCn()}>
      <button {...buttonProps}>
        {isOnlyIcon ? (
          <OnlyIcon {...onlyIconProps} />
        ) : isOnlyLabel ? (
          <OnlyLabel {...onlyLabelProps} />
        ) : isIconAndLabel ? (
          <IconAndLabel {...iconAndLabelProps} />
        ) : null}
      </button>
    </li>
  );
};

const OnlyIcon = ({ icon, className }) => {
  const iconCn = () => cntl`
    w-4
    h-4
    text-gray-light-11
    dark:text-gray-dark-11
  `;

  const spanCn = () => cntl`
    ${className ? className : undefined}
  `;

  return <span className={spanCn()}>{cloneElement(icon, { className: iconCn() })}</span>;
};

const OnlyLabel = ({ isSelected, label, className }) => {
  const labelCn = () => cntl`
    text-sm
    hover:text-gray-light-12
    hover:dark:text-gray-dark-12
    ${
      isSelected
        ? `font-semibold text-gray-light-12 dark:text-gray-dark-12`
        : `font-regular text-gray-light-11 dark:text-gray-dark-11`
    }
    ${className ? className : undefined}
  `;

  return <span className={labelCn()}>{label}</span>;
};

const IconAndLabel = ({ icon, label, className }) => {
  const iconAndLabelCn = () => cntl`
    flex
    gap-x-1
    ${className ? className : undefined}
  `;

  return (
    <span className={iconAndLabelCn()}>
      <OnlyIcon icon={icon} />
      <OnlyLabel label={label} />
    </span>
  );
};

ButtonSegmentedControl.propTypes = propTypes;
export default ButtonSegmentedControl;
