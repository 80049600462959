import cntl from "cntl";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { COLOR, SIZE } from "../constants";
import SectionContent from "./SectionContent";
import SectionItem from "./SectionItem";

const propTypes = {
  theme: PropTypes.oneOf(["", COLOR.dark, COLOR.gray, COLOR.light, COLOR.primary, COLOR.primary_light]),
};

function FeatureSimpleIcons02({ items, theme, content }) {
  const [bgColor, setBgColor] = useState("");

  const wrapperCn = () => cntl`
    ${bgColor}
  `;

  const containerCn = () => cntl`
    container
    px-4 md:px-8 py-16 md:py-24
    gap-y-12
    lg:gap-x-16
    flex
    justify-center
    flex-columns
    mx-auto
  `;

  const columnsWrapperCn = () => cntl`
    grid
    grid-cols-1
    md:grid-cols-3
    gap-y-10
    gap-x-0
    md:gap-x-16
    max:gap-y-8
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-gray-light-10 dark:bg-gray-dark-10");
        break;
      case COLOR.gray:
        setBgColor("bg-gray-light-2 dark:bg-gray-dark-2");
        break;
      case COLOR.primary:
        setBgColor("bg-primary-light-10 dark:bg-primary-dark-10");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        break;
      case COLOR.light:
        setBgColor("bg-white dark:bg-black");
        break;
      default:
        setBgColor("bg-white dark:bg-black");
        break;
    }
  }, [theme]);

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        {content && <SectionContent {...content} />}
        <div className={columnsWrapperCn()}>
          {items?.map((item, index) => {
            return (
              <div key={`simple-icon-item-${index}`}>
                <SectionItem {...item} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

FeatureSimpleIcons02.prototype = propTypes;
export default FeatureSimpleIcons02;
