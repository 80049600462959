import cntl from "cntl";
import { useState } from "react";
import { Input } from "../atoms";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { IconMinus, IconPlus } from "@tabler/icons-react";

const propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  supportingText: PropTypes.string,
  size: PropTypes.oneOf([SIZE.md, SIZE.lg]),
};

function isNumberKey(event) {
  var charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
}

function Counter({ label, onChange, className, inputProps, isDisabled, supportingText, size = SIZE.md }) {
  const minInputValue = inputProps?.min || 0;
  const maxInputValue = inputProps?.max || 100;
  const [counter, setCounter] = useState(0);

  const wrapperCn = () => cntl`
    flex
    flex-col
    gap-y-1.5
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    text-sm
    font-medium
    text-gray-light-11
    dark:text-gray-dark-11
    first-letter:uppercase
  `;

  const elementCn = () => cntl`
    flex
  `;

  const supportingTextCn = () => cntl`
    text-sm
    text-gray-light-11
    dark:text-gray-dark-11
    first-letter:uppercase
  `;

  const handleButtonClick = ({ target }) => {
    if (!isDisabled) {
      if (target === "remove") {
        if (counter - 1 >= minInputValue) {
          onChange(counter - 1);
          setCounter(counter - 1);
        } else {
          onChange(minInputValue);
          return minInputValue;
        }
      }
      if (target === "add") {
        if (counter + 1 <= maxInputValue) {
          onChange(counter + 1);
          setCounter(counter + 1);
        } else {
          onChange(maxInputValue);
          return maxInputValue;
        }
      }
    }
  };

  const onInputChange = (e) => {
    if (isNumberKey(e)) {
      setCounter(Number(e?.target?.value));
      onChange(e?.target?.value);
    }
  };

  return (
    <div className={wrapperCn()}>
      {label?.length && <label className={labelCn()}>{label}</label>}
      <div className={elementCn()}>
        <Input
          step={1}
          size={size}
          value={counter}
          isDisabled={isDisabled}
          onChange={onInputChange}
          prefix={
            <div className="cursor-pointer" onClick={() => handleButtonClick({ target: "remove" })}>
              <IconMinus />
            </div>
          }
          suffix={
            <div className="cursor-pointer" onClick={() => handleButtonClick({ target: "add" })}>
              <IconPlus />
            </div>
          }
          {...inputProps}
        />
      </div>
      {supportingText?.length && <div className={supportingTextCn()}>{supportingText}</div>}
    </div>
  );
}

Counter.propTypes = propTypes;
export default Counter;
