import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import { useState, useCallback } from "react";
import { ButtonMini, Dropdown, Menu, Trigger } from "../../atoms";
import cntl from "cntl";

const propTypes = {
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  onItemSelect: PropTypes.func,
  buttonMini: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

function ButtonMiniDropdown({ isDisabled, children, className, onItemSelect, buttonMini }) {
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const dropdownCn = () => cntl`
    ${className ? className : undefined}
  `;

  const offset = useCallback(
    ({ reference, popper }) => {
        return [(popper.width - reference.width  ) / 2, 4];
    },
    []
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset
        },
      },
    ],
    strategy: "absolute",
  });

  const dropdownProps = {
    isDisabled,
    onSelect: onItemSelect,
    className: dropdownCn(),
  };

  const triggerProps = {
    ref: setReferenceElement,
  };

  const menuProps = {
    ref: setPopperElement,
    style: styles.popper,
    ...attributes.popper,
  };

  return (
    <Dropdown {...dropdownProps}>
      <Trigger {...triggerProps}>
        <ButtonMini {...buttonMini} />
      </Trigger>
      <Menu {...menuProps}>{children}</Menu>
    </Dropdown>
  );
}

ButtonMiniDropdown.propTypes = propTypes;
export default ButtonMiniDropdown;
