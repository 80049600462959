import cntl from "cntl";
import PropTypes from "prop-types";
import { Badge } from "../../atoms";
import { BADGE_VARIANTS, COLOR } from "../../constants";

const propTypes = {
  icon: PropTypes.object,
  variant: PropTypes.oneOf([BADGE_VARIANTS.solid, BADGE_VARIANTS.surface, BADGE_VARIANTS.soft, BADGE_VARIANTS.outline]),
  color: PropTypes.oneOf([
    COLOR.gray,
    COLOR.info,
    COLOR.blue,
    COLOR.pink,
    COLOR.amber,
    COLOR.error,
    COLOR.primary,
    COLOR.success,
    COLOR.warning,
  ]),
};

function BadgeText({ icon, className, color = COLOR.gray, variant = BADGE_VARIANTS.soft, ...props }) {
  const badgeTextCn = () => cntl`
    ${style[variant]?.[color]?.label}
    ${style[variant]?.[color]?.border}
    ${style[variant]?.[color]?.background}
  `;

  const style = {
    [BADGE_VARIANTS.soft]: {
      [COLOR.primary]: {
        background: "bg-primary-lightAlpha-3 dark:bg-primary-darkAlpha-3",
        label: "text-primary-lightAlpha-11 dark:text-primary-darkAlpha-11",
      },
      [COLOR.gray]: {
        background: "bg-gray-lightAlpha-3 dark:bg-gray-darkAlpha-3",
        label: "text-gray-lightAlpha-11 dark:text-gray-darkAlpha-11",
      },
      [COLOR.error]: {
        background: "bg-error-lightAlpha-3 dark:bg-error-darkAlpha-3",
        label: "text-error-lightAlpha-11 dark:text-error-darkAlpha-11",
      },
      [COLOR.success]: {
        background: "bg-success-lightAlpha-3 dark:bg-success-darkAlpha-3",
        label: "text-success-lightAlpha-11 dark:text-success-darkAlpha-11",
      },
      [COLOR.info]: {
        background: "bg-info-lightAlpha-3 dark:bg-info-darkAlpha-3",
        label: "text-info-lightAlpha-11 dark:text-info-darkAlpha-11",
      },
      [COLOR.warning]: {
        background: "bg-warning-lightAlpha-3 dark:bg-warning-darkAlpha-3",
        label: "text-warning-lightAlpha-11 dark:text-warning-darkAlpha-11",
      },
      [COLOR.blue]: {
        background: "bg-blue-lightAlpha-3 dark:bg-blue-darkAlpha-3",
        label: "text-blue-lightAlpha-11 dark:text-blue-darkAlpha-11",
      },
      [COLOR.amber]: {
        background: "bg-amber-lightAlpha-3 dark:bg-amber-darkAlpha-3",
        label: "text-amber-lightAlpha-11 dark:text-amber-darkAlpha-11",
      },
      [COLOR.pink]: {
        background: "bg-pink-lightAlpha-3 dark:bg-pink-darkAlpha-3",
        label: "text-pink-lightAlpha-11 dark:text-pink-darkAlpha-11",
      },
    },
    [BADGE_VARIANTS.solid]: {
      [COLOR.primary]: {
        label: "text-white",
        background: "bg-primary-light-9 dark:bg-primary-dark-9",
      },
      [COLOR.gray]: {
        label: "text-white",
        background: "bg-gray-light-9 dark:bg-gray-dark-9",
      },
      [COLOR.error]: {
        label: "text-white",
        background: "bg-error-light-9 dark:bg-error-dark-9",
      },
      [COLOR.success]: {
        label: "text-white",
        background: "bg-success-light-9 dark:bg-success-dark-9",
      },
      [COLOR.info]: {
        label: "text-white",
        background: "bg-info-light-9 dark:bg-info-dark-9",
      },
      [COLOR.warning]: {
        label: "text-white",
        background: "bg-warning-light-9 dark:bg-warning-dark-9",
      },
      [COLOR.blue]: {
        label: "text-white",
        background: "bg-blue-light-9 dark:bg-blue-dark-9",
      },
      [COLOR.amber]: {
        label: "text-gray-light-12 dark:text-gray-dark-12",
        background: "bg-amber-light-9 dark:bg-amber-dark-9",
      },
      [COLOR.pink]: {
        label: "text-white",
        background: "bg-pink-light-9 dark:bg-pink-dark-9",
      },
    },
    [BADGE_VARIANTS.surface]: {
      [COLOR.primary]: {
        background: "bg-primary-light-3 dark:bg-primary-dark-3",
        label: "text-primary-lightAlpha-11 dark:text-primary-darkAlpha-11",
        border: "border border-primary-lightAlpha-7 dark:border-primary-darkAlpha-7",
      },
      [COLOR.gray]: {
        background: "bg-gray-light-3 dark:bg-gray-dark-3",
        label: "text-gray-lightAlpha-11 dark:text-gray-darkAlpha-11",
        border: "border border-gray-lightAlpha-7 dark:border-gray-darkAlpha-7",
      },
      [COLOR.error]: {
        background: "bg-error-light-3 dark:bg-error-dark-3",
        label: "text-error-lightAlpha-11 dark:text-error-darkAlpha-11",
        border: "border border-error-lightAlpha-7 dark:border-error-darkAlpha-7",
      },
      [COLOR.success]: {
        background: "bg-success-light-3 dark:bg-success-dark-3",
        label: "text-success-lightAlpha-11 dark:text-success-darkAlpha-11",
        border: "border border-success-lightAlpha-7 dark:border-success-darkAlpha-7",
      },
      [COLOR.info]: {
        background: "bg-info-light-3 dark:bg-info-dark-3",
        label: "text-info-lightAlpha-11 dark:text-info-darkAlpha-11",
        border: "border border-info-lightAlpha-7 dark:border-info-darkAlpha-7",
      },
      [COLOR.warning]: {
        background: "bg-warning-light-3 dark:bg-warning-dark-3",
        label: "text-warning-lightAlpha-11 dark:text-warning-darkAlpha-11",
        border: "border border-warning-lightAlpha-7 dark:border-warning-darkAlpha-7",
      },
      [COLOR.blue]: {
        background: "bg-blue-light-3 dark:bg-blue-dark-3",
        label: "text-blue-lightAlpha-11 dark:text-blue-darkAlpha-11",
        border: "border border-blue-lightAlpha-7 dark:border-blue-darkAlpha-7",
      },
      [COLOR.amber]: {
        background: "bg-amber-light-3 dark:bg-amber-dark-3",
        label: "text-amber-lightAlpha-11 dark:text-amber-darkAlpha-11",
        border: "border border-amber-lightAlpha-7 dark:border-amber-darkAlpha-7",
      },
      [COLOR.pink]: {
        background: "bg-pink-light-3 dark:bg-pink-dark-3",
        label: "text-pink-lightAlpha-11 dark:text-pink-darkAlpha-11",
        border: "border border-pink-lightAlpha-7 dark:border-pink-darkAlpha-7",
      },
    },
    [BADGE_VARIANTS.outline]: {
      [COLOR.primary]: {
        background: "bg-transparent",
        label: "text-primary-lightAlpha-11 dark:text-primary-darkAlpha-11",
        border: "border-1.5 border-primary-lightAlpha-8 dark:border-primary-darkAlpha-8",
      },
      [COLOR.gray]: {
        background: "bg-transparent",
        label: "text-gray-lightAlpha-11 dark:text-gray-darkAlpha-11",
        border: "border-1.5 border-gray-lightAlpha-8 dark:border-gray-darkAlpha-8",
      },
      [COLOR.error]: {
        background: "bg-transparent",
        label: "text-error-lightAlpha-11 dark:text-error-darkAlpha-11",
        border: "border-1.5 border-error-lightAlpha-8 dark:border-error-darkAlpha-8",
      },
      [COLOR.success]: {
        background: "bg-transparent",
        label: "text-success-lightAlpha-11 dark:text-success-darkAlpha-11",
        border: "border-1.5 border-success-lightAlpha-8 dark:border-success-darkAlpha-8",
      },
      [COLOR.info]: {
        background: "bg-transparent",
        label: "text-info-lightAlpha-11 dark:text-info-darkAlpha-11",
        border: "border-1.5 border-info-lightAlpha-8 dark:border-info-darkAlpha-8",
      },
      [COLOR.warning]: {
        background: "bg-transparent",
        label: "text-warning-lightAlpha-11 dark:text-warning-darkAlpha-11",
        border: "border-1.5 border-warning-lightAlpha-8 dark:border-warning-darkAlpha-8",
      },
      [COLOR.blue]: {
        background: "bg-transparent",
        label: "text-blue-lightAlpha-11 dark:text-blue-darkAlpha-11",
        border: "border-1.5 border-blue-lightAlpha-8 dark:border-blue-darkAlpha-8",
      },
      [COLOR.amber]: {
        background: "bg-transparent",
        label: "text-amber-lightAlpha-11 dark:text-amber-darkAlpha-11",
        border: "border-1.5 border-amber-lightAlpha-8 dark:border-amber-darkAlpha-8",
      },
      [COLOR.pink]: {
        background: "bg-transparent",
        label: "text-pink-lightAlpha-11 dark:text-pink-darkAlpha-11",
        border: "border-1.5 border-pink-lightAlpha-8 dark:border-pink-darkAlpha-8",
      },
    },
  };

  return (
    <Badge className={badgeTextCn()} {...props}>
      {icon}
    </Badge>
  );
}

BadgeText.propTypes = propTypes;
export default BadgeText;
