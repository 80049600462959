import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Layout({ children, ...props }) {
  return <div {...props}>{children}</div>;
}

Layout.propTypes = propTypes;
export default Layout;
