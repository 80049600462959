import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants/index";

const propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  tabIndex: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  ariaSelected: PropTypes.string,
  ariaControls: PropTypes.string,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

function TabButton({
  id,
  label,
  onClick,
  children,
  tabIndex,
  className,
  ariaControls,
  size = SIZE.xs,
  isDisabled = false,
  ariaSelected = "false",
}) {
  const buttonCn = () => cntl`
    flex
    gap-x-2
    snap-center
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    font-medium
    first-letter:uppercase
    ${style[size]?.padding}
    ${style[size]?.fontStyle}
    ${isDisabled ? `text-gray-lightAlpha-8 dark:text-gray-darkAlpha-8` : `text-gray-light-12 dark:text-gray-dark-12`}
  `;

  const style = {
    [SIZE.xs]: {
      fontStyle: "text-xs",
      padding: "px-1 py-0.5",
    },
    [SIZE.sm]: {
      fontStyle: "text-sm",
      padding: "px-2 py-1",
    },
  };

  return (
    <button
      id={id}
      role="tab"
      onClick={onClick}
      tabIndex={tabIndex}
      disabled={isDisabled}
      className={buttonCn()}
      aria-controls={ariaControls}
      aria-selected={ariaSelected}
    >
      <span className={labelCn()}>{label}</span>
      {children}
    </button>
  );
}

TabButton.propTypes = propTypes;
export default TabButton;
