import cntl from "cntl";
import PropTypes from "prop-types";
import { ButtonPrimary } from "../atoms";
import { POSITIONS, SIZE } from "../constants";
import { useTranslation } from "react-i18next";
import { BadgeText, HeaderPricing } from "../molecules";

const propTypes = {
  period: PropTypes.string,
  firstCol: PropTypes.array,
  className: PropTypes.string,
  columnsProps: PropTypes.array,
};

function PricingPageHeader({ className, firstCol, columnsProps, period }) {
  return (
    <FeaturedIcon3ColsDesktop className={className} firstCol={firstCol} columnsProps={columnsProps} period={period} />
  );
}

const FeaturedIcon3ColsDesktop = ({ className, firstCol, columnsProps, period }) => {
  const { t } = useTranslation();

  const wrapperCn = () => cntl`
    grid
    border
    rounded-xl
    grid-cols-1
    xl:grid-cols-4
    border-gray-light-6
    dark:border-gray-dark-6
    ${className ? className : undefined}
  `;

  const colCn = ({ isHighlight, colIndex }) => cntl`
    flex-col
    divide-y
    divide-solid
    divide-gray-light-6
    dark:divide-gray-dark-6
    ${!isHighlight && colIndex === 0 && `border-l border-l-gray-light-6`}
    ${isHighlight && colIndex === 2 && `lg:rounded-r-xl`}
    ${
      isHighlight
        ? `lg:rounded border-primary-light-6 dark:border-primary-dark-6 border-2 -m-[2px] ${
            colIndex === 0 ? "rounded-t-xl" : colIndex === 2 ? "rounded-b-xl" : ""
          }`
        : `lg:border-r border-r-gray-light-6 dark:border-r-gray-dark-6 last:border-r-0`
    }
  `;

  const firstColCn = () => cntl`
    hidden
    xl:flex
    flex-col
    divide-y
    divide-solid
    divide-gray-light-6
    dark:divide-gray-dark-6
    bg-gray-lightAlpha-2
    dark:bg-gray-darkAlpha-2
  `;

  const headerCn = () => cntl`
    p-4
    flex
    pb-8
    lg:p-8
    lg:pb-10
    flex-col
    xl:h-[10rem]
    justify-between
  `;

  const tableCn = () => cntl`
    flex
    flex-col
    divide-y
    divide-solid
    divide-gray-light-6
    dark:divide-gray-dark-6
  `;

  return (
    <div className={wrapperCn()}>
      <div className={firstColCn()}>
        <BillingPeriod
          period={period}
          title={t("page.plans.body.plans")}
          subtitle={t("page.plans.body.billing_period")}
        />
        {firstCol?.map(({ title, rows }, index) => {
          return <FirstCol title={title} rows={rows} index={index} key={`col-${index}`} />;
        })}
      </div>
      {columnsProps?.map((props, colIndex) => {
        return (
          <div className={colCn({ isHighlight: props.header?.isMostPopular, colIndex })} key={`col-price-${colIndex}`}>
            <div className={headerCn()}>
              {props?.header && <HeaderPricing {...props?.header} alignment={POSITIONS.left} />}
            </div>
            {props?.groups?.length && (
              <div className={tableCn()}>
                {props?.groups?.map((props, cellIndex) => {
                  return (
                    <div key={`cell-col-${cellIndex}`}>
                      <TableMobile index={cellIndex} className="block xl:hidden" {...props} />
                      <TableDesktop index={cellIndex} className="hidden xl:block" {...props} />
                    </div>
                  );
                })}
              </div>
            )}
            {props?.button && (
              <div>
                <ButtonPrimary size={SIZE.md} className="mx-auto mt-8 mb-16 lg:mb-8" {...props?.button} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const TableMobile = ({ index, className, ...props }) => {
  const wrapperCn = () => cntl`
    border-t
    border-gray-light-6 
    ${className ? className : undefined}
  `;

  const cellTitleCn = () => cntl`
    h-9
    px-4
    mt-8
  `;

  const titleCn = () => cntl`
    text-sm
    font-medium
    text-gray-light-11
    dark:text-gray-dark-11
  `;

  const cellRowCn = () => cntl`
    h-14
    px-4
    flex
    text-sm
    font-medium
    items-center
    justify-between
  `;

  const valueCn = () => cntl`
    text-gray-light-11 
    dark:text-gray-dark-11
  `;

  return (
    <div className={wrapperCn()}>
      {props?.title && (
        <div className={cellTitleCn()}>
          <div className={titleCn()}>{props?.title}</div>
        </div>
      )}
      {props?.rows?.map(({ value, description }, index) => {
        return (
          <div className={cellRowCn()} key={`mobile-row-${index}`}>
            <div>{description?.text}</div>
            <div className={valueCn()}>{value}</div>
          </div>
        );
      })}
    </div>
  );
};

const TableDesktop = ({ index, className, ...props }) => {
  const wrapperCn = () => cntl`
    py-4
    ${className ? className : undefined}
  `;

  const cellRowCn = () => cntl`
    h-9
    px-4
    flex
    text-sm
    font-medium
    items-center
    text-gray-light-12 
    dark:text-gray-dark-12
    justify-between
  `;

  const valueCn = () => cntl`
    text-gray-light-11 
    dark:text-gray-dark-11
    text-left
  `;

  return (
    <div className={wrapperCn()}>
      <div className="h-9" key={`desktop-row-${index}`}></div>
      {props?.rows?.map(({ value }, index) => {
        return (
          <div className={cellRowCn()} key={`desktop-row-${index}`}>
            <div className={valueCn()}>{value}</div>
          </div>
        );
      })}
    </div>
  );
};

const FirstCol = ({ rows, title, index }) => {
  const { t } = useTranslation();

  const titleCn = () => cntl`
    h-9
    pl-4
    text-sm
    font-medium
    text-gray-light-11
    dark:text-gray-dark-11
  `;

  const cellRowCn = () => cntl`
    h-9
    px-4
    flex
    text-sm
    font-medium
    items-center
    text-gray-light-12 
    dark:text-gray-dark-12
    justify-between
  `;

  return (
    <div className="py-4">
      <div className={titleCn()}>{t(`card.${title}`)}</div>
      {rows?.map(({ description }, index) => {
        return (
          <div className={cellRowCn()} key={`first-col-${index}`}>
            <div>{description?.text}</div>
          </div>
        );
      })}
    </div>
  );
};

const BillingPeriod = function ({ title, subtitle, className, period }) {
  const style = {
    default: {
      text: "text-gray-light-11 dark:text-gray-dark-11",
      title: "text-gray-light-12 dark:text-gray-dark-12",
    },
  };

  const container = () => cntl`
    flex
    flex-col
    justify-center
    items-start
    p-4
    gap-2
    text-nowrap
    h-40
    ${className ? className : undefined}
  `;

  const titleCn = () => cntl`
    text-base
    font-semibold	
    ${style["default"]?.title}
  `;

  const subtitleCn = () => cntl`
    text-sm
    font-semibold
    ${style["default"]?.title}
  `;

  return (
    <div className={container()}>
      {title?.length && <div className={titleCn()}>{title}</div>}
      {subtitle?.length > 0 && <div className={subtitleCn()}>{subtitle}:</div>}
      <BadgeText label={period} />
    </div>
  );
};

PricingPageHeader.prototype = propTypes;
export default PricingPageHeader;
