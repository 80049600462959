export const POLLING_INTERVAL = 600 * 1000;
export const SNACKBAR_TIMEOUT = 9 * 1000;
export const DOTS = "...";

export const BADGE_VALUES = {
  enabled: "enabled",
  online: "online",
  disabled: "disabled",
  completed: "completed",
  confirmed: "confirmed",
  accepted: "accepted",
  executed: "executed",
  successful: "successful",
  error: "error",
  rejected: "rejected",
  denied: "denied",
  failed: "failed",
  pending: "pending",
  toProcess: "to_process",
  paid: "paid",
  sent: "sent",
  general: "general",
  single: "single",
  toPay: "to_pay",
  pages: "pages",
  support: "support",
  cover: "cover",
  frontCover: "frontcover",
  backCover: "backcover",
  created: "created",
  true: "true",
  false: "false",
  currentPlan: "currentPlan",
  tenant_administrator: "tenant_administrator",
  not_tenant_administrator: "not_tenant_administrator",
};
