import { get } from "lodash";
import { openSnackbar } from "../common/ui";
import { POLLING_INTERVAL } from "../../constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACCESS_TOKEN_STORAGE_KEY } from "@dbox/core";
import { localStorage } from "../../services/common/storage";
import pollingServiceInstance from "@dbox/core/services/common/PollingService";
import authSlice, * as fromAuth from "@dbox/aldys-common/store/reducers/auth.reducer";
import { logout, refreshToken, authenticateUser } from "@dbox/core/services/aldys/authentication";

const getAccessToken = (state) => {
  return fromAuth.getAccessToken(state.auth);
};

export const authenticate =
  ({ email, two_factors_auth_token, device_info }) =>
  async (dispatch) => {
    const res = await dispatch(logIn({ email, two_factors_auth_token, device_info }));

    if (get(res, "type", "") === "auth/logIn/fulfilled") {
      pollingServiceInstance.startPolling(POLLING_INTERVAL, () => dispatch(refresh()));
    }
  };

export const refreshAuth = (app) => async (dispatch) => {
  const res = await dispatch(refresh());

  if (get(res, "type", "") === "auth/refresh/fulfilled") {
    pollingServiceInstance.startPolling(POLLING_INTERVAL, () => dispatch(refresh()));
  }
};

const logIn = createAsyncThunk("auth/logIn", async ({ email, two_factors_auth_token, device_info }, { dispatch }) => {
  try {
    const res = await authenticateUser(email, two_factors_auth_token, device_info);
    return res;
  } catch (err) {
    dispatch(
      openSnackbar({
        type: "error",
        message: err.message,
      })
    );
    throw err;
  }
});

export const syncTokens = createAsyncThunk("auth/syncTokens", async (_, { dispatch }) => {
  try {
    const tokens = await localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    return tokens ? JSON.parse(tokens) : null;
  } catch (err) {
    dispatch(
      openSnackbar({
        type: "error",
        message: err.message,
      })
    );
    throw err;
  }
});

export const refresh = createAsyncThunk("auth/refresh", async (_, { dispatch, getState }) => {
  try {
    const accessToken = getAccessToken(getState());
    const newToken = await refreshToken(accessToken);
    return newToken;
  } catch (err) {
    dispatch(authSlice.actions.removeTokens());
    pollingServiceInstance.stopPolling();
    throw err;
  }
});

export const logOut = createAsyncThunk("auth/logOut", async (_, { dispatch }) => {
  try {
    await logout();
    return;
  } catch (err) {
    dispatch(
      openSnackbar({
        type: "error",
        message: err.message,
      })
    );
    throw err;
  }
});
