import cntl from "cntl";
import PropTypes from "prop-types";
import { FeaturedIcon, ProgressBar } from "../atoms";
import { useMemo, useState, useCallback } from "react";
import { COLOR, FEATURED_ICON_VARIANTS, STATUS } from "../constants";
import { IconCircleCheck, IconTrash } from "@tabler/icons-react";

const propTypes = {
  icon: PropTypes.object,
  labelPB: PropTypes.string,
  className: PropTypes.string,
  progressPB: PropTypes.number,
  onRemoveFile: PropTypes.func,
  sizePB: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.oneOf([STATUS.success, STATUS.error, STATUS.undefined]),
};

function FileUploadItemBase({
  icon,
  status,
  sizePB,
  labelPB,
  className,
  progressPB,
  onRemoveFile,
}) {
  const [textStyle, setTextStyle] = useState("");
  const [iconStyle, setIconStyle] = useState("");
  const [statusStyle, setStatusStyle] = useState("");
  const [colorFeaturedIcon, setColorFeaturedIcon] = useState();

  const itemBaseWrappwerCn = () => cntl`
    p-4  
    flex
    border
    flex-row
    gap-x-3
    rounded-lg
    justify-between
    ${statusStyle}
    ${className ? className : undefined}
  `;

  const iconCn = () => cntl`
    h-4
    w-4
    shrink-0
    ${iconStyle}
  `;

  useMemo(() => {
    switch (status) {
      case STATUS.success:
        setTextStyle("text-primary-light-11 dark:text-primary-light-11");
        setIconStyle("text-primary-light-11 dark:text-primary-light-11");
        setColorFeaturedIcon(COLOR.primary);
        setStatusStyle("border-primary-light-11 dark:text-primary-light-11 bg-white dark:bg-black");
        break;
      case STATUS.error:
        setTextStyle("text-error-light-11 dark:text-error-dark-11");
        setColorFeaturedIcon(COLOR.error);
        setIconStyle("text-error-light-11 dark:text-error-dark-11 cursor-pointer");
        setStatusStyle("border-error-light-11 dark:border-error-dark-11 bg-error-light-1 dark:bg-error-dark-1");
        break;
      case STATUS.undefined:
        setTextStyle("text-primary-light-11 dark:text-primary-dark-11");
        setColorFeaturedIcon(COLOR.primary);
        setStatusStyle("border-gray-light-6 dark:text-gray-dark-6 bg-white dark:bg-black");
        setIconStyle("text-gray-light-11 dark:text-gray-dark-11 cursor-pointer");
        break;
      default:
        setTextStyle("text-primary-light-11 dark:text-primary-dark-11");
        setColorFeaturedIcon(COLOR.primary);
        setStatusStyle("border-gray-light-6 dark:border-gray-dark-6 bg-white dark:bg-black");
        setIconStyle("text-gray-light-11 dark:text-gray-dark-11 cursor-pointer");
        break;
    }
  }, [status]);

  const renderFeaturedIcon = useCallback(() => {
    return (
      <FeaturedIcon
        color={colorFeaturedIcon}
        vaiant={FEATURED_ICON_VARIANTS.light_circle_outline}
      >
        {icon}
      </FeaturedIcon>
    );
  }, [icon, colorFeaturedIcon]);

  return (
    <div className={itemBaseWrappwerCn()}>
      {renderFeaturedIcon()}
        <ProgressBar
          status={status}
          label={labelPB}
          textStyle={textStyle}
          sizeFile={sizePB?.toString()}
          progress={progressPB?.toString()}
        />
      {status === STATUS.success ? (
        <IconCircleCheck className={iconCn()} />
      ) : (
        <IconTrash className={iconCn()} onClick={() => onRemoveFile()} />
      )}
    </div>
  );
}

FileUploadItemBase.propTypes = propTypes;
export default FileUploadItemBase;
