import { SNACKBAR_TIMEOUT } from "../../constants";
import { getSnackbarTimeoutId, getNotificationTimeoutId } from "../../reducers/ui.reducer";

export const openSnackbar =
  (option = {}) =>
  (dispatch, getState) => {
    const { type, message } = option;
    const state = getState();

    const previousTimeoutId = getSnackbarTimeoutId(state.ui);

    if (previousTimeoutId) {
      dispatch(closeSnackbar());
    }

    const timeoutId = setTimeout(() => {
      dispatch({
        type: "ui/closeSnackbar",
      });
    }, SNACKBAR_TIMEOUT);
    dispatch({
      type: "ui/openSnackbar",
      payload: {
        type,
        message,
        timeoutId,
      },
    });
  };

export const openNotification =
  (option = {}) =>
  (dispatch, getState) => {
    const { id, type, message, title, children, timeStamp, variant } = option;
    const state = getState();

    const previousTimeoutId = getNotificationTimeoutId(state.ui);

    if (previousTimeoutId) {
      dispatch(closeNotification());
    }

    const timeoutId = setTimeout(() => {
      dispatch({
        variant,
        type: "ui/closeNotification",
      });
    }, SNACKBAR_TIMEOUT);
    dispatch({
      type: "ui/openNotification",
      payload: {
        id,
        type,
        title,
        variant,
        message,
        children,
        timeStamp,
        timeoutId,
      },
    });
  };

export const closeAllPickers = () => {
  return {
    type: "ui/closeAllPickers",
  };
};

export const openPicker = () => {
  return {
    type: "ui/setPickerOpen",
  };
};

export const closeSnackbar = () => (dispatch, getState) => {
  const state = getState();

  const previousTimeoutId = getSnackbarTimeoutId(state.ui);

  if (previousTimeoutId) {
    clearTimeout(previousTimeoutId);
  }

  dispatch({
    type: "ui/closeSnackbar",
  });
};

export const closeNotification = (props) => (dispatch, getState) => {
  const state = getState();

  const previousTimeoutId = getNotificationTimeoutId(state.ui);

  if (previousTimeoutId) {
    clearTimeout(previousTimeoutId);
  }

  dispatch({
    variant: props?.variant,
    type: "ui/closeNotification",
  });
};
