import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneElement, forwardRef } from "react";

const propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLeadIcon: PropTypes.bool,
  isFirstLevel: PropTypes.bool,
};

const ButtonMini = forwardRef(function ButtonMini({ label, icon, className, isDisabled, onClick }, ref) {
  const miniButton = () => cntl`
    transition
    bg-gray-light-2
    dark:bg-gray-dark-2
    ${
      isDisabled
        ? `hover:bg-gray-light-2 hover:dark:bg-gray-dark-2`
        : `border hover:bg-gray-light-4 dark:hover:bg-gray-dark-4 border-gray-light-6 dark:border-gray-dark-6`
    }
    ${className ? className : undefined}
  `;

  const miniButtonCn = () => cntl`
    h-6
    flex
    px-1.5
    rounded
    gap-x-1
    items-center
    ${miniButton()}
    ${className ? className : undefined}
  `;

  const iconCn = () => cntl`
    w-4
    h-4
    rounded
    text-gray-light-11
    dark:text-gray-dark-11
    ${label ? `mr-0` : `mr-1.5`}
  `;

  const textCn = () => cntl`
    text-xs
    font-medium
    text-gray-light-12
    first-letter:uppercase
    dark:text-gray-dark-12
  `;

  return (
    <button ref={ref} className={miniButtonCn()} onClick={onClick} disabled={isDisabled}>
      {icon
        ? cloneElement(icon, {
            className: iconCn(),
          })
        : null}
      {label ? <div className={textCn()}>{label}</div> : null}
    </button>
  );
});

ButtonMini.propTypes = propTypes;
export default ButtonMini;
