import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { cloneElement } from "react";
import SectionItem from "./SectionItem";
import SectionContent from "./SectionContent";

const propTypes = {
  item: PropTypes.array,
  content: PropTypes.object,
};

function FeatureCardIcons01({ items, content }) {
  const containerCn = () => cntl`
    container
    mx-auto
    px-4 md:px-8 py-16 md:py-24
  `;

  const columnsWrapperCn = () => cntl`
    grid
    grid-cols-1
    md:grid-cols-2
    ${"lg:grid-cols-" + items?.length}
    gap-6
  `;

  const itemCn = () => cntl`
    flex 
    flex-col 
    gap-y-12
    md:gap-y-16 
    p-5
    md:p-6 
    bg-gray-light-2 
    dark:bg-gray-dark-2
  `;

  const iconCn = () => cntl`
    rounded-lg
    bg-primary-light-5 
    dark:bg-primary-dark-5
    p-3
    w-fit
  `;

  return (
    <div className={containerCn()}>
      <div className="flex flex-col gap-y-12 md:gap-y-16">
        {content && <SectionContent {...content} size={SIZE.sm} />}
        <div className={columnsWrapperCn()}>
          {items?.map(({ icon, text, title, theme }, index) => {
            return (
              <div key={`section-item-icon-${index}`} className={itemCn()}>
                {icon && (
                  <div className={iconCn()}>
                    {cloneElement(icon, {
                      className: `w-6 h-6 text-white`,
                    })}
                  </div>
                )}
                <SectionItem title={title} text={text} theme={theme} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

FeatureCardIcons01.prototype = propTypes;
export default FeatureCardIcons01;
