import cntl from "cntl";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import Menu from "../atoms/dropdown/Menu";
import Item from "../atoms/dropdown/Item";
import Trigger from "../atoms/dropdown/Trigger";
import ListItem from "../atoms/dropdown/ListItem";
import Dropdown from "../atoms/dropdown/Dropdown";
import HeaderItem from "../atoms/dropdown/HeaderItem";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { arrayBufferToBase64 } from "@dbox/core/utils/arrayBufferToBase64";
import { MenuBlock } from "../atoms";

const propTypes = {
  onItemSelect: PropTypes.func,
  triggerProps: PropTypes.object,
  headerBlockProps: PropTypes.object,
  logoutBlockProps: PropTypes.object,
  settingsBlockProps: PropTypes.array,
};

function SwitcherDropdown({ onItemSelect, triggerProps, logoutBlockProps, headerBlockProps, settingsBlockProps }) {

  const menuCn = () => cntl`
    w-80
    absolute
  `;

  return (
    <Dropdown onSelect={onItemSelect}>
      <Trigger>
        <TriggerElement {...triggerProps} />
      </Trigger>
      <Menu className={menuCn()}>
        <HeaderBlock {...headerBlockProps} />
        <SettingsBlock items={settingsBlockProps} />
        <LogoutBlock items={logoutBlockProps} />
      </Menu>
    </Dropdown>
  );
}

const HeaderBlock = ({ text, listItems }) => {
  const headerBlockCn = () => cntl`
    p-3
  `;

  return (
    <div className={headerBlockCn()}>
      <HeaderItem text={text} />
      {listItems?.map((props) => {
        return <ListItem key={uniqueId("dropdown-list-item-")} {...props} />;
      })}
    </div>
  );
};

const SettingsBlock = ({ items }) => {
  return (
    <MenuBlock>
      {items?.map((props) => {
        return <Item key={uniqueId("dropdown-item-")} {...props} />;
      })}
    </MenuBlock>
  );
};

const LogoutBlock = ({ items }) => {
  return (
    <MenuBlock>
      <Item {...items} />
    </MenuBlock>
  );
};

const TriggerElement = ({ logo, text, onClick }) => {
  const headerCn = () => cntl`
    h-9
    px-2
    flex
    gap-x-2.5
    rounded-lg
    items-center
    cursor-pointer
    hover:bg-gray-light-4
    dark:hover:bg-gray-dark-4
  `;

  const logoCn = () => cntl`
    w-5
    h-5
    rounded
  `;

  const textCn = () => cntl`
    w-32
    text-sm
    truncate
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-12
  `;

  return (
    <div role="button" className={headerCn()} onClick={onClick}>
      {logo?.buffer ? (
        <LazyLoadImage
          alt="logo"
          className={logoCn()}
          src={`data:image/png;base64,${arrayBufferToBase64(logo?.buffer ?? null)}`}
        />
      ) : null}
      <div className={textCn()}>{text}</div>
    </div>
  );
};

SwitcherDropdown.propTypes = propTypes;
export default SwitcherDropdown;
