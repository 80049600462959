import cntl from "cntl";
import PropTypes from "prop-types";
import Content from "./SectionContent";
import { useMemo, useState } from "react";
import { COLOR, POSITIONS, SIZE } from "../constants";

const propTypes = {
  content: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  theme: PropTypes.oneOf([
    COLOR.dark,
    COLOR.gray,
    COLOR.light,
    COLOR.primary,
    COLOR.primary_light,
  ]),
};

function SectionCTASimpleLogos({ children, theme, content }) {
  const [bgColor, setBgColor] = useState("");

  const wrapperCn = () => cntl`
    ${bgColor}
  `;

  const containerCn = () => cntl`
    px-4
    py-16
    md:px-8
    mx-auto
    md:py-24
    container
  `;

  const contentAndChildrenCn = () => cntl`
    flex
    flex-col
    gap-x-16
    gap-y-12
    md:flex-row
  `;

  useMemo(() => {
    switch (theme) {
      case COLOR.dark:
        setBgColor("bg-gray-light-10 dark:bg-gray-dark-10");
        break;
      case COLOR.gray:
        setBgColor("bg-gray-light-2 dark:bg-gray-dark-2");
        break;
      case COLOR.primary:
        setBgColor("bg-primary-light-10 dark:bg-primary-dark-10");
        break;
      case COLOR.primary_light:
        setBgColor("bg-primary-light-2 dark:bg-primary-dark-2");
        break;
      case COLOR.light:
        setBgColor("bg-white dark:bg-black");
        break;
      default:
        setBgColor("bg-white dark:bg-black");
        break;
    }
  }, [theme]);

  return (
    <div className={wrapperCn()}>
      <div className={containerCn()}>
        <div className={contentAndChildrenCn()}>
          <div className="md:w-1/2">
            <Content
              {...content}
              theme={theme}
              size={SIZE.sm}
              position={POSITIONS.left}
            />
          </div>
          {children && <div className="md:w-1/2">{children}</div>}
        </div>
      </div>
    </div>
  );
}

SectionCTASimpleLogos.prototype = propTypes;
export default SectionCTASimpleLogos;
