import cntl from "cntl";
import PropTypes from "prop-types";
import { FeaturedIcon } from "../atoms";
import { COLOR, HEADER_PRICING, POSITIONS, SIZE } from "../constants";
import BadgeText from "./badge/BadgeText";

const propTypes = {
  badgeLabel: PropTypes.number,
  alignment: PropTypes.string,
  hasFeaturedIcon: PropTypes.bool,
  size: PropTypes.oneOf([SIZE.md, SIZE.lg]),
  variant: PropTypes.oneOf([HEADER_PRICING.with_featured_icon]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  theme: PropTypes.oneOf([COLOR.primary_light, COLOR.gray, COLOR.light]),
};

function HeaderPricing({
  icon,
  theme,
  title,
  subtitle,
  pretitle,
  alignment,
  className,
  badgeLabel,
  hasFeaturedIcon,
}) {
  const style = {
    [COLOR.primary_light]: {
      text: "text-primary-light-11 dark:text-primary-dark-11",
      title: "text-primary-light-12 dark:text-primary-dark-12",
    },
    [COLOR.gray]: {
      text: "text-gray-light-11 dark:text-gray-dark-11",
      title: "text-gray-light-12 dark:text-gray-dark-12",
    },
    [COLOR.light]: {
      text: "text-gray-light-11 dark:text-gray-dark-11",
      title: "text-gray-light-12 dark:text-gray-dark-12",
    },
    default: {
      text: "text-gray-light-11 dark:text-gray-dark-11",
      title: "text-gray-light-12 dark:text-gray-dark-12",
    },
  };

  const container = () => cntl`
    flex
    flex-col
    ${
      alignment === POSITIONS.left
        ? "self-start items-start"
        : alignment === POSITIONS.right
        ? "self-end items-end"
        : "self-center items-center"
    }
    ${className ? className : undefined}
  `;

  const pretitleCn = () => cntl`
    text-xl
    font-semibold	
    text-primary-light-11 dark:text-primary-dark-11
  `;

  const titleCn = () => cntl`
    mb-1
    text-3xl
    font-semibold	
    ${style[theme]?.title || style["default"]?.title}
  `;

  const subtitleCn = () => cntl`
    text-xl
    font-semibold
    ${style[theme]?.title || style["default"]?.title}
  `;

  const titleAndBadgeCn = () => cntl`
    flex
    gap-x-2
    items-center
  `;

  return (
    <div className={container()}>
      {hasFeaturedIcon && icon ? <FeaturedIcon size={SIZE.md} children={icon} /> : <div>{icon}</div>}
      {pretitle?.length && <div className={pretitleCn()}>{pretitle}</div>}
      {title?.length && (
        <div className={titleAndBadgeCn()}>
          <div className={titleCn()}>{title}</div>
          {badgeLabel > 0 && <BadgeText size={SIZE.sm} color={COLOR.success} label={`-${badgeLabel}%`} />}
        </div>
      )}
      {subtitle?.length > 0 && <div className={subtitleCn()}>{subtitle}</div>}
    </div>
  );
}

HeaderPricing.propTypes = propTypes;
export default HeaderPricing;
