import cntl from "cntl";
import PropTypes from "prop-types";
import { IconX } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { MODAL_HEADER_VARIANTS } from "../constants";

const propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  onCloseModal: PropTypes.func,
  hasBorderBottom: PropTypes.bool,
  hasPaddingBottom: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  variant: PropTypes.oneOf([MODAL_HEADER_VARIANTS.left, MODAL_HEADER_VARIANTS.center, MODAL_HEADER_VARIANTS.inline]),
};

function ModalHeader({
  text,
  icon,
  title,
  variant,
  className,
  onCloseModal,
  hasBorderBottom = false,
  hasPaddingBottom = false,
}) {
  const [content, setContent] = useState("");
  const [textAlign, setTextAlign] = useState("");

  const headerCn = () => cntl`
    p-5
    flex
    sm:p-6
    gap-y-4
    flex-col
    ${className ? className : undefined}
    ${hasPaddingBottom ? cntl`pb-5 sm:pb-6` : cntl`pb-0 sm:pb-0`}
    ${hasBorderBottom ? cntl`border-b border-b-gray-light-6 dark:border-b-gray-dark-6` : cntl`border-b-none`}
  `;

  useMemo(() => {
    switch (variant) {
      case MODAL_HEADER_VARIANTS.left:
        setTextAlign("text-left");
        setContent(
          <RenderLeftAndCenterContent
            icon={icon}
            text={text}
            title={title}
            variant={variant}
            textAlign={textAlign}
            onCloseModal={onCloseModal}
          />
        );
        break;
      case MODAL_HEADER_VARIANTS.center:
        setTextAlign("text-center");
        setContent(
          <RenderLeftAndCenterContent
            icon={icon}
            text={text}
            title={title}
            variant={variant}
            textAlign={textAlign}
            onCloseModal={onCloseModal}
          />
        );
        break;
      case MODAL_HEADER_VARIANTS.inline:
        setTextAlign("text-left");
        setContent(
          <RenderInlineContent
            icon={icon}
            text={text}
            title={title}
            variant={variant}
            textAlign={textAlign}
            onCloseModal={onCloseModal}
          />
        );
        break;
      default:
        setTextAlign("text-left");
        setContent(
          <RenderLeftAndCenterContent
            icon={icon}
            text={text}
            title={title}
            textAlign={textAlign}
            onCloseModal={onCloseModal}
          />
        );
        break;
    }
  }, [icon, title, text, textAlign, onCloseModal, variant]);

  return <div className={headerCn()}>{content}</div>;
}

const RenderLeftAndCenterContent = ({ variant, textAlign, onCloseModal, icon, text, title }) => {
  const iconAndCloseIconWrapper = () => cntl`
    flex
    gap-x-4
    flex-row
    items-center
    justify-between
  `;

  const iconCn = () => cntl`
    w-12
    h-12
  `;

  const iconWarningCn = () => cntl`
    w-5
    h-5
    shrink-0
    self-start
  `;

  const titleCn = () => cntl`
    text-lg
    font-semibold
    text-gray-light-12
    first-letter:uppercase
    dark:text-gray-dark-12
  `;

  const textCn = () => cntl`
    text-sm
    text-gray-light-11
    first-letter:uppercase
  `;

  const tlteAndTextWrapper = () => cntl`
    flex
    gap-y-1
    flex-col
    flex-wrap
    ${textAlign}
  `;

  return (
    <>
      <div className={iconAndCloseIconWrapper()}>
        {variant === MODAL_HEADER_VARIANTS.center && <div className={iconCn()} />}
        <div className={iconWarningCn()}>{icon}</div>
        <div className="flex items-center justify-center self-start w-7 h-7">
          <IconX className="cursor-pointer w-6 h-6 shrink-0 text-gray-light-10 dark:text-gray-dark-10" onClick={onCloseModal} />
        </div>
      </div>
      <div className={tlteAndTextWrapper()}>
        <div className={titleCn()}>{title}</div>
        <div className={textCn()}>{text}</div>
      </div>
    </>
  );
};

const RenderInlineContent = ({ icon, title, text, onCloseModal, textAlign }) => {
  const iconAndCloseIconWrapper = () => cntl`
    flex
    gap-x-4
    flex-row
    items-center
    justify-between
  `;

  const iconWarningCn = () => cntl`
    w-5
    h-5
    shrink-0
    self-start
  `;

  const titleCn = () => cntl`
    text-lg
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-12
    first-letter:uppercase
  `;

  const textCn = () => cntl`
    text-sm
    text-gray-light-11
    dark:text-gray-dark-11
    first-letter:uppercase
  `;

  const tlteAndTextWrapper = () => cntl`
    flex
    gap-y-1
    flex-col
    flex-wrap
    ${textAlign}
  `;

  return (
    <>
      <div className={iconAndCloseIconWrapper()}>
        {icon && <div className={iconWarningCn()}>{icon}</div>}
        <div className={tlteAndTextWrapper()}>
          <div className={titleCn()}>{title}</div>
          <div className={textCn()}>{text}</div>
        </div>
        <div className="flex items-center justify-center self-start w-7 h-7">
          <IconX className="cursor-pointer w-6 h-6 shrink-0 text-gray-light-10 dark:text-gray-dark-10" onClick={onCloseModal} />
        </div>
      </div>
    </>
  );
};

ModalHeader.propTypes = propTypes;
export default ModalHeader;
