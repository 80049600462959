// Utils
import PropTypes from "prop-types";

// Hooks
import { useMemo } from "react";

// Context
import { AccordionContext } from "@dbox/core/context/Index";

// Components
import Body from "./Body";
import Header from "./Header";

const propTypes = {
  onToggle: PropTypes.func,
  activeEventKey: PropTypes.array,
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

const Accordion = ({ element: Component, activeEventKey, onToggle, children, ...otherProps }) => {
  const context = useMemo(() => {
    return { activeEventKey, onToggle };
  }, [activeEventKey, onToggle]);

  return (
    <AccordionContext.Provider value={context}>
      <Component {...otherProps}>{children}</Component>
    </AccordionContext.Provider>
  );
};

Accordion.defaultProps = {
  element: "div",
};

Accordion.Body = Body;
Accordion.Header = Header;

Accordion.propTypes = propTypes;
export default Accordion;
