import cntl from "cntl";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { IconCheck, IconCircleCheck } from "@tabler/icons-react";
import { CHECK_ITEM_ICON_VARIANTS, COLOR, SIZE } from "@dbox/components/constants";

const propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.xl, SIZE.lg]),
  color: PropTypes.oneOf([COLOR.success, COLOR.primary, COLOR.dark]),
  variant: PropTypes.oneOf([
    CHECK_ITEM_ICON_VARIANTS.border,
    CHECK_ITEM_ICON_VARIANTS.dark,
    CHECK_ITEM_ICON_VARIANTS.light,
  ]),
};

function CheckItem({ text, size, variant, color }) {
  const [iconSize, setIconSize] = useState("");
  const [textSize, setTextSize] = useState("");
  const [iconStyle, setIconStyle] = useState("");

  const wrapperCn = () => cntl`
    flex 
    w-full 
    gap-x-3
    flex-row 
    justify-start 
  `;

  const iconCn = () => cntl`
    shrink-0
    rounded-full
    ${iconSize}
    ${iconStyle}
  `;

  const textCn = () => cntl`
    ${textSize}
  `;

  useEffect(() => {
    if (variant === CHECK_ITEM_ICON_VARIANTS.border) {
      if (color === COLOR.success) {
        setIconStyle("text-success-light-11 bg-white");
      } else {
        setIconStyle("text-primary-light-11 dark:text-primary-dark-11 bg-white");
      }
    } else if (variant === CHECK_ITEM_ICON_VARIANTS.dark) {
      if (color === COLOR.success) {
        setIconStyle("bg-success-light-11 text-white");
      } else setIconStyle("bg-primary-light-6 text-white");
    } else if (color === COLOR.success) {
      setIconStyle("bg-success-light-3 text-success-light-11");
    } else if (color === COLOR.dark) {
      setIconStyle("text-gray-light-12");
    } else setIconStyle("text-gray-light-11");
  }, [variant, color]);

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setIconSize("w-6 h-6");
        setTextSize("text-base");
        break;
      case SIZE.md:
        setTextSize("text-lg");
        setIconSize("w-7 h-7");
        break;
      case SIZE.lg:
        setTextSize("text-lg");
        setIconSize("w-8 h-8");
        break;
      case SIZE.xl:
        setTextSize("text-xl");
        setIconSize("w-9 h-9");
        break;
      default:
        setIconSize("w-4 h-4");
        setTextSize("text-xs");
        break;
    }
  }, [size]);

  return (
    <div className={wrapperCn()}>
      {variant === CHECK_ITEM_ICON_VARIANTS.border ? (
        <IconCircleCheck className={iconCn()} />
      ) : (
        <IconCheck className={iconCn()} />
      )}
      <div className={textCn()}>{text}</div>
    </div>
  );
}

CheckItem.propTypes = propTypes;
export default CheckItem;
