import cntl from "cntl";
import PropTypes from "prop-types";
import TextLink from "./TextLink";
import { useMemo, useState } from "react";
import { COLOR } from "../constants";

const proptypes = {
  hasBar: PropTypes.bool,
  text: PropTypes.string,
  number: PropTypes.string,
  ctaText: PropTypes.string,
  ctaOnclick: PropTypes.func,
  textCentered: PropTypes.bool,
  theme: PropTypes.oneOf([COLOR.light, COLOR.dark, COLOR.gray, COLOR.primary, COLOR.primary_light]),
};

function MetricItem({ hasBar, text, number, ctaText, ctaOnclick, textCentered, theme }) {

  const [titleColor, setTitleColor] = useState("");
  const [borderColor, setBorderColor] = useState("");

  useMemo(() => {
    switch (theme) {
      case COLOR.gray:
        setTitleColor("text-primary-light-6");
        setBorderColor("border-primary-light-6");
        break;
      case COLOR.primary_light:
        setTitleColor("text-primary-light-6");
        setBorderColor("border-primary-light-6");
        break;
      case COLOR.primary:
        setTitleColor("text-white");
        setBorderColor("border-primary-light-6");
        break;
      case COLOR.dark:
        setTitleColor("text-white");
        setBorderColor("border-gray-light-6")
        break;
      case COLOR.light:
        setTitleColor("text-primary-light-6");
        setBorderColor("border-primary-light-6");
        break;
      default:
        setTitleColor("text-primary-light-6");
        setBorderColor("border-primary-light-6");
        break;
    }
  }, [theme]);

  const wrapperCn = () => cntl`
      w-full
      flex-row
      lg:flex-col
      ${hasBar && borderColor}
      ${hasBar && "border-t-2 lg:border-t-0 lg:border-l-2"}
      ${textCentered ? "justify-center" : "pt-4 lg:pt-0 lg:pl-6"}
    `;

  const contentCn = () => cntl`
      flex
      flex-col
      gap-1
    `;

  const titleCn = () => cntl`
      font-semibold
      text-6xl
      ${titleColor}
    `;

  const textCn = () => cntl`
      font-medium
      text-lg
    `;

  return (
    <div className={wrapperCn()}>
      <div className={contentCn()}>
        {number && <div className={titleCn()}>{number}</div>}
        <div className={textCn()}>{text}</div>
      </div>
     <TextLink label={ctaText} to={null} />
    </div>
  );
}

MetricItem.prototype = proptypes;
export default MetricItem;
