import cntl from "cntl";
import PropTypes from "prop-types";
import { ButtonGrey } from "../../atoms";
import { BUTTON_VARIANTS } from "../../constants";
import { IconLayoutSidebar } from "@tabler/icons-react";

const propTypes = {
  id: PropTypes.string,
  isRight: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

function ButtonToggle({ id, isRight = false, onClick, className }) {
  const mobilePreHeaderCn = () => cntl`
    ${className ? className : undefined}
  `;

  const iconRightCn = () => cntl`
    rotate-180
  `;

  return (
    <div id={id} className={mobilePreHeaderCn()}>
      <ButtonGrey
        onClick={onClick}
        variant={BUTTON_VARIANTS.ghost}
        icon={!isRight ? <IconLayoutSidebar /> : <IconLayoutSidebar className={iconRightCn()} />}
      />
    </div>
  );
}

ButtonToggle.propTypes = propTypes;
export default ButtonToggle;
