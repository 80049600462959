import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneElement, forwardRef } from "react";
import { IconLoader2 } from "@tabler/icons-react";
import { SIZE, POSITIONS } from "../../constants/index";

const propTypes = {
  icon: PropTypes.object,
  type: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  classNameLoader: PropTypes.string,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm, SIZE.md, SIZE.lg]),
  iconPosition: PropTypes.oneOf([POSITIONS.left, POSITIONS.right]),
};

const Button = forwardRef(function Button(
  {
    icon,
    label,
    onClick,
    className,
    isLoading,
    isDisabled,
    iconPosition,
    size = SIZE.md,
    type = "button",
    classNameLoader,
    ...props
  },
  ref
) {
  const buttonWithLabelCn = () => cntl`
    ${styleWithLabel[size]?.container?.size}
    ${styleWithLabel[size]?.container?.padding}
    ${styleWithLabel[size]?.container?.rounded}
  `;

  const buttonOnlyIconCn = () => cntl`
    ${styleOnlyIcon[size]?.container?.size}
    ${styleOnlyIcon[size]?.container?.rounded}
  `;

  const buttonCn = () => cntl`
    ${styleWithLabel.base}
    ${className ? className : undefined}
    ${label?.length > 0 ? buttonWithLabelCn() : buttonOnlyIconCn()}
    ${isLoading ? cntl`pointer-events-none` : cntl`pointer-events-auto`}
  `;

  const iconSize = () => cntl`
    ${styleOnlyIcon[size]?.icon?.size}
  `;

  const labelCn = () => cntl` 
    first-letter:uppercase
    ${styleWithLabel[size]?.label?.font}
    ${styleWithLabel[size]?.label?.padding}
  `;

  const styleWithLabel = {
    base: `flex items-center justify-center ${iconPosition === POSITIONS.left ? `flex-row` : `flex-row-reverse`}`,
    [SIZE.xs]: {
      container: {
        size: "h-6",
        padding: "px-2",
        rounded: "rounded-0.75",
      },
      label: {
        padding: "px-1",
        font: "font-medium text-xs",
      },
    },
    [SIZE.sm]: {
      container: {
        size: "h-8",
        padding: "px-3",
        rounded: "rounded",
      },
      label: {
        padding: "px-1",
        font: "font-medium text-sm",
      },
    },
    [SIZE.md]: {
      container: {
        size: "h-10",
        padding: "px-4",
        rounded: "rounded-md",
      },
      label: {
        padding: "px-2",
        font: "font-medium text-base -tracking-md",
      },
    },
    [SIZE.lg]: {
      container: {
        size: "h-12",
        padding: "px-6",
        rounded: "rounded-lg",
      },
      label: {
        padding: "px-2",
        font: "font-medium text-lg leading-6.5 -tracking-lg",
      },
    },
  };

  const styleOnlyIcon = {
    [SIZE.xs]: {
      container: {
        size: "w-6 h-6",
        rounded: "rounded-0.75",
      },
      icon: {
        size: "w-4 h-4",
      },
    },
    [SIZE.sm]: {
      container: {
        size: "w-8 h-8",
        rounded: "rounded",
      },
      icon: {
        size: "w-4 h-4",
      },
    },
    [SIZE.md]: {
      container: {
        size: "w-10 h-10",
        rounded: "rounded-md",
      },
      icon: {
        size: "w-4.5 h-4.5",
      },
    },
    [SIZE.lg]: {
      container: {
        size: "w-12 h-12",
        rounded: "rounded-lg",
      },
      icon: {
        size: "w-5 h-5",
      },
    },
  };

  return (
    <button
      ref={ref}
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      className={buttonCn()}
      {...props}
    >
      {!isLoading ? (
        <>
          {icon
            ? cloneElement(icon, {
                className: `${icon.props.className} ${iconSize()}`,
              })
            : null}
          {label?.length > 0 ? <span className={labelCn()}>{label}</span> : null}
        </>
      ) : (
        <IconLoader2 className={`animate-spin ${classNameLoader ? classNameLoader : undefined}`} />
      )}
    </button>
  );
});

Button.propTypes = propTypes;
export default Button;
