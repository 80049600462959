import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useMemo, useState } from "react";

const propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
};

function TagCount({ size, className, label }) {
  const [height, setHeigth] = useState("");
  const [fontStyle, setFontStyle] = useState("");

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setHeigth("w-4 h-4");
        setFontStyle("text-xs");
        break;
      case SIZE.md:
        setFontStyle("text-sm");
        setHeigth("w-[1.125rem] h-[1.125rem]");
        break;
      case SIZE.lg:
        setHeigth("w-5 h-5");
        setFontStyle("text-sm");
        break;
      default:
        setHeigth("w-4 h-4");
        setFontStyle("text-xs");
        break;
    }
  }, [size]);

  const wrappercntl = () => cntl`
    flex-row
    inline-flex
    font-medium
    bg-gray-light-3
    items-center
    rounded-[3px]
    justify-center
    text-gray-light-11
    ${height}
    ${fontStyle}
    ${className ? className : undefined}
  `;

  return <div className={wrappercntl()}>{label}</div>;
}

TagCount.prototype = propTypes;
export default TagCount;
