import Input from "./Input";
import PropTypes from "prop-types";
import { isNaN, uniqueId } from "lodash";
import { IconCalendar } from "@tabler/icons-react";

const propTypes = {
  onChange: PropTypes.func,
};

function InputPicker({ onChange, ...props }) {
  const id = uniqueId("input-picker-native-");

  const onDateChange = (e) => {
    const date = new Date(e.target.value);
    const parseDate = Date.parse(date);

    
    if (onChange && !isNaN(parseDate)) {
      onChange(date);
    }
  };

  const onClick = (e) => {
    const input = document.getElementById(id);
    input.showPicker();
  };

  return <Input {...props} id={id} type="date" onChange={onDateChange} iconLeft={<IconCalendar onClick={onClick} />} />;
}

InputPicker.propTypes = propTypes;
export default InputPicker;
