import { SIZE } from "../constants";
import { useTranslation } from "react-i18next";

function Divider({ title, marginY, size, className }) {
  const { t } = useTranslation();

  const borderWidth = {
    [SIZE.xs]: "border-t",
    [SIZE.sm]: "border-t-4",
    [SIZE.lg]: "border-t-8",
  };

  return (
    <div className={`flex items-center ${marginY ? marginY : `my-6`} ${className ? className : undefined}`}>
      <div className="flex-1">
        <hr className={`border-gray-lightAlpha-6 dark:border-gray-darkAlpha-6 ${borderWidth[size] || borderWidth[SIZE.xs]}`} />
      </div>
      {title && (
        <div className="flex-none px-4">
          <p className={`first-letter:uppercase font-medium text-sm text-gray-lightAlpha-11 dark:text-gray-darkAlpha-11`}>
            {t(title)}
          </p>
        </div>
      )}
      <div className="flex-1">
        <hr className={`border-gray-lightAlpha-6 dark:border-gray-darkAlpha-6 ${borderWidth[size] || borderWidth[SIZE.xs]}`} />
      </div>
    </div>
  );
}

export default Divider;
