import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { useMemo, useState } from "react";
import { IconX } from "@tabler/icons-react";

const propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
};

function TagClose({ size, className, onClick }) {
  const [iconSize, setIconSize] = useState("");

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setIconSize("w-3.5 h-3.5");
        break;
      case SIZE.md:
        setIconSize("w-4 h-4");
        break;
      case SIZE.lg:
        setIconSize("w-5 h-5");
        break;
      default:
        setIconSize("w-3.5 h-3.5");
        break;
    }
  }, [size]);

  const wrapperIconCn = () => cntl`
    inline-flex
    rounded-[3px]
    focus:outline
    focus:outline-4
    hover:bg-gray-light-3
    focus:outline-gray-light-3
    ${iconSize}
    ${className ? className : undefined}
  `;

  const iconCn = () => cntl`
    w-full
    h-full
    text-gray-light-10
    hover:text-gray-light-11
    focus:text-gray-light-10
  `;

  return (
    <div tabIndex="0" className={wrapperIconCn()} onClick={onClick}>
      <IconX className={iconCn()} />
    </div>
  );
}

TagClose.prototype = propTypes;
export default TagClose;
