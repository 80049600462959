import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const propTypes = {
  logo: PropTypes.object,
  image: PropTypes.object,
  minWidth: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function MinimumResolutionWrapper({ children, logo, image, minWidth = 768 }) {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);

  const isResolutionBelowSm = width < minWidth;
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  if (isResolutionBelowSm) {
    return (
      <div className="h-screen flex flex-col items-center justify-center mx-auto max-w-xs sm:max-w-md">
        <div className="my-8">{logo}</div>
        <div className="my-16 text-center">
          <div className="text-2xl text-gray-light-12 dark:text-gray-dark-12 font-semibold first-letter:uppercase mb-6">
            {t("min_resolution.title")}
          </div>
          <div className="text-base text-gray-light-11 dark:text-gray-dark-11 first-letter:uppercase">
            {t("min_resolution.text")}
          </div>
        </div>
        <div className="w-full flex justify-center">{image && image}</div>
      </div>
    );
  }
  return children;
}

MinimumResolutionWrapper.propTypes = propTypes;
export default MinimumResolutionWrapper;
