// Utils
import cntl from "cntl";
import { useState } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";

// Constants
import { NOTIFICATION_TYPES, NOTIFICATION_VARIANTS, SIZE } from "../../constants";

// Icons
import { IconCopy, IconCopyCheck } from "@tabler/icons-react";

// Hooks
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Notification
import { openNotification } from "@dbox/core/actions/common/ui";

const propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf([SIZE.sm]),
};

const CopyToClipboard = ({ text, size = SIZE.sm }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboardCn = () => cntl`
    flex
    border
    gap-x-2
    items-center
    bg-gray-lightAlpha-3
    dark:bg-gray-darkAlpha-3
    border-gray-lightAlpha-4
    dark:border-gray-darkAlpha-4
    ${style[size].height}
    ${style[size].padding}
    ${style[size].rounded}
  `;

  const textCn = () => cntl`
    font-semibold
    ${style[size].font}
  `;

  const iconCn = () => cntl`
    cursor-pointer
    text-gray-light-9
    dark:text-gray-dark-9
    ${style[size].icon}
  `;

  const style = {
    [SIZE.sm]: {
      height: "h-8",
      padding: "px-3",
      icon: "w-5 h-5",
      rounded: "rounded",
      font: "text-base leading-5 text-gray-light-12 dark:text-gray-dark-12",
    },
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    }
  }

  const onClick = () => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => {
        dispatch(
          openNotification({
            title: t("notification.error.500.title.server_error"),
            message: t("notification.error.500.message.server_error"),
            timeStamp: format(new Date(), "iii ii, hh:mm:ss"),
            type: NOTIFICATION_TYPES.error,
            variant: NOTIFICATION_VARIANTS.toast,
          })
        );
      });
  };

  return (
    <div className={copyToClipboardCn()}>
      <span className={textCn()}>{text}</span>
      <span onClick={onClick}>
        {!isCopied ? <IconCopy className={iconCn()} /> : <IconCopyCheck className={iconCn()} />}
      </span>
    </div>
  );
};

CopyToClipboard.propTypes = propTypes;
export default CopyToClipboard;
