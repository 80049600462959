import PropTypes from "prop-types";
import { cloneElement, forwardRef } from "react";

const propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

const Trigger = forwardRef(function Trigger({ size, isDisabled, onClick, children, onChange }, ref) {
  return (
    <>
      {children
        ? cloneElement(children, {
            ref,
            size,
            onClick,
            onChange,
            isDisabled
          })
        : null}
    </>
  );
});

Trigger.propTypes = propTypes;
export default Trigger;
