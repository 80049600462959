import cntl from "cntl";
import PropTypes from "prop-types";

const propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
};

function Item({ text, value, disabled, onSelect }) {
  const textValue = () => cntl`
    text-sm
    font-medium
    ${disabled ? `text-gray-light-7 pointer-events-none` : `text-gray-light-11`}
  `;

  const itemCn = () => cntl`
    h-8
    px-2
    flex
    rounded-md
    items-center
    cursor-pointer
    hover:bg-gray-light-4
    dark:hover:bg-gray-dark-4
    ${textValue()}
  `;

  const handleItemClick = () => {
    if (value) {
      onSelect(value);
    }
  };

  return (
    <div className={itemCn()} onClick={handleItemClick}>
      {text}
    </div>
  );
}

Item.propTypes = propTypes;
export default Item;
