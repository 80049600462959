// Utils
import cntl from "cntl";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";

// Components
import { SKELETON_VARIANTS } from "@dbox/components/constants";

const propTypes = {
  numbOfRow: PropTypes.number,
  className: PropTypes.string,
  numbOfCards: PropTypes.number,
  variant: PropTypes.oneOf([
    SKELETON_VARIANTS.row,
    SKELETON_VARIANTS.card,
    SKELETON_VARIANTS.table,
    SKELETON_VARIANTS.rectangle,
  ]),
};

function Skeleton({ variant, className, numbOfCards, numbOfRow }) {
  if (variant === SKELETON_VARIANTS.table) {
    return <SkeletonTable />;
  } else if (variant === SKELETON_VARIANTS.card) {
    return <SkeletonCard numbOfCards={numbOfCards} />;
  } else if (variant === SKELETON_VARIANTS.row) {
    return <SkeletonRow className={className} numbOfRow={numbOfRow} />;
  } else if (variant === SKELETON_VARIANTS.rectangle) {
    return <SkeletonRectangle className={className} />;
  } else {
    return;
  }
}

const SkeletonTable = () => {
  const skeletonTableCn = () => cntl`
    flex
    w-full
    flex-col
    animate-pulse
    max-h-[24rem]
  `;

  const headerCn = () => cntl`
    flex
    h-12
    px-14
    items-center
    justify-between
    bg-gray-light-3
    dark:bg-gray-dark-3
  `;

  const grayBlockCn = () => cntl`
    flex
    gap-x-7
    items-center
  `;

  const grayBoxCn = () => cntl`
    h-4
    w-16
    rounded-sm
    bg-gray-light-8
    dark:bg-gray-dark-8
  `;

  const rowCn = () => cntl`
    h-12
    border-b
    border-b-gray-light-6
    dark:border-b-gray-dark-6
  `;

  return (
    <div className={skeletonTableCn()}>
      <div className={headerCn()}>
        <div className={grayBlockCn()}>
          <div className={grayBoxCn()} />
          <div className={grayBoxCn()} />
        </div>
        <div className={grayBlockCn()}>
          <div className={grayBoxCn()} />
          <div className={grayBoxCn()} />
        </div>
        <div className={grayBlockCn()}>
          <div className={grayBoxCn()} />
          <div className={grayBoxCn()} />
        </div>
      </div>
      <div className={rowCn()} />
      <div className={rowCn()} />
      <div className={rowCn()} />
      <div className={rowCn()} />
      <div className={rowCn()} />
    </div>
  );
};

const SkeletonCard = ({ numbOfCards = 1 }) => {
  const skeletonCardCn = () => cntl`
    h-80
    w-full
    animate-pulse
    bg-gray-light-3
    dark:bg-gray-dark-3
  `;

  return (
    <>
      {Array.from({ length: numbOfCards }, (_, i) => i + 1)?.map(() => (
        <div key={uniqueId("skeleton-card-")} className={skeletonCardCn()} />
      ))}
    </>
  );
};

const SkeletonRectangle = ({ className }) => {
  const skeletonRectangleCn = () => cntl`
    h-80
    w-full
    animate-pulse
    bg-gray-light-3
    dark:bg-gray-dark-3
    ${className ? className : undefined}
  `;

  return <div className={skeletonRectangleCn()} />;
};

const SkeletonRow = ({ className, numbOfRow = 1 }) => {
  const rows = Array.from({ length: numbOfRow }, (_, i) => i + 1);

  const skeletonRowCn = () => cntl`
    flex
    gap-8
    flex-col
  `;

  const rowCn = () => cntl`
    animate-pulse
    bg-gray-light-3
    dark:bg-gray-dark-3
    ${className ? className : undefined}
  `;

  return (
    <div className={skeletonRowCn()}>
      {rows.map((_) => {
        return <div key={uniqueId("skeleton-row-")} className={rowCn()} />;
      })}
    </div>
  );
};

Skeleton.propTypes = propTypes;
export default Skeleton;
