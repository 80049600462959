import cntl from "cntl";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { AVATAR_VARIANT, SIZE } from "../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { arrayBufferToBase64 } from "@dbox/core/utils/arrayBufferToBase64";

const propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  letters: PropTypes.string,
  className: PropTypes.string,
  supportingText: PropTypes.string,
  picture: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  variant: PropTypes.oneOf([AVATAR_VARIANT.rounded, AVATAR_VARIANT.square]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.oneOf([SIZE.xxs, SIZE.xs, SIZE.sm, SIZE.md, SIZE.base, SIZE.lg, SIZE.xl, SIZE["2xl"], SIZE["3xl"]]),
};

const Avatar = forwardRef(
  (
    {
      text,
      picture,
      letters,
      onClick,
      children,
      className,
      supportingText,
      size = SIZE.base,
      variant = AVATAR_VARIANT.rounded,
    },
    ref
  ) => {
    const pictureCn = () => cntl`
      overflow-hidden
      ${variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded-0.75"}
    `;

    const lettersCn = () => cntl`
      text-center
      font-medium
      text-gray-lightAlpha-11
      dark:text-gray-darkAlpha-11
      ${style[size]?.letters?.fontSize}
    `;

    const childrenCn = () => cntl`
      right-0
      absolute
      bottom-0
    `;

    const textAndSupportingTextCn = () => cntl`
      w-48
      flex
      flex-col
    `;

    const textCn = () => cntl`
      truncate
      text-left
      font-semibold
      text-gray-light-12
      dark:text-gray-dark-12
      ${style[size]?.text?.fontSize}
    `;

    const supportingTextCn = () => cntl`
      truncate
      text-left
      text-gray-light-11
      dark:text-gray-dark-11
      ${style[size]?.supportingText?.fontSize}
    `;

    const avatarCn = () => cntl`
      flex-row
      gap-x-2.5
      inline-flex
      items-center
      ${className ? className : undefined}
    `;

    const imageCn = () => cntl`
      flex
      relative
      items-center
      justify-center
      overflow-visible
      bg-gray-light-3
      dark:bg-gray-dark-3
      ${onClick ? `cursor-pointer` : `cursor-auto`}
      ${style[size]?.avatar?.size}
      ${style[size]?.avatar?.rounded}
      ${variant === AVATAR_VARIANT.square ? `border border-gray-light-6 dark:border-gray-dark-6` : `border-0`}
    `;

    const style = {
      [SIZE.xxs]: {
        avatar: {
          size: "w-4 h-4",
          rounded: variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded-0.75",
        },
        text: {
          fontSize: "text-xs",
        },
        supportingText: {
          fontSize: "text-xs",
        },
      },
      [SIZE.xs]: {
        avatar: {
          size: "w-5 h-5",
          rounded: variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded",
        },
        text: {
          fontSize: "text-sm",
        },
        supportingText: {
          fontSize: "text-xs",
        },
      },
      [SIZE.sm]: {
        avatar: {
          size: "w-6 h-6",
          rounded: variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded",
        },
        text: {
          fontSize: "text-sm",
        },
        supportingText: {
          fontSize: "text-xs",
        },
      },
      [SIZE.md]: {
        avatar: {
          size: "w-7 h-7",
          rounded: variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded",
        },
        text: {
          fontSize: "text-sm",
        },
        supportingText: {
          fontSize: "text-xs",
        },
      },
      [SIZE.base]: {
        avatar: {
          size: "w-8 h-8",
          rounded: variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded",
        },
        text: {
          fontSize: "text-sm",
        },
        supportingText: {
          fontSize: "text-xs",
        },
      },
      [SIZE.lg]: {
        avatar: {
          size: "w-10 h-10",
          rounded: variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded-md",
        },
        text: {
          fontSize: "text-base",
        },
        supportingText: {
          fontSize: "text-xs",
        },
      },
      [SIZE.xl]: {
        avatar: {
          size: "w-12 h-12",
          rounded: variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded-md",
        },
        text: {
          fontSize: "text-base",
        },
        supportingText: {
          fontSize: "text-sm",
        },
      },
      [SIZE["2xl"]]: {
        avatar: {
          size: "w-16 h-16",
          rounded: variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded-lg",
        },
        text: {
          fontSize: "text-base",
        },
        supportingText: {
          fontSize: "text-sm",
        },
      },
      [SIZE["3xl"]]: {
        avatar: {
          size: "w-20 h-20",
          rounded: variant === AVATAR_VARIANT.rounded ? "rounded-full" : "rounded-lg",
        },
        text: {
          fontSize: "text-base",
        },
        supportingText: {
          fontSize: "text-sm",
        },
        letters: {
          fontSize: "text-7xl",
        },
      },
    };

    return (
      <div ref={ref} className={avatarCn()}>
        <div id="avatar" onClick={onClick} className={imageCn()}>
          {picture?.buffer ? (
            <LazyLoadImage
              alt="image-avatar"
              className={pictureCn()}
              src={`data:image/png;base64,${arrayBufferToBase64(picture?.buffer ?? null)}`}
            />
          ) : letters ? (
            <div className={lettersCn()}>{letters}</div>
          ) : null}
          {children ? <div className={childrenCn()}>{children}</div> : null}
        </div>
        {text?.length > 0 || supportingText?.length > 0 ? (
          <div className={textAndSupportingTextCn()}>
            {text?.length > 0 ? <div className={textCn()}>{text}</div> : null}
            {supportingText?.length > 0 ? <div className={supportingTextCn()}>{supportingText}</div> : null}
          </div>
        ) : null}
      </div>
    );
  }
);

Avatar.propTypes = propTypes;
export default Avatar;
