import cntl from "cntl";
import PropTypes from "prop-types";
import { FeaturedIcon } from "../atoms";
import { useMemo, useState } from "react";
import { COLOR, FEATURED_ICON_VARIANTS } from "../constants";

const propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  image: PropTypes.object,
  preTitle: PropTypes.string,
  description: PropTypes.string,
  bgColorUpper: PropTypes.oneOf[(COLOR.light, COLOR.primary)],
  bgColorLower: PropTypes.oneOf[(COLOR.light, COLOR.primary)],
};

function FeatureIconsAndImages03({ items, title, description, preTitle, image, bgColorUpper, bgColorLower }) {
  const [textColor, setTextColor] = useState();
  const [iconColor, setIconColor] = useState();
  const [titleColor, setTitleColor] = useState();
  const [preTitleColor, setPreTitleColor] = useState();

  const wrapperCn = () => cntl`
    flex
    flex-col
    justify-center
  `;

  const upperContenCn = () => cntl`
    px-4
    pt-16
    pb-28
    container
    mx-auto
    flex
    flex-col
    gap-y-12
    justify-center
    md:px-8
    lg:pb-40
    lg:pt-24
    lg:flex-row
    lg:gap-x-24
    lg:items-start
    ${bgColorUpper === COLOR.primary ? "bg-primary-light-10 dark:bg-primary-dark-10" : "bg-white dark:bg-black"}
  `;

  const lowerContenCn = () => cntl`
    flex
    pb-16
    flex-row
    lg:px-20
    lg:pb-24
    justify-center
    ${bgColorLower === COLOR.primary ? "bg-primary-light-10 dark:bg-primary-dark-10" : "bg-white dark:bg-black"}
  `;

  const textWrapperCn = () => cntl`
    flex
    gap-y-3
    flex-col
    justify-center
  `;

  const preTitleCn = () => cntl`
    font-semibold
    ${preTitleColor}
  `;

  const titleCn = () => cntl`
    text-4xl
    font-semibold
    ${titleColor}
  `;

  const descriptionCn = () => cntl`
    text-xl
    ${textColor}
  `;

  const itemsWrapperCn = () => cntl`
    flex
    flex-col
    gap-y-10
    lg:gap-y-12
  `;

  const itemCn = () => cntl`
    flex
    pt-1.5
    gap-x-4
    flex-row
    lg:pt-2.5
    items-start
  `;

  const itemContentCn = () => cntl`
    flex
    gap-x-1
    flex-col
    lg:gap-x-2
  `;

  const imageCn = () => cntl`
    px-4
    -mt-16
    lg:px-0
    lg:-mt-24
    rounded-lg
  `;

  const itemTitleCn = () => cntl`
    text-xl
    font-semibold
    ${titleColor}
  `;

  const itemDescriptionCn = () => cntl`
    ${textColor}
  `;

  useMemo(() => {
    switch (bgColorUpper) {
      case COLOR.primary:
        setTitleColor("text-white dark:text-black");
        setIconColor(COLOR.primary);
        setTextColor("text-primary-light-8 dark:text-primary-dark-8");
        setPreTitleColor("text-primary-light-8 dark:text-primary-dark-8");
        break;
      case COLOR.light:
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        setIconColor(COLOR.primary_light);
        setPreTitleColor("text-primary-light-11 dark:text-primary-dark-11");
        break;
      default:
        setTextColor("text-gray-light-11 dark:text-gray-dark-11");
        setTitleColor("text-gray-light-12 dark:text-gray-dark-12");
        setIconColor(COLOR.primary_light);
        break;
    }
  }, [bgColorUpper]);

  return (
    <div className={wrapperCn()}>
      <div className={upperContenCn()}>
        <div className={textWrapperCn()}>
          <div className={preTitleCn()}>{preTitle}</div>
          <div className={titleCn()}>{title}</div>
          <div className={descriptionCn()}>{description}</div>
        </div>
        <div className={itemsWrapperCn()}>
          {items?.map((item, index) => {
            return (
              <div key={`item-${index}`} className={itemCn()}>
                <FeaturedIcon color={iconColor} variant={FEATURED_ICON_VARIANTS.light_circle_outline}>
                  {item.icon}
                </FeaturedIcon>
                <div className={itemContentCn()}>
                  <div className={itemTitleCn()}>{item.title}</div>
                  <div className={itemDescriptionCn()}>{item.text}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={lowerContenCn()}>
        <div className={imageCn()}>{image}</div>
      </div>
    </div>
  );
}

FeatureIconsAndImages03.prototype = propTypes;
export default FeatureIconsAndImages03;
