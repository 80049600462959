import { get } from "lodash";
import { httpClient } from "../../httpClient";

export function authRequire(email, password) {
  return httpClient.post(`/auth/require`, {
    email,
    password,
  });
}

export function resendCodeEmail(email) {
  return httpClient.post(`/auth/require/resend`, {
    email,
  });
}

export function signIn(email, two_factors_auth_token) {
  return httpClient.post(`/auth/login`, {
    email,
    two_factors_auth_token,
  });
}

export function signUp(body) {
  return httpClient.post(`/auth/register`, body);
}

export function refresh() {
  return httpClient.post(`/auth/refresh`);
}

export function logOut() {
  return httpClient.get(`/auth/logout`);
}

export async function updatePassword(values) {
  try {
    const res = await httpClient.post(`/auth/password/reset`, values);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function sendResetPasswordMail(values) {
  try {
    const res = await httpClient.post(`/auth/forgot/password`, values);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}

export async function resetPassword(values) {
  try {
    const res = await httpClient.post(`/auth/forgot/password/reset`, values);
    return get(res, "data", null);
  } catch (err) {
    throw err;
  }
}
