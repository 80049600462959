import cntl from "cntl";
import { Avatar } from "../atoms";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import FileUpload from "./FileUpload";
import React, { useEffect, useState } from "react";

const propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
};

function FileUploadWithPreview({ onChange, className, ...props }) {
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [fileToLoad, setFileToLoad] = useState([]);

  const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

  const wrapperCn = () => cntl`
    flex
    w-full
    gap-x-8
    flex-row
  `;

  const previewCn = () => cntl`
    w-16
    h-16
    rounded-full
  `;

  const resetImage = () => {
    setImages([]);
    setImageFiles([]);
    setFileToLoad([]);
  };

  const handleChange = (fileToUpload) => {
    resetImage();
  
    if (fileToUpload) {
      setFileToLoad(fileToUpload);
      // To preview image
      fileToUpload?.forEach((file) => {
        if (file?.type?.match(imageTypeRegex)) {
          setImageFiles((prev) => [...prev, file]);
        } else {
          setImageFiles([]);
        }
      });
    }
  };

  useEffect(() => {
    const images = [];
    let isCancel = false;
    const fileReaders = [];

    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);

        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            images.push(result);
          }
          if (images.length === imageFiles.length && !isCancel) {
            setImages(images);
          }
        };

        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;

      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles]);

  useEffect(() => {
    window.addEventListener("reset", resetImage, true);

    return () => {
      window.removeEventListener("reset", resetImage, true);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("submit", () => onChange(fileToLoad), true);

    return () => {
      window.removeEventListener("submit", () => onChange(fileToLoad), true);
    };
  }, [onChange, fileToLoad]);

  return (
    <div className={wrapperCn()}>
      {images?.length > 0 ? (
        <img alt="preview" src={images[0]} className={previewCn()} />
      ) : (
        <Avatar className="self-start" size={SIZE["2xl"]} picture={props?.picture} />
      )}
      <FileUpload {...props} onChange={handleChange} />
    </div>
  );
}

FileUploadWithPreview.propTypes = propTypes;
export default FileUploadWithPreview;
