import cntl from "cntl";
import { get } from "lodash";
import PropTypes from "prop-types";
import { INPUT_VARIANTS, SIZE } from "../constants";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useState, cloneElement, forwardRef } from "react";

const propTypes = {
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  role: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  isDisabled: PropTypes.bool,
  dataType: PropTypes.string,
  isPassword: PropTypes.bool,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  className: PropTypes.string,
  textHelper: PropTypes.string,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  formRegistration: PropTypes.object,
  variant: PropTypes.oneOf([INPUT_VARIANTS.surface]),
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

const Input = forwardRef(function Input(
  {
    id,
    min,
    max,
    role,
    name,
    step,
    label,
    value,
    onBlur,
    errors,
    onClick,
    inputRef,
    datatype,
    iconLeft,
    onChange,
    iconRight,
    onKeyDown,
    className,
    textHelper,
    isPassword,
    isDisabled,
    placeholder,
    prefix = "",
    suffix = "",
    type = "text",
    onInputChange,
    size = SIZE.lg,
    showErrorMessage = true,
    formRegistration = null,
    variant = INPUT_VARIANTS.surface,
  },
  ref
) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { onChange: onFormChange, onBlur: onFormBlur, name: formName, ...rest } = formRegistration || {};

  const computedId = id || formName;
  const computedName = name || formName;
  const errorMessage = get(errors, `[${formName}].message`, "");

  const wrapperCn = () => cntl`
    flex
    flex-col
    select-wrapper
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    mb-1
    text-sm
    text-gray-lightAlpha-12
    first-letter:uppercase
    dark:text-gray-darkAlpha-12
  `;

  const inputCn = ({ hasErrors }) => cntl`
    py-0
    w-full
    border
    form-input
    focus:ring-0
    focus:ring-transparent
    focus:outline-0
    focus:outline-none
    focus:outline-transparent
    ${styleBase[size]?.container?.size}
    ${styleBase[size]?.text?.fontStyle}
    ${styleBase[size]?.icon?.paddingLeft}
    ${styleBase[size]?.container?.rounded}
    ${styleBase[size]?.icon?.paddingRight}
    ${hasErrors ? styleVariant[variant]?.style?.error : styleVariant[variant]?.style?.default}
  `;

  const iconCn = () => cntl`
    z-[1]
    top-1/2
    absolute
    -translate-y-1/2
    text-gray-lightAlpha-10
    dark:text-gray-darkAlpha-10
    disabled:text-gray-lightAlpha-7
    dark:disabled:text-gray-darkAlpha-7
    ${styleBase[size]?.icon?.size}
  `;

  const iconLeftCn = () => cntl`
    left-2
    ${iconCn()}
  `;

  const iconRightCn = ({ hasError }) => cntl`
    right-2
    ${iconCn()}
    ${hasError ? cntl`text-error-lightAlpha-9 dark:text-error-darkAlpha-9` : undefined}
  `;

  const iconPasswordCn = () => cntl`
    right-2
    cursor-pointer
    ${iconCn()}
  `;

  const textHelpCn = () => cntl`
    mb-1
    text-sm
    first-letter:uppercase
    text-gray-lightAlpha-11
    dark_text-gray-darkAlpha-11
  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1
    text-sm
    first-letter:uppercase
    text-error-lightAlpha-11
    dark:text-error-darkAlpha-11
    ${!hasErrors && cntl`invisible`}
  `;

  const surfaceVariantBase = () => cntl`
    bg-white/[.9]
    text-gray-light-12
    dark:bg-black/[.25]
    dark:text-gray-dark-12
    disabled:bg-gray-lightAlpha-3
    dark:disabled:bg-gray-darkAlpha-3
    placeholder:text-gray-lightAlpha-10
    dark:placeholder:text-gray-darkAlpha-10
  `;

  const surfaceVariantDefault = () => cntl`
    border-gray-light-6
    dark:border-gray-dark-6
    focus:border-primary-lightAlpha-8
    dark:focus:border-primary-darkAlpha-8
    ${surfaceVariantBase()}
  `;

  const surfaceVariantError = () => cntl`
    border-error-lightAlpha-8
    dark:border-error-darkAlpha-8
    focus:border-error-lightAlpha-8
    dark:focus:border-error-darkAlpha-8
  `;

  const prefixAndsuffixCn = ({ isSuffix }) => cntl`
    border
    ${isSuffix ? `border-l-0` : `border-r-0`}
    ${styleBase[size]?.container?.size}
    ${styleBase[size]?.text?.fontStyle}
    ${styleVariant[variant]?.style?.default}
    ${isSuffix ? styleBase[size]?.container?.roundedSuffix : styleBase[size]?.container?.roundedPrefix}
  `;

  const styleBase = {
    [SIZE.sm]: {
      container: {
        size: "h-6",
        rounded:
          prefix && suffix ? "rounded-0" : prefix ? "rounded-r-0.75" : suffix ? "rounded-l-0.75" : "rounded-0.75",
        roundedSuffix: suffix ? "rounded-r-0.75" : "",
        roundedPrefix: suffix ? "rounded-l-0.75" : "",
      },
      text: {
        fontStyle: "text-xs",
      },
      icon: {
        size: "w-4 h-4",
        paddingLeft: iconLeft ? "pl-7" : "px-2",
        paddingRight: iconRight || isPassword ? "pr-7" : "px-2",
      },
    },
    [SIZE.md]: {
      container: {
        size: "h-8",
        rounded: prefix && suffix ? "rounded-0" : prefix ? "rounded-r" : suffix ? "rounded-l" : "rounded",
        roundedSuffix: suffix ? "rounded-r" : "",
        roundedPrefix: suffix ? "rounded-l" : "",
      },
      text: {
        fontStyle: "text-sm",
      },
      icon: {
        size: "w-4 h-4",
        paddingLeft: iconLeft ? "pl-7" : "px-2",
        paddingRight: iconRight || isPassword ? "pr-7" : "px-2",
      },
    },
    [SIZE.lg]: {
      container: {
        size: "h-10",
        rounded: prefix && suffix ? "rounded-0" : prefix ? "rounded-r-md" : suffix ? "rounded-l-md" : "rounded-md",
        roundedSuffix: suffix ? "rounded-r-md" : "",
        roundedPrefix: suffix ? "rounded-l-md" : "",
      },
      text: {
        fontStyle: "text-base",
      },
      icon: {
        size: "w-5 h-5",
        paddingLeft: iconLeft ? "pl-8" : "px-2",
        paddingRight: iconRight || isPassword ? "pr-8" : "px-2",
      },
    },
  };

  const styleVariant = {
    [INPUT_VARIANTS.surface]: {
      style: {
        error: surfaceVariantError(),
        default: surfaceVariantDefault(),
      },
    },
  };

  const onTextChange = (event) => {
    const val = event.target.value;
    onInputChange(val);
  };

  const onTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const suffixProps = {
    suffix,
    className: prefixAndsuffixCn({ isSuffix: true }),
  };

  const prefixProps = {
    prefix,
    className: prefixAndsuffixCn({ isSuffix: false }),
  };

  return (
    <div className={wrapperCn()} ref={inputRef}>
      {label?.length > 0 ? <label className={labelCn()}>{label}</label> : null}
      {textHelper?.length > 0 ? (
        <div className={textHelpCn()} data-cypress="form-help-msg">
          {textHelper}
        </div>
      ) : null}
      <div className="flex items-center">
        {prefix ? <Prefix {...prefixProps} /> : null}
        <div className="relative w-full">
          {iconLeft
            ? cloneElement(iconLeft, {
                className: iconLeftCn(),
              })
            : null}
          <input
            ref={ref}
            min={min}
            max={max}
            step={step}
            role={role}
            value={value}
            id={computedId}
            onClick={onClick}
            autoComplete="off"
            name={computedName}
            datatype={datatype}
            onKeyDown={onKeyDown}
            disabled={isDisabled}
            placeholder={placeholder}
            aria-invalid={errorMessage ? "true" : "false"}
            className={inputCn({ hasErrors: !!errorMessage })}
            type={isPassword ? (!isPasswordVisible ? "password" : type) : type}
            onChange={(e) => {
              if (onFormChange) {
                onFormChange(e);
              }
              if (onChange) {
                onChange(e);
              }
              if (onInputChange) {
                onTextChange(e);
              }
            }}
            onBlur={(e) => {
              if (onFormBlur) {
                onFormBlur(e);
              }
              if (onBlur) {
                onBlur(e);
              }
            }}
            {...rest}
          />
          {isPassword && (
            <div className={iconPasswordCn()} onClick={onTogglePasswordVisibility}>
              {!isPasswordVisible ? <IconEyeOff /> : <IconEye />}
            </div>
          )}
          {iconRight &&
            cloneElement(iconRight, {
              className: iconRightCn({ hasErrors: !!errorMessage }),
            })}
        </div>
        {suffix ? <Suffix {...suffixProps} /> : null}
      </div>
      {!!errorMessage && showErrorMessage && (
        <div
          className={errorCn({
            hasErrors: !!errorMessage,
          })}
          data-cypress="form-error-msg"
        >
          {errorMessage ? errorMessage : "Error message"}
        </div>
      )}
    </div>
  );
});

const Suffix = ({ className, suffix }) => {
  const suffixCn = () => cntl`
    p-3
    flex
    items-center
    ${className ? className : undefined}
  `;

  return <div className={suffixCn()}>{suffix}</div>;
};

const Prefix = ({ className, prefix }) => {
  const prefixCn = () => cntl`
    p-3
    flex
    items-center
    ${className ? className : undefined}
  `;

  return <div className={prefixCn()}>{prefix}</div>;
};

Input.propTypes = propTypes;
export default Input;
