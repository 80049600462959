import cntl from "cntl";
import PropTypes from "prop-types";
import { EventEmitter } from "@dbox/core";
import { DIRECTION, SIZE, TAB_BUTTON_VARIANTS } from "../constants";
import { useState, useEffect, Fragment, cloneElement, Children, useCallback } from "react";
import { TabButtonGrey, TabButtonWhite, TabButtonPrimary, TabButtonUnderline, TabButtonUnderlineFilled } from "..";

const propTypes = {
  className: PropTypes.string,
  startIndex: PropTypes.string,
  buttonWidth: PropTypes.string,
  textPreTabs: PropTypes.string,
  classNameNavTab: PropTypes.string,
  classNameWrapper: PropTypes.string,
  navTab: PropTypes.array.isRequired,
  classNameContentTab: PropTypes.string,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm]),
  direction: PropTypes.oneOf([DIRECTION.row, DIRECTION.column]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  variant: PropTypes.oneOf([
    TAB_BUTTON_VARIANTS.gray,
    TAB_BUTTON_VARIANTS.white,
    TAB_BUTTON_VARIANTS.primary,
    TAB_BUTTON_VARIANTS.underline,
    TAB_BUTTON_VARIANTS.underline_filled,
  ]),
};

function Tabs({
  size,
  navTab,
  children,
  className,
  textPreTabs,
  buttonWidth,
  classNameNavTab,
  startIndex = "0",
  classNameWrapper,
  classNameContentTab,
  onChange = () => null,
  direction = DIRECTION.row,
  variant = TAB_BUTTON_VARIANTS.underline,
}) {
  const [runTabs, setRunTabs] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");

  const resetSelectIndex = useCallback(() => {
    setSelectedIndex("0");
  }, []);

  useEffect(() => {
    let resetTabIndex = EventEmitter.on("ResetTabIndex", resetSelectIndex);

    return () => {
      resetTabIndex.off("ResetTabIndex", resetSelectIndex);
    };
  }, [resetSelectIndex]);

  useEffect(() => {
    setSelectedIndex(startIndex);
  }, [startIndex]);

  const cloneElementCn = (index) => cntl`
    ${classNameContentTab ? classNameContentTab : undefined}
    ${selectedIndex === index?.toString() ? `block` : `hidden`}
  `;

  const tabDirection = () => cntl`
    ${direction === DIRECTION.row ? cntl`flex-row` : cntl`flex-col`}
  `;

  const divCn = () => cntl`
    flex
    ${className ? className : undefined}
    ${direction === DIRECTION.row ? cntl`flex-col` : cntl`flex-row`}
  `;

  const gapBetweenTabButton = () => cntl`
    ${variant === TAB_BUTTON_VARIANTS.underline ? cntl`gap-4` : cntl`gap-2`}
  `;

  const borderBottom = () => cntl`
    ${
      direction === DIRECTION.row &&
      (variant === TAB_BUTTON_VARIANTS.underline || variant === TAB_BUTTON_VARIANTS.underline_filled)
        ? cntl`border-b border-b-gray-lightAlpha-6 dark:border-b-gray-darkAlpha-6`
        : undefined
    }
  `;

  const wrapperTextPreTabs = () => cntl`
    flex
    gap-x-8
    text-base
    items-center
    ${borderBottom()}
    ${tabDirection()}
    ${classNameWrapper ? classNameWrapper : undefined}
  `;

  const navCn = () => cntl`
    flex
    hide-scrollbar
    overflow-x-auto
    snap-x snap-mandatory
    ${buttonWidth}
    ${gapBetweenTabButton()}
    ${classNameNavTab ? classNameNavTab : undefined}
  `;

  useEffect(() => {
    if (navTab?.length === children?.length) {
      setRunTabs(true);
    } else {
      setRunTabs(false);
    }
  }, [navTab, children]);

  const onClickTab = (index, dataAttribute) => {
    onChange({ index, dataAttribute });
    setSelectedIndex(index?.toString());
  };

  const renderTabButton = (label, isDisabled, index, children, dataAttribute) => {
    switch (variant) {
      case TAB_BUTTON_VARIANTS.gray:
        return (
          <TabButtonGrey
            size={size}
            label={label}
            id={`tab-${index}`}
            isDisabled={isDisabled}
            className={buttonWidth}
            ariaControls={`panel-${index}`}
            onClick={() => onClickTab(index, dataAttribute)}
            tabIndex={selectedIndex === index ? "0" : "-1"}
            ariaSelected={selectedIndex === index ? "true" : "false"}
          >
            {children}
          </TabButtonGrey>
        );
      case TAB_BUTTON_VARIANTS.white:
        return (
          <TabButtonWhite
            size={size}
            label={label}
            id={`tab-${index}`}
            isDisabled={isDisabled}
            className={buttonWidth}
            ariaControls={`panel-${index}`}
            onClick={() => onClickTab(index, dataAttribute)}
            tabIndex={selectedIndex === index ? "0" : "-1"}
            ariaSelected={selectedIndex === index ? "true" : "false"}
          >
            {children}
          </TabButtonWhite>
        );
      case TAB_BUTTON_VARIANTS.primary:
        return (
          <TabButtonPrimary
            size={size}
            label={label}
            id={`tab-${index}`}
            isDisabled={isDisabled}
            className={buttonWidth}
            ariaControls={`panel-${index}`}
            onClick={() => onClickTab(index, dataAttribute)}
            tabIndex={selectedIndex === index ? "0" : "-1"}
            ariaSelected={selectedIndex === index ? "true" : "false"}
          >
            {children}
          </TabButtonPrimary>
        );
      case TAB_BUTTON_VARIANTS.underline:
        return (
          <TabButtonUnderline
            size={size}
            label={label}
            id={`tab-${index}`}
            isDisabled={isDisabled}
            className={buttonWidth}
            ariaControls={`panel-${index}`}
            onClick={() => onClickTab(index, dataAttribute)}
            tabIndex={selectedIndex === index ? "0" : "-1"}
            ariaSelected={selectedIndex === index ? "true" : "false"}
          >
            {children}
          </TabButtonUnderline>
        );
      case TAB_BUTTON_VARIANTS.underline_filled:
        return (
          <TabButtonUnderlineFilled
            size={size}
            label={label}
            id={`tab-${index}`}
            isDisabled={isDisabled}
            className={buttonWidth}
            ariaControls={`panel-${index}`}
            onClick={() => onClickTab(index, dataAttribute)}
            tabIndex={selectedIndex === index ? "0" : "-1"}
            ariaSelected={selectedIndex === index ? "true" : "false"}
          >
            {children}
          </TabButtonUnderlineFilled>
        );
      default:
        return (
          <TabButtonUnderlineFilled
            size={size}
            label={label}
            id={`tab-${index}`}
            isDisabled={isDisabled}
            className={buttonWidth}
            ariaControls={`panel-${index}`}
            onClick={() => onClickTab(index, dataAttribute)}
            tabIndex={selectedIndex === index ? "0" : "-1"}
            ariaSelected={selectedIndex === index ? "true" : "false"}
          >
            {children}
          </TabButtonUnderlineFilled>
        );
    }
  };

  return (
    <div className={divCn()}>
      {runTabs && (
        <>
          <div className={wrapperTextPreTabs()}>
            {textPreTabs?.length && <div className="first-letter:uppercase">{textPreTabs}</div>}
            <div role="tablist" aria-label="tabs" className={navCn()}>
              {navTab?.map(({ label, isDisabled, children, dataAttribute }, index) => {
                return (
                  <Fragment key={`tab-button-${label}-${index}`}>
                    {renderTabButton(label, isDisabled, index?.toString(), children, dataAttribute)}
                  </Fragment>
                );
              })}
            </div>
          </div>
          {children &&
            Children.map(children, (child, index) => {
              return (
                <Fragment key={`tab-content-${index}`}>
                  {cloneElement(child, {
                    tabIndex: "0",
                    role: "tabpanel",
                    id: `panel-${index}`,
                    "aria-labelledby": `tab-${index}`,
                    className: cloneElementCn(index),
                  })}
                </Fragment>
              );
            })}
        </>
      )}
    </div>
  );
}

Tabs.propTypes = propTypes;
export default Tabs;
