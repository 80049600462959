// Utils
import PropTypes from "prop-types";

// Hooks
import useAccordionContext from "@dbox/core/hooks/useAccordionContext";
import cntl from "cntl";

const propTypes = {
  hasBorder: PropTypes.bool,
  className: PropTypes.string,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const Body = ({ className, hasBorder, eventKey, children, ...otherProps }) => {
  const { activeEventKey } = useAccordionContext();

  const bodyCn = () => cntl`
    ${hasBorder ? `px-6 py-3` : `p-0`}
    ${className ? className : undefined}
    ${activeEventKey?.includes(eventKey) ? `border-b border-gray-lightAlpha-4 dark:text-gray-darkAlpha-4` : `border-0`}
  `;

  return activeEventKey?.includes(eventKey) ? (
    <div className={bodyCn()} {...otherProps}>
      {children}
    </div>
  ) : null;
};

Body.propTypes = propTypes;
export default Body;
