// Utils
import cntl from "cntl";
import PropTypes from "prop-types";

// Hooks
import useAccordionContext from "@dbox/core/hooks/useAccordionContext";

// Icons
import { IconChevronUp } from "@tabler/icons-react";

const propTypes = {
  onClick: PropTypes.func,
  hasBorder: PropTypes.bool,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const useAccordionClick = (eventKey, onClick, activeEventKey, onToggle, isOpen) => {
  return (event) => {
    onToggle((prev) =>
      activeEventKey?.includes(eventKey) ? activeEventKey.filter((index) => index !== eventKey) : [...prev, eventKey]
    );
    if (onClick) {
      onClick({ event, isOpen });
    }
  };
};

const Header = ({ hasBorder, eventKey, onClick, children, ...otherProps }) => {
  const { onToggle, activeEventKey } = useAccordionContext();

  const isOpen = activeEventKey?.includes(eventKey);

  const accordionClick = useAccordionClick(eventKey, onClick, activeEventKey, onToggle, !isOpen);

  const headerCn = () => cntl`
    py-3.5
    cursor-pointer
    ${hasBorder ? `px-6` : `px-0`}
    ${!isOpen ? `border-b border-gray-lightAlpha-4 dark:text-gray-darkAlpha-4` : `border-0`}
  `;

  const childrenAndArrowCn = () => cntl`
    flex
    items-center
    justify-between
  `;

  const iconCn = () => cntl`
    size-5
    transition-transform
    text-gray-lightAlpha-10
    dark:text-gray-darkAlpha-10
    ${!isOpen ? `rotate-180` : `rotate-0`}
  `;

  return (
    <div className={headerCn()} onClick={accordionClick} {...otherProps}>
      <div className={childrenAndArrowCn()}>
        {children}
        <IconChevronUp className={iconCn()} />
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
export default Header;
