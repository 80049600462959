import cntl from "cntl";
import PropTypes from "prop-types";
import { SIZE } from "../constants";
import { Checkbox } from "../atoms";
import TagClose from "../atoms/TagClose";
import TagCount from "../atoms/TagCount";
import { useMemo, useState } from "react";

const propTypes = {
  size: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function Tag({
  size,
  label,
  children,
  className,
  onCloseProps,
  onCountProps,
  onCheckboxProps,
}) {
  const [heigth, setHeigth] = useState("");
  const [padding, setPadding] = useState("");
  const [labelSize, setLabelSize] = useState("");
  const [countMargin, setCountMargin] = useState("");
  const [checkboxMargin, setCheckboxMargin] = useState("");
  const [childrenMargin, setChildrenMargin] = useState("");

  const tagCn = () => cntl`
    border
    bg-white
    flex-row
    rounded-md
    inline-flex
    items-center
    justify-center
    border-gray-light-6
    ${heigth}
    ${padding}
    ${className ? className : undefined}
  `;

  const tagXIcon = () => cntl`
    ml-[0.188rem]
  `;

  const labelCn = () => cntl`
    text-center
    font-medium
    text-gray-light-11 
    dark:text-gray-dark-11
    ${labelSize}
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setPadding("px-2");
        setLabelSize("text-xs");
        setHeigth("h-[1.375rem]");
        setCountMargin("ml-[0.188rem]");
        setChildrenMargin(onCheckboxProps?.isVisible ? cntl`mx-1` : cntl`mr-1`);
        setCheckboxMargin(
          `[&>input]:mt-0 ${!children ? cntl`mr-[0.188rem]` : undefined}`
        );
        break;
      case SIZE.md:
        setHeigth("h-6");
        setLabelSize("text-sm");
        setPadding("px-[0.563rem]");
        setCountMargin("ml-[0.313rem]");
        setChildrenMargin(
          onCheckboxProps?.isVisible ? cntl`mx-[0.313rem]` : cntl`mr-[0.313rem]`
        );
        setCheckboxMargin(
          `[&>input]:mt-0 ${!children ? cntl`mr-[0.313rem]` : undefined}`
        );
        break;
      case SIZE.lg:
        setHeigth("h-7");
        setPadding("px-2.5");
        setLabelSize("text-sm");
        setCountMargin("ml-1.5");
        setChildrenMargin(
          onCheckboxProps?.isVisible ? cntl`mx-1.5` : cntl`mr-1.5]`
        );
        setCheckboxMargin(
          `[&>input]:mt-0 ${!children ? cntl`mr-1.5` : undefined}`
        );
        break;
      default:
        setPadding("px-2");
        setLabelSize("text-xs");
        setHeigth("h-[1.375rem]");
        setCountMargin("ml-[0.188rem]");
        setChildrenMargin(onCheckboxProps?.isVisible ? cntl`mx-1` : cntl`mr-1`);
        setCheckboxMargin(
          `[&>input]:mt-0 ${!children ? cntl`mr-[0.188rem]` : undefined}`
        );
        break;
    }
  }, [size, children, onCheckboxProps?.isVisible]);

  return (
    <div className={tagCn()}>
      {onCheckboxProps?.isVisible && (
        <Checkbox
          showLabel={false}
          className={checkboxMargin}
          name={onCheckboxProps?.name}
          onChange={onCheckboxProps?.onChange}
          isChecked={onCheckboxProps?.isChecked}
        />
      )}
      {children && <div className={childrenMargin}>{children}</div>}
      {label?.length && <div className={labelCn()}>{label}</div>}
      {onCloseProps?.isVisible && (
        <TagClose
          size={size}
          className={tagXIcon()}
          onClick={onCloseProps?.onClose}
        />
      )}
      {onCountProps?.isVisible && (
        <TagCount
          size={size}
          className={countMargin}
          label={onCountProps?.label}
        />
      )}
    </div>
  );
}

Tag.propTypes = propTypes;
export default Tag;
