import cntl from "cntl";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ButtonPrimary } from "@dbox/components";
import VerificationTitleAndSubtitle from "../atoms/VerificationTitleAndSubtitle";
import { IconMail } from "@tabler/icons-react";

const propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

function ContainerVerificationStep3({ onClick, className }) {
  const { t } = useTranslation();

  const container = () => cntl`
    px-4
    xs:px-0
    xs:w-[22.5rem]
    ${className ? className : undefined}
  `;

  const wrapperCn = () => cntl`
    flex
    gap-y-8
    flex-col
  `;

  return (
    <section className={container()}>
      <div className={wrapperCn()}>
        <VerificationTitleAndSubtitle
          icon={<IconMail />}
          title={t("page.verification.step3.title")}
          subTitle={t("page.verification.step3.sub_title")}
        />
        <ButtonPrimary label={t("button.label.continue")} onClick={onClick} />
      </div>
    </section>
  );
}

ContainerVerificationStep3.propTypes = propTypes;
export default ContainerVerificationStep3;
