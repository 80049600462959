import cntl from "cntl";
import { Fragment } from "react";
import PropTypes from "prop-types";
import { MegaInput } from "../atoms";
import { KEYBOARDS, SIZE } from "../constants";

const propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  textHelper: PropTypes.string,
  numberOfInput: PropTypes.oneOf([4, 6]),
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
};

function VerificationCode({
  size,
  label,
  className,
  textHelper,
  numberOfInput = 4,
}) {
  const code = [];

  const verificationCodeCn = () => cntl`
    flex
    flex-col
    gap-y-1.5
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    text-sm
    font-medium
    text-gray-light-11 
    dark:text-gray-dark-11
    first-letter:uppercase
  `;

  const inputCn = () => cntl`
    flex
    gap-x-2
    flex-row
    items-center
  `;

  const textHelperCn = () => cntl`
    text-sm
    text-medium
    text-gray-light-10
    dark:text-gray-dark-10
    first-letter:uppercase
  `;

  const hyphenCn = () => cntl`
    font-medium
    leading-none
    text-gray-light-9
    dark:text-gray-dark-9
    text-[2.25rem]
    sm:text-[3.75rem]
  `;

  const handleNextFocus = (e) => {
    const { maxLength, value, name } = e.target;
    const [_, fieldIndex] = name.split("-");
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < numberOfInput) {
        const nextSibling = document.querySelector(
          `input[id=megaInput-${parseInt(fieldIndex, 10) + 1}]`
        );
        if (nextSibling) {
          nextSibling.select();
          nextSibling.focus();
        }
      }
    }
  };

  const manageCodeArr = (index, e) => {
    code?.splice(index, 1, e?.target?.value);
  };

  const onChangeEvent = (e, index) => {
    handleNextFocus(e);
    manageCodeArr(index, e);
  };

  const onPaste = (e) => {
    e.preventDefault();
    const container = document.querySelector("#container-mega-input");
    const paste = e.clipboardData.getData("text");
    const listMegaInputs = container.querySelectorAll("input");

    listMegaInputs.forEach((input, i) => {
      input.value = paste[i] || "";
    });
  };

  const handleNextSibling = (e) => {
    const { name } = e.target;
    const [_, fieldIndex] = name.split("-");
    if (parseInt(fieldIndex, 10) < numberOfInput) {
      const nextSibling = document.querySelector(
        `input[name=megaInput-${parseInt(fieldIndex, 10) + 1}]`
      );
      if (nextSibling) {
        nextSibling.focus();
      }
    }
  };

  const handlePreviousSibling = (e) => {
    const { name } = e.target;
    const [_, fieldIndex] = name.split("-");
    if (parseInt(fieldIndex, 10) < numberOfInput) {
      const previousSibling = document.querySelector(
        `input[name=megaInput-${parseInt(fieldIndex, 10) - 1}]`
      );
      if (previousSibling) {
        previousSibling.focus();
      }
    }
  };

  const handleBackSpace = (e) => {
    const input = e.target;
    const [_, fieldIndex] = input.name.split("-");
    if (parseInt(fieldIndex, 10) < numberOfInput) {
      const previousSibling = document.querySelector(
        `input[name=megaInput-${parseInt(fieldIndex, 10) - 1}]`
      );
      if (input.value) {
        input.value = "";
        manageCodeArr(parseInt(fieldIndex, 10), e);
        return;
      }
      if (previousSibling) previousSibling.focus();
    }
  };

  const onKeyDown = (e) => {
    if (e?.keyCode === KEYBOARDS.arrowLeft) {
      handlePreviousSibling(e);
    } else if (e?.keyCode === KEYBOARDS.arrowRight) {
      handleNextSibling(e);
    } else if (e?.keyCode === KEYBOARDS.backspace) {
      handleBackSpace(e);
    } else {
      return;
    }
  };

  return (
    <div id="verification-code" className={verificationCodeCn()}>
      <label className={labelCn()}>{label}</label>
      <div id="container-mega-input" className={inputCn()} autoComplete="off">
        {[...Array(numberOfInput)]?.map((_, index) => {
          return (
            <Fragment key={`mega-input-${index}`}>
              <MegaInput
                size={size}
                placeholder="0"
                onPaste={onPaste}
                onKeyDown={onKeyDown}
                id={`megaInput-${index}`}
                name={`megaInput-${index}`}
                onClick={(e) => e.target.select()}
                onChange={(e) => onChangeEvent(e, index)}
              />
              {numberOfInput === 6 && index === 2 && (
                <span className={hyphenCn()}>-</span>
              )}
            </Fragment>
          );
        })}
      </div>
      {textHelper && <div className={textHelperCn()}>{textHelper}</div>}
    </div>
  );
}

VerificationCode.propTypes = propTypes;
export default VerificationCode;
