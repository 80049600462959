import cntl from "cntl";
import { isNil } from "lodash";
import { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getValidatedMonths, months, ranges } from "../utils/picker";
import { SIZE, PICKER_MONTH, PICKER_VARIANT, BUTTON_VARIANTS } from "../constants";
import { Input, ButtonGrey, PickerMonth, PickerFooter, ButtonPrimary } from "@dbox/components";
import { format, isAfter, isBefore, addMonths, isSameDay, isSameMonth, isWithinInterval } from "date-fns";

const propTypes = {
  isSingle: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  initialDateRange: PropTypes.object,
  variant: PropTypes.oneOf([PICKER_VARIANT.data_range_base, PICKER_VARIANT.data_range_complex]),
};

const DateRangePicker = forwardRef(function DateRangePicker(
  {
    style,
    minDate,
    variant,
    maxDate,
    onSubmit,
    onCancel,
    onChange,
    className,
    isSingle = false,
    initialDateRange = {},
  },
  ref
) {
  const today = new Date();
  const { t } = useTranslation();
  const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(initialDateRange, minDate, maxDate);
  const [hoverDay, setHoverDay] = useState();
  const [indexItem, setIndexItem] = useState(-1);
  const [dateRange, setDateRange] = useState({ ...initialDateRange });
  const [firstMonth, setFirstMonth] = useState(intialFirstMonth || today);
  const [secondMonth, setSecondMonth] = useState(initialSecondMonth || addMonths(firstMonth, 1));

  const monthsArr = months.map(({ label, value }) => {
    return {
      value,
      label: t(`select.options.${label}`),
    };
  });

  const wrapperDataRange = () => cntl`
    z-10
    flex
    w-fit
    shadow
    flex-row
    bg-white
    rounded-lg
    data-range-picker
    ${className ? className : undefined}
  `;

  const monthsAndFooterCn = () => cntl`
    flex
    flex-col
  `;

  const monthCn = () => cntl`
    flex
    flex-row
  `;

  const dividerBetweenMonths = () => cntl`
    border-l
    border-gray-light-6
  `;

  const ulCn = ({ isSingle }) => cntl`
    flex
    ${
      !isSingle
        ? cntl`flex-col gap-y-1`
        : cntl`flex-row whitespace-nowrap overflow-x-auto hidden-scrollbar justify-between mt-4`
    }
  `;

  const defaultRangesCn = () => cntl`
    flex
    px-4
    py-3
    flex-col
    border-r
    border-gray-light-6
  `;

  const rangesCn = () => cntl`
    flex
    mt-3
    gap-x-2
    flex-row
    items-center
  `;

  const listCn = ({ isActive }) => cntl`
    px-4
    py-2.5
    text-sm
    rounded-md
    font-medium
    focus:outline
    text-gray-light-11 
    dark:text-gray-dark-11
    cursor-pointer
    focus:outline-4
    hover:bg-gray-light-2
    dark:hover:bg-gray-dark-2
    first-letter:uppercase
    focus:outline-gray-light-3
    dark:focus:outline-gray-dark-3
    ${
      isActive
        ? cntl`
                bg-gray-light-2
                dark:bg-gray-dark-2 hover:bg-gray-light-3
                dark:hover:bg-gray-dark-3`
        : cntl``
    }
  `;

  const listSingleCn = ({ isVisible }) => cntl`
    text-sm
    font-semibold
    cursor-pointer
    text-gray-light-11 
    dark:text-gray-dark-11
    first-letter:uppercase
    ${isVisible ? cntl`block` : cntl`hidden`}
  `;

  const inputCn = () => cntl`
    pointer-events-none
  `;

  const hyphenCn = () => cntl`
    text-md
    text-gray-light-11
     dark:text-gray-dark-11
  `;

  const buttonsCn = () => cntl`
    px-6
    py-3
    flex
    gap-x-3
    flex-row
    border-t
    text-gray-light-10
    dark:text-gray-dark-10
  `;

  const onClickSubmit = () => {
    onSubmit(dateRange);
  };

  const onClickCancel = () => {
    onCancel();
    setDateRange({});
  };

  const onDayClick = (day) => {
    setHoverDay(day);
    setIndexItem(-1);
    if (dateRange?.startDate && !dateRange?.endDate && !isBefore(day, dateRange?.startDate)) {
      const newRange = { startDate: dateRange?.startDate, endDate: day };
      setDateRange(newRange);
      if (variant === PICKER_VARIANT.data_range_base) onChange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
  };

  const onDayHover = (date) => {
    if (dateRange?.startDate && !dateRange?.endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  const inHoverRange = (day) => {
    return (
      dateRange?.startDate &&
      !dateRange?.endDate &&
      hoverDay &&
      isAfter(hoverDay, dateRange?.startDate) &&
      isWithinInterval(day, {
        start: dateRange?.startDate,
        end: hoverDay,
      })
    );
  };

  const setFirstDateValidated = (date) => {
    setFirstMonth(date);
  };

  const setSecondDateValidated = (date) => {
    setSecondMonth(date);
  };

  const onMonthNavigate = (marker, action) => {
    if (marker === PICKER_MONTH.first_month) {
      setFirstMonth(addMonths(firstMonth, action));
    }
    if (marker === PICKER_MONTH.second_month) {
      setSecondMonth(addMonths(secondMonth, action));
    }
  };

  const onClickListItem = (index, startDate, endDate) => {
    const isValid = !isNil(startDate) && !isNil(endDate);
    const newRange = {
      endDate,
      startDate,
    };

    setIndexItem(index);
    setDateRange(isValid ? newRange : {});
    setFirstMonth(isValid ? startDate : today);
    setSecondMonth(
      isValid ? (isSameMonth(startDate, endDate) ? addMonths(startDate, 1) : endDate) : addMonths(today, 1)
    );
    if (variant === PICKER_VARIANT.data_range_base) onChange(newRange);
  };

  const Ranges = () => {
    return (
      <div className={defaultRangesCn()}>
        <ul className={ulCn({ isSingle })}>
          {ranges(today).map(({ label, startDate, endDate }, index) => {
            return (
              <li
                tabIndex="0"
                key={`item-range-${index}`}
                className={listCn({ isActive: indexItem === index })}
                onClick={() => onClickListItem(index, startDate, endDate)}
              >
                {t(`button.label.${label}`)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const RangesSingle = () => {
    return (
      <ul className={ulCn({ isSingle })}>
        {ranges(today).map(({ label, startDate, endDate, isVisibleInSingle }, index) => {
          return (
            <li
              tabIndex="0"
              key={`item-range-${index}`}
              className={listSingleCn({
                isVisible: isVisibleInSingle,
              })}
              onClick={() => onClickListItem(index, startDate, endDate)}
            >
              {t(`button.label.${label}`)}
            </li>
          );
        })}
      </ul>
    );
  };

  const Inputs = () => {
    return (
      <div className={rangesCn()}>
        <Input className={inputCn()} value={dateRange?.startDate ? format(dateRange?.startDate, "dd/MM/yyyy") : ""} />
        <div className={hyphenCn()}>&#8208;</div>
        <Input className={inputCn()} value={dateRange?.endDate ? format(dateRange?.endDate, "dd/MM/yyyy") : ""} />
      </div>
    );
  };

  const commonProps = {
    minDate,
    maxDate,
    dateRange,
    monthsArr,
    onDayClick,
    onDayHover,
    inHoverRange,
    onMonthNavigate,
  };

  return (
    <div className={wrapperDataRange()} ref={ref} style={style}>
      {variant === PICKER_VARIANT.data_range_complex && !isSingle && <Ranges />}
      <div className={monthsAndFooterCn()}>
        <div className={monthCn()}>
          <PickerMonth
            {...commonProps}
            date={firstMonth}
            setValue={setFirstDateValidated}
            marker={PICKER_MONTH.first_month}
            navState={{
              back: true,
              forward: true,
            }}
          >
            {isSingle && <Inputs />}
            {isSingle && variant === PICKER_VARIANT.data_range_complex && <RangesSingle />}
          </PickerMonth>
          {!isSingle && (
            <PickerMonth
              {...commonProps}
              date={secondMonth}
              setValue={setSecondDateValidated}
              className={dividerBetweenMonths()}
              marker={PICKER_MONTH.second_month}
              navState={{
                forward: true,
                back: true,
              }}
            />
          )}
        </div>
        {!isSingle ? (
          <PickerFooter
            variant={variant}
            range={dateRange}
            onClickCancel={onClickCancel}
            onClickSubmit={onClickSubmit}
            onClickToday={() => onClickListItem(-1, today, today)}
          />
        ) : (
          variant === PICKER_VARIANT.data_range_complex && (
            <div className={buttonsCn()}>
              <ButtonGrey
                size={SIZE.md}
                className="w-full"
                onClick={onClickCancel}
                variant={BUTTON_VARIANTS.outline}
                label={t("button.label.cancel")}
              />
              <ButtonPrimary
                size={SIZE.md}
                className="w-full"
                onClick={onClickSubmit}
                label={t("button.label.apply")}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
});

DateRangePicker.propTypes = propTypes;
export default DateRangePicker;
